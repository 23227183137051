import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";

import cbIcon from '../../theme/assets/svg/omt_cb_icon.svg';
import {useDispatch} from "react-redux";
import {setCbRewardModalStatus} from "../../store/wallet/action";



const CommunityBoostMain = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.contentWrapItem}`}>
                        <div className={`${Styles.titleWrap}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={cbIcon} alt="Community Boost"/>
                            </div>
                            <div className={`${Styles.customTitle}`}>{t('Поддержка сообщества')}</div>
                        </div>
                        <div className={`${Styles.mainNoteWrap}`}>
                            {t('В преддверии запуска новых продуктов DEX OMT и OMT Launchpad мы выделили пул вознаграждений в размере')} <span>100.000$</span> {t('для расширения нашего комьюнити')}.
                        </div>
                        <div className={`${Styles.borderNoteWrap}`}>
                            <div className={`${Styles.borderNoteWrapTitle}`}>{t('Выполняйте задания Ульев - получайте бонусы')}</div>
                            <div className={`${Styles.borderNoteWrapContent}`}>
                                <span>{t('Получите 5%')}</span> {t('в Ноnee от первого депозита Вашего партнера, бонус действует для всех пользователей на время проведения промо Community Boost')}
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <div className={`${Styles.title2}`}>{t('Топовый Буст')}:</div>
                        <div className={`${Styles.noteWrap}`}>{t('Попадите в наш Leaderboard что бы получить супер вознаграждения из Топа')}</div>
                        <div className={`${Styles.placeWrap}`}>
                            <div className={`${Styles.placeWrapItem}`}>
                                <div className={`${Styles.placeWrapItemTitle}`}>1 {t('место')}</div>
                                <div className={`${Styles.placeWrapItemReward}`}>10.000 Honee</div>
                                <div className={`${Styles.placeWrapItemReward}`}>20.000 OMT</div>
                                <div className={`${Styles.placeWrapItemReward}`}>PRO+ {t('на год')}</div>
                            </div>
                            <div className={`${Styles.placeWrapItem}`}>
                                <div className={`${Styles.placeWrapItemTitle}`}>2 {t('место')}</div>
                                <div className={`${Styles.placeWrapItemReward}`}>5.000 Honee</div>
                                <div className={`${Styles.placeWrapItemReward}`}>10.000 OMT</div>
                                <div className={`${Styles.placeWrapItemReward}`}>PRO+ {t('на год')}</div>
                            </div>
                            <div className={`${Styles.placeWrapItem}`}>
                                <div className={`${Styles.placeWrapItemTitle}`}>3 {t('место')}</div>
                                <div className={`${Styles.placeWrapItemReward}`}>2.500 Honee</div>
                                <div className={`${Styles.placeWrapItemReward}`}>5.000 OMT</div>
                                <div className={`${Styles.placeWrapItemReward}`}>PRO+ {t('на год')}</div>
                            </div>
                        </div>
                        <div className={`${Styles.rewardBtn}`} onClick={()=>{dispatch(setCbRewardModalStatus(true))}}>{t('4-20 место - остальные награды')}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CommunityBoostMain;
