import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {dotsHandler} from "../../helpers/dotsHandler";



const HoneechizeBotEarnings = () => {
    const honeechizeBotEarningsData = useSelector(store => store.wallet.honeechizeBotEarningsData);
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title3}`}>{t('Ваш заработок за')}:</div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        <div className={`${Styles.contentBoxItem} ${Number(honeechizeBotEarningsData?.exchanges) === 0 ? Styles.contentBoxItemActiveAnimate : null}`}>
                            <div className={`${Styles.contentBoxItemTitle} ${Styles.colorOrange}`}>In work</div>
                            <div className={`${Styles.contentBoxItemName} ${Styles.colorOrange}`}>{t('Баланс')}:</div>
                            <div className={`${Styles.contentBoxItemValue}`}>${honeechizeBotEarningsData ? dotsHandler(honeechizeBotEarningsData?.balance) : 0}</div>
                            {Number(honeechizeBotEarningsData?.exchanges) === 0 ?
                                <>
                                    <span className={`${Styles.topA}`}/>
                                    <span className={`${Styles.rightA}`}/>
                                    <span className={`${Styles.bottomA}`}/>
                                    <span className={`${Styles.leftA}`}/>
                                </> : null
                            }
                        </div>
                        <div className={`${Styles.contentBoxItem} ${honeechizeBotEarningsData?.exchanges > 0 ? Styles.contentBoxItemActiveAnimate : null}`}>
                            <div className={`${Styles.contentBoxItemTitle} ${Styles.colorYellow}`}>exchanges</div>
                            <div className={`${Styles.contentBoxItemName} ${Styles.colorYellow}`}>{t('Баланс')}:</div>
                            <div className={`${Styles.contentBoxItemValue}`}>${honeechizeBotEarningsData ? dotsHandler(honeechizeBotEarningsData?.exchanges) : 0}</div>
                            {honeechizeBotEarningsData?.exchanges > 0 ?
                                <>
                                    <span className={`${Styles.topA}`}/>
                                    <span className={`${Styles.rightA}`}/>
                                    <span className={`${Styles.bottomA}`}/>
                                    <span className={`${Styles.leftA}`}/>
                                </> : null
                            }
                        </div>
                        <div className={`${Styles.contentBoxItem} ${honeechizeBotEarningsData?.profit > 0 ? Styles.contentBoxItemActiveAnimate : null}`}>
                            <div className={`${Styles.contentBoxItemTitle} ${Styles.colorGreen}`}>PROFIT</div>
                            <div className={`${Styles.contentBoxItemName} ${Styles.colorGreen}`}>{t('Баланс')}:</div>
                            <div className={`${Styles.contentBoxItemValue}`}>${honeechizeBotEarningsData ? dotsHandler(honeechizeBotEarningsData?.profit) : 0}</div>
                            {honeechizeBotEarningsData?.profit > 0 ?
                                <>
                                    <span className={`${Styles.topA}`}/>
                                    <span className={`${Styles.rightA}`}/>
                                    <span className={`${Styles.bottomA}`}/>
                                    <span className={`${Styles.leftA}`}/>
                                </> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HoneechizeBotEarnings;
