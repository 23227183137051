import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';

import table1 from "../../../theme/assets/svg/omt_whitepaper_table1.svg";
import table2 from "../../../theme/assets/svg/omt_whitepaper_table2.svg";


const WhitePaperHAtechnicalPart = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Technical part')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Technical part</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    Automated cryptocurrency arbitrage is an innovation in the world of Web3. It is
                                    an official development by the OMT team of artificial intelligence, enabling
                                    cryptocurrency exchange across different platforms. Our program has access
                                    to over 30 major global cryptocurrency exchanges, constantly updating
                                    information on current exchange rates. Once the AI detects a price difference
                                    (spread), it automatically initiates an exchange, which takes place within
                                    minutes, maximizing profits. The bot is configured to only engage in trades
                                    when it identifies a potential profit from 2% up to 8%, depends on your settings. This is because
                                    cryptocurrency rates are highly volatile, and trading with a smaller spread
                                    percentage carries a higher risk. Within a matter of minutes, the rate can
                                    fluctuate by 1% to 5%. The settings allow you to manually adjust the trading
                                    risks, taking responsibility for the actions of our AI. However, we strongly
                                    advise against increasing the risks to avoid potential loss of your assets.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={table1} alt="Automated cryptocurrency arbitrage is an innovation in the world of Web3."/>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    OMT's cryptocurrency arbitrage system is built on the model of a bee colony. There is a queen,
                                    which is the main server and the shared fund of all clients, and there are worker bees, which are
                                    the users' bots. After the user activates their bot and replenishes the deposit, their money is
                                    sent to the common pool, frozen for 90 days, and all bots of the OMT platform have access to it..
                                    The worker bee bots utilize the shared funds but do not exceed the deposit amount of their respective owners.
                                    Once the user's deposit enters the shared fund, you can activate your worker bee (bot) and start generating profits.
                                    You can customize your bot according to your preferences, with several main settings available, one of which is the
                                    risk level. There are three types: low, medium, and high. The higher the risk level, the more trades your worker bee
                                    will make and the higher the percentage it will bring in. However, it also carries the potential for loss-making trades.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={table2} alt="OMT's cryptocurrency arbitrage system is built on the model of a bee colony."/>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    To withdraw the main assets from the bot's balance, you need to submit an application through a request to the support
                                    service with the note "closing of the trading deposit". As this is a complex technical and mathematical process,
                                    this operation is carried out from 1 to 14 days (depending on the overall trading volumes, these indicators may change).
                                    IMPORTANT! Please note that our AI is set for continuous learning due to the rapidly changing market, so a pause in its
                                    training from 2 to 4 weeks may cause critical malfunctions in your bot's operation. Therefore, you should understand
                                    that before the bot becomes unusable, we will irreversibly transfer it to another user. Otherwise, after such a downtime,
                                    the bot may behave unnaturally and fail to cope with its tasks. If after transferring your bot you decide to restart the AI,
                                    you will have to go through all the training stages again.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperHAtechnicalPart;