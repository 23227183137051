import React, {useState} from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";


const AmbassadorsPromo = () => {
    const { t } = useTranslation();
    const ambassadorMainData = useSelector(store => store.wallet.ambassadorMainData);

    const [p3CopyStatus, setP3CopyStatus] = useState(false);
    const [p5CopyStatus, setP5CopyStatus] = useState(false);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.title3}`}>{t('Промокоды')}</div>
                    <div className={`${Styles.rowWrap}`}>
                        <div className={`${Styles.rowItem}`}>
                            <div className={`${Styles.rowItemTitle}`}>3% {t('промокод')}</div>
                            <div className={`${Styles.rowItemFakeInputWrap}`}>
                                <div className={`${Styles.rowItemFakeInput}`}>
                                    {ambassadorMainData?.promo?.p3?.code}
                                    <div className={`${Styles.rowItemFakeInputAbsInfo} ${ambassadorMainData?.influencer ? Styles.rowItemFakeInputAbsInfoInfluencer : ' '}`}>
                                        {ambassadorMainData?.promo?.p3?.applied}/{ambassadorMainData?.promo?.p3?.all}
                                    </div>
                                </div>
                                <div
                                    className={`${Styles.rowItemFakeInputCopyBtn} ${p3CopyStatus ? Styles.rowItemFakeInputCopyBtnCopied : null} ${ambassadorMainData?.influencer ? Styles.rowItemFakeInputCopyBtnInfluencer : ''}`}
                                    onClick={()=>{
                                        setP3CopyStatus(true);
                                        setP5CopyStatus(false);
                                        navigator.clipboard.writeText(ambassadorMainData?.promo?.p3?.code);
                                    }}
                                >
                                    {p3CopyStatus ? t('Скопировано') : t('Скопировать')}
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.rowItem}`}>
                            <div className={`${Styles.rowItemTitle}`}>5% {t('промокод')}</div>
                            <div className={`${Styles.rowItemFakeInputWrap}`}>
                                <div className={`${Styles.rowItemFakeInput}`}>
                                    {ambassadorMainData?.promo?.p5?.code}
                                    <div className={`${Styles.rowItemFakeInputAbsInfo} ${ambassadorMainData?.influencer ? Styles.rowItemFakeInputAbsInfoInfluencer : ' '}`}>
                                        {ambassadorMainData?.promo?.p5?.applied}/{ambassadorMainData?.promo?.p5?.all}
                                    </div>
                                </div>
                                <div
                                    className={`${Styles.rowItemFakeInputCopyBtn} ${p5CopyStatus ? Styles.rowItemFakeInputCopyBtnCopied : null} ${ambassadorMainData?.influencer ? Styles.rowItemFakeInputCopyBtnInfluencer : ''}`}
                                    onClick={()=>{
                                        setP3CopyStatus(false);
                                        setP5CopyStatus(true);
                                        navigator.clipboard.writeText(ambassadorMainData?.promo?.p5?.code);
                                    }}
                                >
                                    {p5CopyStatus ? t('Скопировано') : t('Скопировать')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AmbassadorsPromo;
