import React from "react";
import {toast} from "react-toastify";
import Styles from './styles.module.scss'

import check1 from '../../theme/assets/svg/omt_confirmation_check_icon1.svg';
import check2 from '../../theme/assets/svg/omt_confirmation_check_icon2.svg';
import {useDispatch} from "react-redux";
import {setConfirmationStep} from "../../store/main/action";
import {useTranslation} from "react-i18next";


const ConfirmationService = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const btnHandler = (status) =>{
        if (status){
            toast(t('Спасибо что помогаете улучшить наш сервис!'));
        }
        dispatch(setConfirmationStep('1'));
        localStorage.setItem('omt-step', '1');
    }
    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title}`}>{t('Помогите нам стать лучше')}</div>
                <div className={`${Styles.note}`}>
                    {t('Мы постоянно стремимся стать лучше для Вас. В связи с этим, мы хотели бы собирать информацию о том, ĸаĸ вы используете сервис, чтобы улучшить его, опираясь на ваш пользовательсĸий опыт')}.
                </div>
                <div className={`${Styles.listWrap}`}>
                    <div className={`${Styles.listItem}`}>
                        <div className={`${Styles.listTitle}`}>{t('Для улучшения нашего продукта, мы бы хотели собирать')}:</div>
                        <div className={`${Styles.listBox}`}>
                            <div className={`${Styles.listItemBox}`}>
                                <div className={`${Styles.list}`}>
                                    <img src={check1} alt="accept"/>
                                    <span className={`${Styles.listContent}`}>{t('анонимизированные события кликов и просмотров страниц')}</span>
                                </div>
                                <div className={`${Styles.listWarning}`}>{t('Вы всегда сможете отказаться в настройках аккаунта')}</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.listItem}`}>
                        <div className={`${Styles.listTitle}`}>{t('Информация, которая собираться не будет')}:</div>
                        <div className={`${Styles.listBox}`}>
                            <div className={`${Styles.listItemBox}`}>
                                <div className={`${Styles.list}`}>
                                    <img src={check2} alt="disable"/>
                                    <span className={`${Styles.listContent}`}>{t('ключи, адреса, транзакции, балансы, хэши или любую личную информацию')}</span>
                                </div>
                            </div>
                            <div className={`${Styles.listItemBox}`}>
                                <div className={`${Styles.list}`}>
                                    <img src={check2} alt="disable"/>
                                    <span className={`${Styles.listContent}`}>{t('полный IP-адрес')}</span>
                                </div>
                            </div>
                            <div className={`${Styles.listItemBox}`}>
                                <div className={`${Styles.list}`}>
                                    <img src={check2} alt="disable"/>
                                    <span className={`${Styles.listContent}`}>{t('данные никогда не будут проданы с целью получения прибыли')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.btnWrap}`}>
                    <div className={`${Styles.btn}`} onClick={()=>{btnHandler(false)}}>{t('Нет, спасибо')}</div>
                    <div className={`${Styles.btn}`} onClick={()=>{btnHandler(true)}}>{t('Я согласен')}</div>
                </div>
                <div className={`${Styles.info}`}>
                    {t('Эти данные являются агрегированными, поэтому - анонимными, соблюдая ĸонцепции Общего регламента по защите данных (ЕС) 2016/679. Для получения дополнительной информации о нашей праĸтиĸе защиты частной жизни, пожалуйста, ознаĸомьтесь с нашей политиĸой ĸонфиденциальности')}{' - '}
                    <span>{t('здесь')}</span>
                </div>
            </div>
        </section>
    );
};

export default ConfirmationService;
