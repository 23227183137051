import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";

import lbIcon from '../../theme/assets/svg/omt_cb_lb_icon.svg';
import {useSelector} from "react-redux";



const CommunityBoostLeaderBoard = () => {
    const { t } = useTranslation();

    const communityBoostLeaderboardData = useSelector(store => store.wallet.communityBoostLeaderboardData);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.contentWrapItem}`}>
                    <div className={`${Styles.card}`}>
                        <div className={`${Styles.cardBodyWithScroll}`}>
                            <div className={`${Styles.lbTitle}`}>{t('Список лидеров')}</div>
                            <div className={`${Styles.titleRow}`}>
                                <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Позиция')}</div>
                                <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Пользователь')}</div>
                                <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Оборот')}</div>
                            </div>
                            <div className={`${Styles.contentBox}`}>
                                {communityBoostLeaderboardData?.leaders?.length > 0 ? communityBoostLeaderboardData?.leaders?.map((item, index)=>{
                                    return (
                                        <div className={`${Styles.contentBoxItem} ${Styles[`contentBoxItem${item.place}`]}`} key={'leaderboard' + index}>
                                            <div className={`${Styles.contentBoxItemValues}`}>{item.place}</div>
                                            <div className={`${Styles.contentBoxItemValues}`}>{item.name.slice(0, 4) + '...' + item.name.slice(item.name.length - 4, item.name.length)}</div>
                                            <div className={`${Styles.contentBoxItemValues}`}>${item.referral_bot_sum * 1}</div>
                                        </div>
                                    )
                                }) : null}
                            </div>
                            <div className={`${Styles.userResult}`}>
                                <div className={`${Styles.userResultTitle}`}>{t('Ваш результат')}:</div>
                                <div className={`${Styles.userResultBox} ${Styles[`userResultBox${communityBoostLeaderboardData?.currentUserPlace?.place}`]}`}>
                                    <div className={`${Styles.contentBoxItemValues}`}>{communityBoostLeaderboardData?.currentUserPlace?.place}</div>
                                    <div className={`${Styles.contentBoxItemValues}`}>{communityBoostLeaderboardData?.currentUserPlace?.name.slice(0, 4) + '...' + communityBoostLeaderboardData?.currentUserPlace?.name.slice(communityBoostLeaderboardData?.currentUserPlace?.name.length - 4, communityBoostLeaderboardData?.currentUserPlace.name.length)}</div>
                                    <div className={`${Styles.contentBoxItemValues}`}>${communityBoostLeaderboardData?.currentUserPlace?.referral_bot_sum * 1}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.contentWrapItem}`}>
                    <div className={`${Styles.card}`}>
                        <div className={`${Styles.cardBody}`}>
                            <div className={`${Styles.contentBox}`}>
                                <div className={`${Styles.imgWrap}`}>
                                    <img src={lbIcon} alt="Leaderboard"/>
                                </div>
                                <div className={`${Styles.text}`}>{t('Список лидеров обновляется 1 раз в 24 часа')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CommunityBoostLeaderBoard;
