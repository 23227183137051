import React, {useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";

import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import {getHoneechizeAffiliateStatistic} from "../../store/wallet/action";


const HoneechizeAffiliateStatistic = () => {
    const honeechizeAffiliateStatisticPageData = useSelector(store => store.wallet.honeechizeAffiliateStatisticPageData);
    const honeechizeAffiliateStatisticCorrectData = useSelector(store => store.wallet.honeechizeAffiliateStatisticCorrectData);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [currentFilter, setCurrentFilter] = useState('week');

    const onScroll = (e) =>{
        if (e.target.scrollTop - (e.target.scrollHeight - e.target.clientHeight) >= -1 && e.target.scrollTop - (e.target.scrollHeight - e.target.clientHeight) <= 1){
            if (honeechizeAffiliateStatisticPageData?.current_page + 1 <= honeechizeAffiliateStatisticPageData?.last_page){
                dispatch(getHoneechizeAffiliateStatistic(currentFilter, honeechizeAffiliateStatisticPageData?.current_page + 1))
            }
        }
    }

    const filterHandler = (period) =>{
        dispatch(getHoneechizeAffiliateStatistic(period));
        setCurrentFilter(period);
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBodyWithScroll}`}>
                    <div className={`${Styles.titleWrap}`}>
                        <div className={`${Styles.title3}`}>{t('Статистика ваших рефералов')}:</div>
                        <div className={`${Styles.filterWrap}`}>
                            <div className={`${Styles.filterWrapItem} ${currentFilter === 'day' ? Styles.filterWrapItemActive : null}`} onClick={()=>{filterHandler('day')}}>{t('День')}</div>
                            <div className={`${Styles.filterWrapItem} ${currentFilter === 'week' ? Styles.filterWrapItemActive : null}`} onClick={()=>{filterHandler('week')}}>{t('Неделя')}</div>
                            <div className={`${Styles.filterWrapItem} ${currentFilter === 'month' ? Styles.filterWrapItemActive : null}`} onClick={()=>{filterHandler('month')}}>{t('Месяц')}</div>
                            {/*<div className={`${Styles.filterWrapItem} ${currentFilter === 'all' ? Styles.filterWrapItemActive : null}`} onClick={()=>{filterHandler('all')}}>{t('Все')}</div>*/}
                        </div>
                    </div>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Имя')}</div>
                        <div className={`${Styles.mobileWrap} ${Styles.colorGray}`}>
                            <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.mobileItem}`}>{t('Уровень')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.mobileItem}`}>{t('Сумма депозита2')}</div>
                        </div>
                        <div className={`${Styles.mobileWrap} ${Styles.colorGray}`}>
                            <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.mobileItem}`}>{t('Реферальная прибыль')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.mobileItem}`}>{t('Собственный доход')}</div>
                        </div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.desktop}`}>{t('Уровень')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.desktop}`}>{t('Сумма депозита2')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.desktop}`}>{t('Реферальная прибыль')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.desktop}`}>{t('Собственный доход')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`} onScroll={onScroll}>
                        {honeechizeAffiliateStatisticCorrectData?.[currentFilter]?.length > 0 ? honeechizeAffiliateStatisticCorrectData?.[currentFilter].map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem} ${item.deposit_amount > 0 ? null : Styles.contentBoxItemZero}`} key={'affiliateStatistic' + index}>
                                    <div className={`${Styles.contentBoxItemValues}`}>{item.referral}</div>
                                    <div className={`${Styles.mobileWrap}`}>
                                        <div className={`${Styles.contentBoxItemValues}`}>{item.level}</div>
                                        <div className={`${Styles.contentBoxItemValues}`}>{item.deposit_amount}</div>
                                    </div>
                                    <div className={`${Styles.mobileWrap}`}>
                                        <div className={`${Styles.contentBoxItemValues}`}>{item.referral_earnings}</div>
                                        <div className={`${Styles.contentBoxItemValues}`}>{item.self_earnings}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.desktop}`}>{item.level}</div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.desktop}`}>{item.deposit_amount}</div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.desktop}`}>{item.referral_earnings}</div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.desktop}`}>{item.self_earnings}</div>
                                </div>
                            )
                        }) : <EmptyValues text={t('У вас пока что нет рефералов')} withIcon={false} withBtn={false}/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HoneechizeAffiliateStatistic;
