import React from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";
import CryptoIcon from "../_UI/cryptoIcon";
import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";


const WalletAssets = () => {
    const availableWallets = useSelector(store => store.wallet.availableWallets);

    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBodyWithScroll}`}>
                    <div className={`${Styles.title3}`}>{t('Активы')}</div>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Токен')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Кол-во')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Сумма')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        {availableWallets.length > 0 ? availableWallets.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem}`} key={'walletAssets' + index} onClick={()=>{navigate(`/wallet/${item.currency.id}`)}}>
                                    <div className={`${Styles.contentBoxItemNameWrap}`}>
                                        <div className={`${Styles.contentBoxItemNameImgWrap}`}>
                                            <CryptoIcon short_name={item?.currency?.short_name}/>
                                        </div>
                                        <div className={`${Styles.contentBoxItemNameBox}`}>
                                            <div className={`${Styles.contentBoxItemShortName}`}>{item?.currency?.short_name}</div>
                                            <div className={`${Styles.contentBoxItemFullName}`}>{item?.currency?.name}</div>
                                        </div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemAmount}`}>{item.amount * 1}</div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemTotal}`}>${item.amount_usdt * 1}</div>
                                </div>
                            )
                        }) : <EmptyValues text={t('У вас пока что нет активов')} withIcon={true} withBtn={true} type='walletAssets'/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WalletAssets;
