import React from "react";
import Styles from './styles.module.scss';
import image from '../../theme/assets/other/3d1.png';
import {useTranslation} from "react-i18next";

const PublicPresaleMain = () => {
    const { t } = useTranslation();
    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.title} ${Styles.maintitle}`}><span>OMT</span> TOKEN</div>
                    <div className={`${Styles.title}`}>{t('Добро пожаловать на нашу эксклюзивную')}<br/> {t('страницу предпродажи')}!</div>
                    {/*<div className={`${Styles.mobileImgBox}`}>*/}
                    {/*    <img src={image} alt="Что такое OMT?"/>*/}
                    {/*</div>*/}
                    <div className={`${Styles.noteWrap}`}>
                        <div className={`${Styles.note}`}>
                            <div>
                                {t('Приготовьтесь отправиться в невероятное путешествие вместе с нашим революционным проектом! В течение следующих нескольких недель, у вас есть эксклюзивная возможность приобрести билеты и стать частью чего-то действительно революционного.')}<br/>
                            </div>
                            <div>
                                {t('Но поторопитесь! Каждый последующий билет предлагает меньшее количество токенов, что делает этот раз идеальным для того, чтобы присоединиться к нам. Не упустите этот ограниченный шанс быть в авангарде передовых технологий, исследуя мир искусственного интеллекта и криптовалют.')}<br/>
                            </div>
                            {t('Действуйте быстро и получите свои билеты прямо сейчас, чтобы открыть мир возможностей и стать одним из первых участников нашего революционного проекта.')}
                        </div>
                    </div>
                </div>
                <div className={`${Styles.imgBox}`}>
                    <img src={image} alt="Что такое OMT?"/>
                </div>
            </div>
        </section>
    )
}

export default PublicPresaleMain;