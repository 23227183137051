import React from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import CryptoIcon from "../_UI/cryptoIcon";
import {setStakingFormStatus} from "../../store/wallet/action";
import {useTranslation} from "react-i18next";


const StakingAvailable = () => {
    const dispatch = useDispatch();
    const stakingAvailable = useSelector(store => store.wallet.availableStaking);
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBodyWithScroll}`}>
                    <div className={`${Styles.title3}`}>{t('Доступны для стейкинга')}</div>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Монета')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.titleRowItemM}`}>{t('Дни')} | APY %</div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.titleRowItemD}`}>{t('Дни')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.titleRowItemD}`}>APY %</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Действие')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        {stakingAvailable?.available?.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem}`} key={'stakingAvailable' + index}>
                                    <div className={`${Styles.contentBoxItemNameWrap}`}>
                                        <div className={`${Styles.contentBoxItemNameImgWrap}`}>
                                            <CryptoIcon short_name={item?.currency?.short_name}/>
                                        </div>
                                        <div className={`${Styles.contentBoxItemNameBox}`}>
                                            <div className={`${Styles.contentBoxItemShortName}`}>{item?.currency?.short_name}</div>
                                            <div className={`${Styles.contentBoxItemFullName}`}>{item?.currency?.name}</div>
                                        </div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemM} ${Styles.contentBoxItemAmount}`}>
                                        <div className={`${Styles.contentBoxItemValuesRowWrap}`}>
                                            <div className={`${Styles.contentBoxItemValuesRow}`}>{item?.days?.length > 1 ? `${item.days[0]} - ${item.days[item.days.length - 1]}` : item.days[0]}</div>
                                            <div className={`${Styles.contentBoxItemValuesRow}`}>{item?.apy?.length > 1 ? `${item.apy[0] * 1} - ${item.apy[item.apy.length - 1] * 1}` : item.apy[0] * 1}</div>
                                        </div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemD}`}>{item?.days?.length > 1 ? `${item.days[0]} - ${item.days[item.days.length - 1]}` : item.days[0]}</div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemD}`}>{item?.apy?.length > 1 ? `${item.apy[0] * 1} - ${item.apy[item.apy.length - 1] * 1}` : item.apy[0] * 1}</div>
                                    <div
                                        className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemTotal}`}
                                        onClick={()=>{dispatch(setStakingFormStatus({status: true, item: item, balance: stakingAvailable?.balance}))}}
                                    >
                                        {t('Депозит')}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StakingAvailable;
