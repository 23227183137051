import React from "react";
import Styles from './styles.module.scss';
import DOMPurify from "dompurify";
import {useSelector} from "react-redux";
import Moment from "react-moment";
import newsReadIcon from "../../theme/assets/svg/omt_news_read_icon.svg";


const PostMain = () => {
    const post = useSelector(store => store.main.post);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title1}`}>{post.title}</div>
                <div className={`${Styles.dateWrap}`}>
                    <div className={`${Styles.date}`}><Moment format="DD/MM/YYYY">{post.date}</Moment></div>
                    <div className={`${Styles.read}`}>
                        <div className={`${Styles.readImgWrap}`}>
                            <img src={newsReadIcon} alt={post.title}/>
                        </div>
                        {post.read_time ? post.read_time : '2'} minutes
                    </div>
                </div>
                <div className={`${Styles.imgWrap}`}>
                    <img src={post.picture} alt={post.title}/>
                </div>
                <div
                    className={`${Styles.content}`}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(post.content).replace(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/gi, '<a target="_blank" href=$1$2$1')
                    }}
                />
            </div>
        </section>
    );
};

export default PostMain;
