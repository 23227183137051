import React from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";

import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import Moment from "react-moment";


const BotHistory = () => {
    const botHistory = useSelector(store => store.wallet.botHistory);
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBodyWithScroll}`}>
                    <div className={`${Styles.title3}`}>{t('Последние транзакции')}</div>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Процент')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Дата и время')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Результат')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        {botHistory?.data?.length > 0 ? botHistory?.data.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem}`} key={'walletHistory' + index}>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemValuesSpecial}`}>{item.percent}%</div>
                                    <div className={`${Styles.contentBoxItemValues}`}><Moment format="DD/MM/YYYY HH:mm" utc={true}>{item.date}</Moment></div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemValuesSpecial}`}>{item.profit} Honee</div>
                                </div>
                            )
                        }) : <EmptyValues text={t('У вас пока что нет транзакций')} withIcon={false} withBtn={false}/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BotHistory;
