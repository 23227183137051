import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import omtIcon from '../../theme/assets/svg/omt_token_border_icon.svg';
import honeeIcon from '../../theme/assets/crypto/omt_crypto_honee_icon.svg';
// import {useNavigate} from "react-router-dom";



const ExchangeMain = () => {
    const exchangeData = useSelector(store => store.wallet.exchangeData);
    // const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.contentWrapItem}`}>
                        <div className={`${Styles.contentWrapItemName}`}>HONEE</div>
                        <div className={`${Styles.contentWrapItemValue}`}>
                            <div className={`${Styles.contentWrapItemValueIconWrap}`}>
                                <img src={honeeIcon} alt="Honee"/>
                            </div>
                            {exchangeData ? Number(exchangeData.honey_balance).toFixed(2) * 1 : '0'}
                        </div>
                        <div className={`${Styles.contentWrapItemTextWrap}`}>
                            <div className={`${Styles.contentWrapItemTextWrapRow}`}>1 HONEE = 1 USDT</div>
                            <div className={`${Styles.contentWrapItemTextWrapRow}`}>{t('Подробнее')}</div>
                        </div>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <div className={`${Styles.contentWrapItemName}`}>OMT {t('Токен')}</div>
                        <div className={`${Styles.contentWrapItemValue}`}>
                            <div className={`${Styles.contentWrapItemValueIconWrap}`}>
                                <img src={omtIcon} alt="Honee"/>
                            </div>
                            {exchangeData ? Number(exchangeData.omt_balance).toFixed(2) * 1 : '0'}
                        </div>
                        <div className={`${Styles.contentWrapItemBtnWrap}`}>
                            <div className={`${Styles.button} ${Styles.buttonDisabled}`}>
                                {t('Купить')}
                            </div>
                            <div className={`${Styles.button} ${Styles.hide}`}>
                                White paper
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ExchangeMain;
