import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import BotProfit from "../../components/botProfit";
import BotHistory from "../../components/botHistory";
import BotMain from "../../components/botMain";
import BotSettings from "../../components/botSettings";
import BotInfo from "../../components/botInfo";
import BotBalance from "../../components/botBalance";
import {getBotHistory, getBotMainData, getBotStats, getWalletAssets} from "../../store/wallet/action";
import BotModal from "../../components/botModal";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import BotPromoModal from "../../components/botPromoModal";
import BotSubscribeModal from "../../components/botSubscribeModal";
import BotMassage from "../../components/_UI/botMessage";
import moment from "moment-timezone";


const BotPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);
    const botMainData = useSelector(store => store.wallet.botMainData);
    const newOpenBot = useSelector(store => store.wallet.newOpenBot);


    const daysToEndSubscribe = (endDate) =>{
        if (endDate){
            const endSubscribe = moment(endDate);
            const now = moment();

            return endSubscribe.diff(now, 'days') + 1 < 1 ? 1 : endSubscribe.diff(now, 'days') + 1
        }
    }


    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getBotMainData());
        dispatch(getWalletAssets());
    },[dispatch])
    
    useEffect(()=>{
        if (botMainData?.bot?.id){
            dispatch(getBotStats(botMainData?.bot?.id));
            dispatch(getBotHistory(botMainData?.bot?.id));
        }
    },[botMainData?.bot?.id, dispatch])

    useEffect(()=>{
        if (newOpenBot){
            dispatch(getBotMainData());
        }
    },[dispatch, newOpenBot])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Бот')}/>
            <div className={`${Styles.contentWrap}`}>
                {botMainData?.bot?.is_active && !botMainData?.subscription ? <BotMassage text={t('Мы хотели бы сообщить вам, что ваша подписка на HoneeAI истекла. Чтобы продолжить использование HoneeAI, пожалуйста, произведите оплату подписки.')}/> : null}
                {botMainData?.subscription && daysToEndSubscribe(botMainData?.subscription?.date_to) < 7 ? <BotMassage styleStatus={true} text={`${t('Мы хотели бы сообщить вам, что срок вашей подписки на HoneeAI истекает через')} ${daysToEndSubscribe(botMainData?.subscription?.date_to)} ${t('д. Чтобы продолжить использование HoneeAI, оплатите подписку.')}`}/> : null}
                <BotMain/>
                <BotSettings/>
                <BotInfo/>
                <BotProfit/>
                <BotHistory/>
                <BotBalance/>
                <BotModal/>
                <BotPromoModal/>
                <BotSubscribeModal/>
            </div>
        </section>
    );
};

export default BotPage;