import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";
import TransactionsMain from "../../components/transactionsMain";
import {getWalletTransactions} from "../../store/wallet/action";


const TransactionsPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authStatus = useSelector(store => store.main.authorizationStatus);
    const { t } = useTranslation();

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])
    
    useEffect(()=>{
        dispatch(getWalletTransactions());
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Транзакции')}/>
            <div className={`${Styles.contentWrap}`}>
                <TransactionsMain/>
            </div>
        </section>
    );
};

export default TransactionsPage;