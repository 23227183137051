import React from "react";
import Styles from './styles.module.scss'
import logo from "../../theme/assets/svg/omt_final_logo.svg";
// import nyLogo from '../../theme/assets/svg/omt_ny_logo.svg';
import soc_icon1 from '../../theme/assets/svg/omt_nav_soc_icon1.svg';
import soc_icon2 from '../../theme/assets/svg/omt_nav_soc_icon2.svg';
import soc_icon3 from '../../theme/assets/svg/omt_nav_soc_icon3.svg';
import soc_icon5 from '../../theme/assets/svg/omt_nav_soc_icon5.svg';
import soc_icon6 from '../../theme/assets/svg/omt_nav_soc_icon6.svg';
import soc_icon7 from '../../theme/assets/svg/omt_nav_soc_icon7.svg';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";


const MainFooter = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.logotypeWrap}`} onClick={()=>{navigate('/')}}>
                    <img src={logo} alt="omt logotype"/>
                </div>
                <div className={`${Styles.navWrap}`}>
                    <div className={`${Styles.navItem}`} onClick={()=>{navigate('/privacy-policy')}}>{t('Политика конфиденциальности')}</div>
                    <div className={`${Styles.navItem}`} onClick={()=>{navigate('/user-agreement')}}>{t('Условия использования')}</div>
                    <div className={`${Styles.navItem}`} onClick={()=>{navigate('/white-paper/executive-summary')}}>{t('Доĸументация')}</div>
                    <div className={`${Styles.navItem}`} onClick={()=>{navigate('/news/page/1')}}>{t('Новости')}</div>
                </div>
                <div className={`${Styles.socWrap}`}>
                    <div
                        className={`${Styles.socItem}`}
                        onClick={() => {
                            window.open("https://www.instagram.com/omtwallet/", "_blank");
                        }}
                    >
                        <img src={soc_icon5} alt="instagram"/>
                    </div>
                    <div
                        className={`${Styles.socItem}`}
                        onClick={() => {
                            window.open("https://twitter.com/official_omtech", "_blank");
                        }}
                    >
                        <img src={soc_icon2} alt="twitter"/>
                    </div>
                    <div
                        className={`${Styles.socItem}`}
                        onClick={() => {
                            window.open("https://github.com/OracleMetaTechnologies", "_blank");
                        }}
                    >
                        <img src={soc_icon7} alt="github"/>
                    </div>
                    <div
                        className={`${Styles.socItem}`}
                        onClick={() => {
                            window.open("https://t.me/officialOMT", "_blank");
                        }}
                    >
                        <img src={soc_icon6} alt="telegram"/>
                    </div>
                    <div
                        className={`${Styles.socItem}`}
                        onClick={() => {
                            window.open("https://www.youtube.com/channel/UCDx29CIHFou4lrdIDHBRmiw", "_blank");
                        }}
                    >
                        <img src={soc_icon1} alt="youtube"/>
                    </div>
                    <div
                        className={`${Styles.socItem}`}
                        onClick={() => {
                            window.open("https://discord.gg/YWMJkwkg74", "_blank");
                        }}
                    >
                        <img src={soc_icon3} alt="discord"/>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default MainFooter;
