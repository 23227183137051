import React, {useEffect} from "react";
import PublicPresaleMain from "../../components/publicPresaleMain";
import Styles from "./styles.module.scss";
import PublicPresaleInfo from "../../components/publicPresaleInfo";
import PublicPresaleImgs from "../../components/publicPresaleImgs";
import {useDispatch} from "react-redux";
import {getPreSaleData} from "../../store/main/action";



const PublicPresalePage = () => {
    const dispatch = useDispatch();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    useEffect(()=>{
        dispatch(getPreSaleData());
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
           <PublicPresaleMain/>
           <PublicPresaleInfo/>
           <PublicPresaleImgs/>
       </section>
    )
}

export default PublicPresalePage;