import {
    TEST,
    SET_WALLET_CREATE_FORM_STATUS,
    SET_WALLET_TAKE_FORM_STATUS,
    SET_WALLET_SEND_FORM_STATUS,
    SET_STAKING_FORM_STATUS,
    SET_STAKING_BALANCE_STATUS,
    SET_BOT_BALANCE_STATUS,
    SET_BONUSES_MODAL_STATUS,
    GET_AVAILABLE_CURRENCIES_STARTED,
    GET_AVAILABLE_CURRENCIES_SUCCESS,
    GET_AVAILABLE_CURRENCIES_FAILURE,
    CREATE_WALLET_STARTED,
    CREATE_WALLET_SUCCESS,
    CREATE_WALLET_FAILURE,
    GET_WALLET_ASSETS_STARTED,
    GET_WALLET_ASSETS_SUCCESS,
    GET_WALLET_ASSETS_FAILURE,
    GET_AVAILABLE_STAKING_STARTED,
    GET_AVAILABLE_STAKING_SUCCESS,
    GET_AVAILABLE_STAKING_FAILURE,
    BALANCE_TRANSACTIONS_STARTED,
    BALANCE_TRANSACTIONS_SUCCESS,
    BALANCE_TRANSACTIONS_FAILURE,
    OPEN_STAKING_STARTED,
    OPEN_STAKING_SUCCESS,
    OPEN_STAKING_FAILURE,
    GET_WALLET_TRANSACTIONS_STARTED,
    GET_WALLET_TRANSACTIONS_SUCCESS,
    GET_WALLET_TRANSACTIONS_FAILURE,
    GET_USER_STAKING_STARTED,
    GET_USER_STAKING_SUCCESS,
    GET_USER_STAKING_FAILURE,
    GET_REF_MAIN_DATA_STARTED,
    GET_REF_MAIN_DATA_SUCCESS,
    GET_REF_MAIN_DATA_FAILURE,
    GET_REF_INFO_STARTED,
    GET_REF_INFO_SUCCESS,
    GET_REF_INFO_FAILURE,
    GET_REF_LOGS_STARTED,
    GET_REF_LOGS_SUCCESS,
    GET_REF_LOGS_FAILURE,
    SET_REF_LOGS_CORRECT_DATA,
    GET_BOT_MAIN_DATA_STARTED,
    GET_BOT_MAIN_DATA_SUCCESS,
    GET_BOT_MAIN_DATA_FAILURE,
    OPEN_BOT_STARTED,
    OPEN_BOT_SUCCESS,
    OPEN_BOT_FAILURE,
    CHANGE_BOT_RISK_STARTED,
    CHANGE_BOT_RISK_SUCCESS,
    CHANGE_BOT_RISK_FAILURE,
    GET_BOT_STATS_STARTED,
    GET_BOT_STATS_SUCCESS,
    GET_BOT_STATS_FAILURE,
    GET_BOT_STATS_CORRECT_DATA,
    GET_WALLET_ASSETS_WITH_BALANCE,
    SEND_TRANSACTIONS_STARTED,
    SEND_TRANSACTIONS_SUCCESS,
    SEND_TRANSACTIONS_FAILURE,
    STOP_BOT_STARTED,
    STOP_BOT_SUCCESS,
    STOP_BOT_FAILURE,
    SET_BOT_MODAL_STATUS,
    GET_BOT_HISTORY_STARTED,
    GET_BOT_HISTORY_SUCCESS,
    GET_BOT_HISTORY_FAILURE,
    CHANGE_PASSWORD_STARTED,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    GET_BANNERS_STARTED,
    GET_BANNERS_SUCCESS,
    GET_BANNERS_FAILURE,
    GET_BOT_PREVIEW_DATA_STARTED,
    GET_BOT_PREVIEW_DATA_SUCCESS,
    GET_BOT_PREVIEW_DATA_FAILURE,
    GET_EXCHANGE_DATA_STARTED,
    GET_EXCHANGE_DATA_SUCCESS,
    GET_EXCHANGE_DATA_FAILURE,
    EXCHANGE_STARTED,
    EXCHANGE_SUCCESS,
    EXCHANGE_FAILURE,
    GET_EXCHANGE_HISTORY_STARTED,
    GET_EXCHANGE_HISTORY_SUCCESS,
    GET_EXCHANGE_HISTORY_FAILURE,
    BOT_INVEST_STARTED,
    BOT_INVEST_SUCCESS,
    BOT_INVEST_FAILURE,
    GET_ONE_CURRENT_WALLET_STARTED,
    GET_ONE_CURRENT_WALLET_SUCCESS,
    GET_ONE_CURRENT_WALLET_FAILURE,
    GET_REFILLABLE_WALLETS,
    SET_STAKING_WITHDRAW_FORM_STATUS,
    WITHDRAW_STAKING_STARTED,
    WITHDRAW_STAKING_SUCCESS,
    WITHDRAW_STAKING_FAILURE,
    SEND_SUPPORT_FORM_STARTED,
    SEND_SUPPORT_FORM_SUCCESS,
    SEND_SUPPORT_FORM_FAILURE,
    SET_AMBASSADORS_MODAL_STATUS,
    GET_AMBASSADOR_MAIN_DATA_STARTED,
    GET_AMBASSADOR_MAIN_DATA_SUCCESS,
    GET_AMBASSADOR_MAIN_DATA_FAILURE,
    GET_AMBASSADOR_STATUSES_STARTED,
    GET_AMBASSADOR_STATUSES_SUCCESS,
    GET_AMBASSADOR_STATUSES_FAILURE,
    GET_AMBASSADOR_GRAPH_STARTED,
    GET_AMBASSADOR_GRAPH_SUCCESS,
    GET_AMBASSADOR_GRAPH_FAILURE,
    GET_AMBASSADOR_GRAPH_CORRECT_DATA,
    GET_AMBASSADOR_LINKS_STARTED,
    GET_AMBASSADOR_LINKS_SUCCESS,
    GET_AMBASSADOR_LINKS_FAILURE,
    SEND_AMBASSADOR_LINK_STARTED,
    SEND_AMBASSADOR_LINK_SUCCESS,
    SEND_AMBASSADOR_LINK_FAILURE,
    GET_AMBASSADOR_TURNOVER_STARTED,
    GET_AMBASSADOR_TURNOVER_SUCCESS,
    GET_AMBASSADOR_TURNOVER_FAILURE,
    AMBASSADOR_LINK_ERRORS,
    AMBASSADOR_LINK_ERRORS_CLEAR,
    AMBASSADOR_CORRECT_LINKS,
    SET_BOT_PROMO_MODAL_STATUS,
    SET_INFLUENCER_MODAL_STATUS,
    GET_TICKETS_STARTED,
    GET_TICKETS_SUCCESS,
    GET_TICKETS_FAILURE,
    SET_TICKETS_MODAL_STATUS,
    BUY_TICKETS_STARTED,
    BUY_TICKETS_SUCCESS,
    BUY_TICKETS_FAILURE,
    GET_CURRENT_WALLET_STARTED,
    GET_CURRENT_WALLET_SUCCESS,
    GET_CURRENT_WALLET_FAILURE,
    GET_CURRENT_WALLET_TRANSACTIONS_STARTED,
    GET_CURRENT_WALLET_TRANSACTIONS_SUCCESS,
    GET_CURRENT_WALLET_TRANSACTIONS_FAILURE,
    GET_EVENTS_STARTED,
    GET_EVENTS_SUCCESS,
    GET_EVENTS_FAILURE,
    GET_CURRENT_EVENT_STARTED,
    GET_CURRENT_EVENT_SUCCESS,
    GET_CURRENT_EVENT_FAILURE,
    SET_TASK_STARTED,
    SET_TASK_SUCCESS,
    SET_TASK_FAILURE,
    SET_COMMISSION_ALERT_STATUS,
    SET_CURRENT_WALLET,
    GET_WALLET_GRAPH_HISTORY_STARTED,
    GET_WALLET_GRAPH_HISTORY_SUCCESS,
    GET_WALLET_GRAPH_HISTORY_FAILURE,
    GET_TOTAL_BALANCE_STARTED,
    GET_TOTAL_BALANCE_SUCCESS,
    GET_TOTAL_BALANCE_FAILURE,
    SET_CREATE_WALLET_CURRENCIES,
    GET_CURRENCY_COMMISSION_STARTED,
    GET_CURRENCY_COMMISSION_SUCCESS,
    GET_CURRENCY_COMMISSION_FAILURE,
    SET_AMBASSADORS_MODAL_SALARY_STATUS,
    GET_AMBASSADOR_SALARY_DATA_STARTED,
    GET_AMBASSADOR_SALARY_DATA_SUCCESS,
    GET_AMBASSADOR_SALARY_DATA_FAILURE,
    GET_OMT_PERIODS_STARTED,
    GET_OMT_PERIODS_SUCCESS,
    GET_OMT_PERIODS_FAILURE,
    GET_AVAILABLE_WALLETS_STARTED,
    GET_AVAILABLE_WALLETS_SUCCESS,
    GET_AVAILABLE_WALLETS_FAILURE,
    GET_BOT_SUBSCRIBE_INFO_STARTED,
    GET_BOT_SUBSCRIBE_INFO_SUCCESS,
    GET_BOT_SUBSCRIBE_INFO_FAILURE,
    SET_BOT_SUBSCRIBE_MODAL_STATUS,
    GET_CUSTOM_SETTINGS_STARTED,
    GET_CUSTOM_SETTINGS_SUCCESS,
    GET_CUSTOM_SETTINGS_FAILURE,
    SET_CUSTOM_SETTINGS_STARTED,
    SET_CUSTOM_SETTINGS_SUCCESS,
    SET_CUSTOM_SETTINGS_FAILURE,
    BUY_SUBSCRIBE_STARTED,
    BUY_SUBSCRIBE_SUCCESS,
    BUY_SUBSCRIBE_FAILURE,
    SET_ALERT_MODAL_STATUS,
    CREATE_CURRENT_WALLET_STARTED,
    CREATE_CURRENT_WALLET_SUCCESS,
    CREATE_CURRENT_WALLET_FAILURE,
    SET_STAKING_FORM_OMT_STATUS,
    GET_STAKING_COMMISSION_STARTED,
    GET_STAKING_COMMISSION_SUCCESS,
    GET_STAKING_COMMISSION_FAILURE,
    GET_STAKING_AWARD_STARTED,
    GET_STAKING_AWARD_SUCCESS,
    GET_STAKING_AWARD_FAILURE,
    GET_TRANSFER_COMMISSION_STARTED,
    GET_TRANSFER_COMMISSION_SUCCESS,
    GET_TRANSFER_COMMISSION_FAILURE,
    WITHDRAW_OMT_STAKING_STARTED,
    WITHDRAW_OMT_STAKING_SUCCESS,
    WITHDRAW_OMT_STAKING_FAILURE,
    SET_STANDARD_WALLET_CRYPTO_MODAL_STATUS,
    GET_INDICATORS_STARTED,
    GET_INDICATORS_SUCCESS,
    GET_INDICATORS_FAILURE,
    GET_CRYPTO_NEWS_STARTED,
    GET_CRYPTO_NEWS_SUCCESS,
    GET_CRYPTO_NEWS_FAILURE,
    SET_BOT_SUBSCRIBE_ALERT_MODAL_STATUS,
    GET_HONEECHIZE_MAIN_DATA_STARTED,
    GET_HONEECHIZE_MAIN_DATA_SUCCESS,
    GET_HONEECHIZE_MAIN_DATA_FAILURE,
    GET_HONEECHIZE_EARNINGS_GRAPH_DATA_STARTED,
    GET_HONEECHIZE_EARNINGS_GRAPH_DATA_SUCCESS,
    GET_HONEECHIZE_EARNINGS_GRAPH_DATA_FAILURE,
    GET_HONEECHIZE_PROFIT_GRAPH_DATA_STARTED,
    GET_HONEECHIZE_PROFIT_GRAPH_DATA_SUCCESS,
    GET_HONEECHIZE_PROFIT_GRAPH_DATA_FAILURE,
    GET_HONEECHIZE_AFFILIATE_SETTINGS_STARTED,
    GET_HONEECHIZE_AFFILIATE_SETTINGS_SUCCESS,
    GET_HONEECHIZE_AFFILIATE_SETTINGS_FAILURE,
    SET_HONEECHIZE_AFFILIATE_SETTINGS_MODAL_STATUS,
    CHANGE_HONEECHIZE_AFFILIATE_PERCENT_STARTED,
    CHANGE_HONEECHIZE_AFFILIATE_PERCENT_SUCCESS,
    CHANGE_HONEECHIZE_AFFILIATE_PERCENT_FAILURE,
    SET_HONEECHIZE_AFFILIATE_TABLE_MODAL_STATUS,
    GET_HONEECHIZE_BOT_EARNINGS_STARTED,
    GET_HONEECHIZE_BOT_EARNINGS_SUCCESS,
    GET_HONEECHIZE_BOT_EARNINGS_FAILURE,
    GET_HONEECHIZE_BOT_SETTINGS_STARTED,
    GET_HONEECHIZE_BOT_SETTINGS_SUCCESS,
    GET_HONEECHIZE_BOT_SETTINGS_FAILURE,
    SET_HONEECHIZE_BOT_SETTINGS_STARTED,
    SET_HONEECHIZE_BOT_SETTINGS_SUCCESS,
    SET_HONEECHIZE_BOT_SETTINGS_FAILURE,
    GET_HONEECHIZE_AFFILIATE_STATISTIC_STARTED,
    GET_HONEECHIZE_AFFILIATE_STATISTIC_SUCCESS,
    GET_HONEECHIZE_AFFILIATE_STATISTIC_FAILURE,
    SET_REF_LOGS_PAGE_DATA,
    SET_HONEECHIZE_ALERT_MODAL_STATUS,
    SET_HONEECHIZE_AFFILIATE_STATISTIC_PAGE_DATA,
    SET_HONEECHIZE_AFFILIATE_STATISTIC_CORRECT_DATA,
    GET_COMMUNITY_BOOST_MAIN_DATA_STARTED,
    GET_COMMUNITY_BOOST_MAIN_DATA_SUCCESS,
    GET_COMMUNITY_BOOST_MAIN_DATA_FAILURE,
    SET_CB_TASK_MODAL_STATUS,
    SET_CB_REWARD_MODAL_STATUS,
    SEND_COMMUNITY_BOOST_POST_STARTED,
    SEND_COMMUNITY_BOOST_POST_SUCCESS,
    SEND_COMMUNITY_BOOST_POST_FAILURE,
    GET_COMMUNITY_BOOST_LEADERBOARD_DATA_STARTED,
    GET_COMMUNITY_BOOST_LEADERBOARD_DATA_SUCCESS,
    GET_COMMUNITY_BOOST_LEADERBOARD_DATA_FAILURE,
    GET_INDEX_MAIN_DATA_STARTED,
    GET_INDEX_MAIN_DATA_SUCCESS,
    GET_INDEX_MAIN_DATA_FAILURE,
    INDEX_SWAP_STARTED,
    INDEX_SWAP_SUCCESS,
    INDEX_SWAP_FAILURE,
    GET_INDEX_SWAP_HISTORY_STARTED,
    GET_INDEX_SWAP_HISTORY_SUCCESS,
    GET_INDEX_SWAP_HISTORY_FAILURE,
    GET_INDEX_SWAP_PREDICTION_STARTED,
    GET_INDEX_SWAP_PREDICTION_SUCCESS,
    GET_INDEX_SWAP_PREDICTION_FAILURE
} from "./types";


const initialState = {
    currentWalletIndex: false,
    walletAssetsStarted: false,
    walletAssets: false,
    walletAssetsWithBalance: false,
    refillableWallets: false,
    walletAssetsErr: false,
    commissionAlertStatus: localStorage.getItem('alertStatus') ? localStorage.getItem('alertStatus') : false,
    createFormStatus: false,
    alertModalStatus: false,
    cbModalStatus: false,
    cbRewardModalStatus: false,
    honeechizeAlertModalStatus: false,
    honeechizeAffiliateSettingsModalStatus: {status: false, data: false},
    honeechizeAffiliateTableModalStatus: false,
    botSubscribeAlertModalStatus: false,
    standardWalletCryptoModalStatus: {status: false, item: false},
    takeFormStatus: {status: false, data: false},
    sendFormStatus: {status: false, data: false},
    stakingFormStatus: {status: false, item: false, balance: false},
    stakingFormOmtStatus: {status: false, item: false, balance: false},
    stakingWithdrawFormStatus: {status: false, item: false, balance: false},
    stakingBalanceStatus: {status: false, type: false, balance: false, withdrawBalance: false},
    botBalanceStatus: {status: false, type: false, balance: false, withdrawBalance: false},
    bonusesModalStatus: false,
    botModalStatus: false,
    botSubscribeModalStatus: false,
    ticketsModalStatus: false,
    botPromoModalStatus: {status: false, balance: false, sendAction: false, botId: false, isOpen: false, currentRisk: false},
    ambassadorsModalStatus: false,
    ambassadorsModalSalaryStatus: false,
    influencerModalStatus: false,
    buyTicketStatusStarted: false,
    buyTicketStatus: false,
    buyTicketStatusErr: false,
    stakingCommissionStarted: false,
    stakingCommission: false,
    stakingCommissionErr: false,
    currentWalletStarted: false,
    currentWallet: false,
    currentWalletErr: false,
    currentWalletTransactionsStarted: false,
    currentWalletTransactions: false,
    currentWalletTransactionsErr: false,
    eventsStarted: false,
    events: false,
    eventsErr: false,
    currentEventStarted: false,
    currentEvent: false,
    currentEventErr: false,
    totalBalanceStarted: false,
    totalBalance: false,
    totalBalanceErr: false,
    transferCommissionStarted: false,
    transferCommission: false,
    transferCommissionErr: false,
    honeechizeMainDataStarted: false,
    honeechizeMainData: false,
    honeechizeMainDataErr: false,
    honeechizeAffiliateSettingsStarted: false,
    honeechizeAffiliateSettings: false,
    honeechizeAffiliateSettingsErr: false,
    changeHoneechizeAffiliatePercentStarted: false,
    changeHoneechizeAffiliatePercent: false,
    changeHoneechizeAffiliatePercentErr: false,
    honeechizeBotEarningsDataStarted: false,
    honeechizeBotEarningsData: false,
    honeechizeBotEarningsDataErr: false,
    honeechizeBotSettingsDataStarted: false,
    honeechizeBotSettingsData: false,
    honeechizeBotSettingsDataErr: false,
    setHoneechizeBotSettingsDataStarted: false,
    setHoneechizeBotSettingsData: false,
    setHoneechizeBotSettingsDataErr: false,
    taskStatusStarted: false,
    taskStatus: false,
    taskStatusErr: {},
    ambassadors: {
        status: 'accepted',
        data: {
            color: '#E80000',
            name: 'red',
            progress: {
                current: '180',
                next: '5000'
            },
            privileges: {
                p3: '5',
                p5: '1'
            },
            rewards: {
                honee: '75',
                omt: '100'
            },
            levels: '5%-3%-1%-1%-1%',
            bonus: '50'
        },
        graphData: {
            min: 7,
            max: 20,
            data: [
                {day: 'Mon', value: 15},
                {day: 'Tue', value: 7},
                {day: 'Wed', value: 5},
                {day: 'Thu', value: 20},
                {day: 'Fri', value: 10},
                {day: 'Sat', value: 10},
                {day: 'Sun', value: 10},
            ]
        },
        promo: {
            p3: {code: 'y54st', current: 1, all: 5},
            p5: {code: 'd3f56', current: 1, all: 1}
        },
        tasks:{
            session: {status: 'rejected', link: false},
            review: {status: 'rejected', link: false},
            social_ambassador: {status: 'rejected', link: false},
            social_post: [
                {status: 'rejected', link: false},
                {status: 'rejected', link: false},
                {status: 'rejected', link: false}
            ]
        },
    },
    createWalletCurrencies: false,
    ambassadorMainDataStarted: false,
    ambassadorMainData: false,
    ambassadorMainDataErr: false,
    sendCbPostStarted: false,
    sendCbPost: false,
    sendCbPostErr: false,
    ambassadorStatusesStarted: false,
    ambassadorStatuses: false,
    ambassadorStatusesErr: false,
    ambassadorGraphStarted: false,
    ambassadorGraph: false,
    ambassadorGraphErr: false,
    ambassadorGraphCorrectData: false,
    ambassadorLinksStarted: false,
    ambassadorCorrectLinks: false,
    ambassadorLinks: false,
    ambassadorLinksErr: false,
    ambassadorLinksErrors: {},
    ambassadorTurnoverStarted: false,
    ambassadorTurnover: false,
    ambassadorTurnoverErr: false,
    tickets: false,
    ticketsStarted: false,
    ticketsErr: false,
    sendAmbassadorLinkStarted: false,
    sendAmbassadorLink: false,
    sendAmbassadorLinkErr: false,
    createWalletAvailableCurrenciesStarted: false,
    createWalletAvailableCurrencies: false,
    createWalletAvailableCurrenciesErr: false,
    createWalletStarted: false,
    createWallet: false,
    createWalletErr: false,
    availableStakingStarted: false,
    availableStaking: false,
    availableStakingErr: false,
    balanceTransactionStarted : false,
    balanceTransaction : false,
    balanceTransactionErr : false,
    newOpenStakingStarted: false,
    newOpenStaking: false,
    newOpenStakingErr: false,
    withdrawStakingStarted: false,
    withdrawStaking: false,
    withdrawStakingErr: false,
    withdrawOmtStakingStarted: false,
    withdrawOmtStaking: false,
    withdrawOmtStakingErr: false,
    walletTransactionsStarted: false,
    walletTransactions: false,
    walletTransactionsErr: false,
    userStakingStarted: false,
    userStaking: false,
    userStakingErr: false,
    refMainDataStarted: false,
    refMainData: false,
    refMainDataErr: false,
    refInfoStarted: false,
    refInfo: false,
    refInfoErr: false,
    refLogsStarted: false,
    refLogs: false,
    refLogsCorrectData: false,
    refLogsErr: false,
    refLogsPageData: false,
    botMainDataStarted: false,
    botMainData: false,
    botMainDataErr: false,
    newOpenBotStarted: false,
    newOpenBot: false,
    newOpenBotErr: false,
    newChangeRiskTypeStarted: false,
    newChangeRiskType: false,
    newChangeRiskTypeErr: false,
    botStatsStarted: false,
    botStats: false,
    botStatsErr: false,
    botStatsCorrectData: false,
    sendTransactionsStatusStarted: false,
    sendTransactionsStatus: false,
    sendTransactionsStatusErr: false,
    stopBotStatusStarted: false,
    stopBotStatus: false,
    stopBotStatusErr: false,
    botHistoryStarted: false,
    botHistory: false,
    botHistoryErr: false,
    changePasswordStatusStarted: false,
    changePasswordStatus: false,
    changePasswordStatusErr: false,
    bannersStarted: false,
    banners: false,
    bannersErr: false,
    botPreviewDataStarted: false,
    botPreviewData: false,
    botPreviewDataErr: false,
    exchangeDataStarted: false,
    exchangeData: false,
    exchangeDataErr: false,
    exchangeStatusStarted: false,
    exchangeStatus: false,
    exchangeStatusErr: false,
    exchangeHistoryStarted: false,
    exchangeHistory: false,
    exchangeHistoryErr: false,
    botInvestStatusStarted: false,
    botInvestStatus: false,
    botInvestStatusErr: false,
    oneCurrentWalletStarted: false,
    oneCurrentWallet: false,
    oneCurrentWalletErr: false,
    supportFormStarted: false,
    supportForm: false,
    supportFormErr: false,
    walletGraphHistoryStarted: false,
    walletGraphHistory: false,
    walletGraphHistoryErr: false,
    currencyCommissionStarted: false,
    currencyCommission: false,
    currencyCommissionErr: false,
    ambassadorsSalaryDataStarted: false,
    ambassadorsSalaryData: false,
    ambassadorsSalaryDataErr: false,
    omtPeriodsStarted: false,
    omtPeriods: false,
    omtPeriodsErr: false,
    availableWalletsStarted: false,
    availableWallets: false,
    availableWalletsErr: false,
    botSubscribeInfoStarted: false,
    botSubscribeInfo: false,
    botSubscribeInfoErr: false,
    customSettingsStarted: false,
    customSettings: false,
    customSettingsErr: false,
    setCustomSettingsStarted: false,
    setCustomSettings: false,
    setCustomSettingsErr: false,
    buySubscribeStatusStarted: false,
    buySubscribeStatus: false,
    buySubscribeStatusErr: false,
    createCurrentWalletStarted: false,
    createCurrentWallet: false,
    createCurrentWalletErr: false,
    stakingAwardStarted: false,
    stakingAward: false,
    stakingAwardErr: false,
    walletIndicatorsStarted: false,
    walletIndicators: false,
    walletIndicatorsErr: false,
    cryptoNewsDataStarted: false,
    cryptoNewsData: false,
    cryptoNewsDataErr: false,
    honeechizeAffiliateStatisticStarted: false,
    honeechizeAffiliateStatistic: false,
    honeechizeAffiliateStatisticErr: false,
    honeechizeAffiliateStatisticPageData: false,
    honeechizeAffiliateStatisticCorrectData: false,
    honeechizeEarningsGraphDataStarted: false,
    honeechizeEarningsGraphData: false,
    honeechizeEarningsGraphDataErr: false,
    honeechizeProfitGraphDataStarted: false,
    honeechizeProfitGraphData: false,
    honeechizeProfitGraphDataErr: false,
    communityBoostMainDataStarted: false,
    communityBoostMainData: false,
    communityBoostMainDataErr: false,
    communityBoostLeaderboardDataStarted: false,
    communityBoostLeaderboardData: false,
    communityBoostLeaderboardDataErr: false,
    indexMainDataStarted: false,
    indexMainData: false,
    indexMainDataErr: false,
    indexSwapStatusStarted: false,
    indexSwapStatus: false,
    indexSwapStatusErr: false,
    indexSwapHistoryStarted: false,
    indexSwapHistory: false,
    indexSwapHistoryErr: false,
    indexSwapPredictionStarted: false,
    indexSwapPrediction: false,
    indexSwapPredictionErr: false,
    honeechizeStatistic: [
        {name: 'FriedBrock', level: 0, deposit_amount: 0, refferal_earnings: 0, self_earnings: 0},
        {name: 'Holand', level: 1, deposit_amount: 254, refferal_earnings: 61.32, self_earnings: 13.32},
        {name: 'Jumber', level: 1, deposit_amount: 870, refferal_earnings: 11.19, self_earnings: 2.19},
        {name: 'FriedBrock', level: 0, deposit_amount: 0, refferal_earnings: 0, self_earnings: 0},
        {name: 'Holand', level: 1, deposit_amount: 254, refferal_earnings: 61.32, self_earnings: 13.32},
        {name: 'Jumber', level: 1, deposit_amount: 870, refferal_earnings: 11.19, self_earnings: 2.19},
        {name: 'FriedBrock', level: 0, deposit_amount: 0, refferal_earnings: 0, self_earnings: 0},
        {name: 'Holand', level: 1, deposit_amount: 254, refferal_earnings: 61.32, self_earnings: 13.32},
        {name: 'Jumber', level: 1, deposit_amount: 870, refferal_earnings: 11.19, self_earnings: 2.19},
    ]
};

export function walletReducer(state = initialState, action) {
    switch (action.type) {
        case TEST:
            return {
                ...state,
                status: action.payload
            };
        case SET_WALLET_CREATE_FORM_STATUS:
            return {
                ...state,
                createFormStatus: action.payload
            };
        case SET_CURRENT_WALLET:
            return {
                ...state,
                currentWalletIndex: action.payload
            };
        case SET_WALLET_TAKE_FORM_STATUS:
            return {
                ...state,
                takeFormStatus: action.payload
            };
        case SET_STANDARD_WALLET_CRYPTO_MODAL_STATUS:
            return {
                ...state,
                standardWalletCryptoModalStatus: action.payload
            };
        case SET_CB_TASK_MODAL_STATUS:
            return {
                ...state,
                cbModalStatus: action.payload
            };
        case SET_CB_REWARD_MODAL_STATUS:
            return {
                ...state,
                cbRewardModalStatus: action.payload
            };
        case SET_HONEECHIZE_AFFILIATE_SETTINGS_MODAL_STATUS:
            return {
                ...state,
                honeechizeAffiliateSettingsModalStatus: action.payload
            };
        case SET_HONEECHIZE_AFFILIATE_TABLE_MODAL_STATUS:
            return {
                ...state,
                honeechizeAffiliateTableModalStatus: action.payload
            };
        case SET_WALLET_SEND_FORM_STATUS:
            return {
                ...state,
                sendFormStatus: action.payload
            };
        case SET_STAKING_FORM_STATUS:
            return {
                ...state,
                stakingFormStatus: action.payload
            };
        case SET_STAKING_FORM_OMT_STATUS:
            return {
                ...state,
                stakingFormOmtStatus: action.payload
            };
        case SET_STAKING_WITHDRAW_FORM_STATUS:
            return {
                ...state,
                stakingWithdrawFormStatus: action.payload
            };
        case SET_ALERT_MODAL_STATUS:
            return {
                ...state,
                alertModalStatus: action.payload
            };
        case SET_HONEECHIZE_ALERT_MODAL_STATUS:
            return {
                ...state,
                honeechizeAlertModalStatus: action.payload
            };
        case SET_BOT_SUBSCRIBE_ALERT_MODAL_STATUS:
            return {
                ...state,
                botSubscribeAlertModalStatus: action.payload
            };
        case SET_STAKING_BALANCE_STATUS:
            return {
                ...state,
                stakingBalanceStatus: action.payload
            };
        case SET_BOT_BALANCE_STATUS:
            return {
                ...state,
                botBalanceStatus: action.payload
            };
        case SET_BONUSES_MODAL_STATUS:
            return {
                ...state,
                bonusesModalStatus: action.payload
            };
        case SET_BOT_MODAL_STATUS:
            return {
                ...state,
                botModalStatus: action.payload
            };
        case SET_BOT_SUBSCRIBE_MODAL_STATUS:
            return {
                ...state,
                botSubscribeModalStatus: action.payload
            };
        case SET_BOT_PROMO_MODAL_STATUS:
            return {
                ...state,
                botPromoModalStatus: action.payload
            };
        case SET_AMBASSADORS_MODAL_STATUS:
            return {
                ...state,
                ambassadorsModalStatus: action.payload
            };
        case SET_AMBASSADORS_MODAL_SALARY_STATUS:
            return {
                ...state,
                ambassadorsModalSalaryStatus: action.payload
            };
        case GET_AVAILABLE_CURRENCIES_STARTED:
            return {
                ...state,
                createWalletAvailableCurrenciesStarted: true
            };
        case GET_AVAILABLE_CURRENCIES_SUCCESS:
            return {
                ...state,
                createWalletAvailableCurrencies: action.payload,
                createWalletAvailableCurrenciesStarted: false
            };
        case GET_AVAILABLE_CURRENCIES_FAILURE:
            return {
                ...state,
                createWalletAvailableCurrenciesErr: action.payload,
                createWalletAvailableCurrenciesStarted: false
            };
        case CREATE_WALLET_STARTED:
            return {
                ...state,
                createWalletStarted: true
            };
        case CREATE_WALLET_SUCCESS:
            return {
                ...state,
                createWallet: action.payload,
                createWalletStarted: false
            };
        case CREATE_WALLET_FAILURE:
            return {
                ...state,
                createWalletErr: action.payload,
                createWalletStarted: false
            };
        case GET_WALLET_ASSETS_STARTED:
            return {
                ...state,
                walletAssetsStarted: true
            };
        case GET_WALLET_ASSETS_SUCCESS:
            return {
                ...state,
                walletAssets: action.payload,
                walletAssetsStarted: false
            };
        case GET_WALLET_ASSETS_FAILURE:
            return {
                ...state,
                walletAssets: action.payload,
                walletAssetsStarted: false
            };
        case GET_AVAILABLE_STAKING_STARTED:
            return {
                ...state,
                availableStakingStarted: true
            };
        case GET_AVAILABLE_STAKING_SUCCESS:
            return {
                ...state,
                availableStaking: action.payload,
                availableStakingStarted: false
            };
        case GET_AVAILABLE_STAKING_FAILURE:
            return {
                ...state,
                availableStakingErr: action.payload,
                availableStakingStarted: false
            };
        case BALANCE_TRANSACTIONS_STARTED:
            return {
                ...state,
                balanceTransactionStarted: true
            };
        case BALANCE_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                balanceTransaction: action.payload,
                balanceTransactionStarted: false
            };
        case BALANCE_TRANSACTIONS_FAILURE:
            return {
                ...state,
                balanceTransactionErr: action.payload,
                balanceTransactionStarted: false
            };
        case OPEN_STAKING_STARTED:
            return {
                ...state,
                newOpenStakingStarted: true
            };
        case OPEN_STAKING_SUCCESS:
            return {
                ...state,
                newOpenStaking: action.payload,
                newOpenStakingStarted: false
            };
        case OPEN_STAKING_FAILURE:
            return {
                ...state,
                newOpenStakingErr: action.payload,
                newOpenStakingStarted: false
            };
        case GET_WALLET_TRANSACTIONS_STARTED:
            return {
                ...state,
                walletTransactionsStarted: true
            };
        case GET_WALLET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                walletTransactions: action.payload,
                walletTransactionsStarted: false
            };
        case GET_WALLET_TRANSACTIONS_FAILURE:
            return {
                ...state,
                walletTransactionsErr: action.payload,
                walletTransactionsStarted: false
            };
        case GET_USER_STAKING_STARTED:
            return {
                ...state,
                userStakingStarted: true
            };
        case GET_USER_STAKING_SUCCESS:
            return {
                ...state,
                userStaking: action.payload,
                userStakingStarted: false
            };
        case GET_USER_STAKING_FAILURE:
            return {
                ...state,
                userStakingErr: action.payload,
                userStakingStarted: false
            };
        case GET_REF_MAIN_DATA_STARTED:
            return {
                ...state,
                refMainDataStarted: true
            };
        case GET_REF_MAIN_DATA_SUCCESS:
            return {
                ...state,
                refMainData: action.payload,
                refMainDataStarted: false
            };
        case GET_REF_MAIN_DATA_FAILURE:
            return {
                ...state,
                refMainDataErr: action.payload,
                refMainDataStarted: false
            };
        case GET_REF_INFO_STARTED:
            return {
                ...state,
                refInfoStarted: true
            };
        case GET_REF_INFO_SUCCESS:
            return {
                ...state,
                refInfo: action.payload,
                refInfoStarted: false
            };
        case GET_REF_INFO_FAILURE:
            return {
                ...state,
                refInfoErr: action.payload,
                refInfoStarted: false
            };
        case GET_REF_LOGS_STARTED:
            return {
                ...state,
                refLogsStarted: true
            };
        case GET_REF_LOGS_SUCCESS:
            return {
                ...state,
                refLogs: action.payload,
                refLogsStarted: false
            };
        case GET_REF_LOGS_FAILURE:
            return {
                ...state,
                refLogsErr: action.payload,
                refLogsStarted: false
            };
        case SET_REF_LOGS_CORRECT_DATA:
            if (state.refLogsCorrectData && action.payload?.pageStatus){
                let paginateData = {
                    all: [...state.refLogsCorrectData.all, ...action.payload.all],
                    bot: [...state.refLogsCorrectData.bot, ...action.payload.bot],
                    staking: [...state.refLogsCorrectData.staking, ...action.payload.staking],
                    pageStatus: action.payload?.pageStatus
                }
                if (action.payload?.pageStatus !== state.refLogsCorrectData.pageStatus){
                    return {
                        ...state,
                        refLogsCorrectData: paginateData
                    };
                } else {
                    break;
                }
            } else {
                return {
                    ...state,
                    refLogsCorrectData: action.payload
                };
            }
        case SET_HONEECHIZE_AFFILIATE_STATISTIC_CORRECT_DATA:
            if (state.honeechizeAffiliateStatisticCorrectData && action.payload?.pageStatus){
                let paginateData = {
                    day: [...state.honeechizeAffiliateStatisticCorrectData.day, ...action.payload.day],
                    week: [...state.honeechizeAffiliateStatisticCorrectData.week, ...action.payload.week],
                    month: [...state.honeechizeAffiliateStatisticCorrectData.month, ...action.payload.month],
                    all: [...state.honeechizeAffiliateStatisticCorrectData.all, ...action.payload.all],
                    pageStatus: action.payload?.pageStatus
                }

                if (action.payload?.pageStatus !== state.honeechizeAffiliateStatisticCorrectData.pageStatus){
                    return {
                        ...state,
                        honeechizeAffiliateStatisticCorrectData: paginateData
                    };
                } else {
                    break;
                }
            } else {
                return {
                    ...state,
                    honeechizeAffiliateStatisticCorrectData: action.payload
                };
            }
        case SET_REF_LOGS_PAGE_DATA:
            return {
                ...state,
                refLogsPageData: action.payload
            };
        case SET_HONEECHIZE_AFFILIATE_STATISTIC_PAGE_DATA:
            return {
                ...state,
                honeechizeAffiliateStatisticPageData: action.payload
            };
        case GET_BOT_MAIN_DATA_STARTED:
            return {
                ...state,
                botMainDataStarted: true
            };
        case GET_BOT_MAIN_DATA_SUCCESS:
            return {
                ...state,
                botMainData: action.payload,
                botMainDataStarted: false
            };
        case GET_BOT_MAIN_DATA_FAILURE:
            return {
                ...state,
                botMainDataErr: action.payload,
                botMainDataStarted: false
            };
        case OPEN_BOT_STARTED:
            return {
                ...state,
                newOpenBotStarted: true
            };
        case OPEN_BOT_SUCCESS:
            return {
                ...state,
                newOpenBot: action.payload,
                newOpenBotStarted: false
            };
        case OPEN_BOT_FAILURE:
            return {
                ...state,
                newOpenBotErr: action.payload,
                newOpenBotStarted: false
            };
        case CHANGE_BOT_RISK_STARTED:
            return {
                ...state,
                newChangeRiskTypeStarted: true
            };
        case CHANGE_BOT_RISK_SUCCESS:
            return {
                ...state,
                newChangeRiskType: action.payload,
                newChangeRiskTypeStarted: false
            };
        case CHANGE_BOT_RISK_FAILURE:
            return {
                ...state,
                newChangeRiskTypeErr: action.payload,
                newChangeRiskTypeStarted: false
            };
        case GET_BOT_STATS_STARTED:
            return {
                ...state,
                botStatsStarted: true
            };
        case GET_BOT_STATS_SUCCESS:
            return {
                ...state,
                botStats: action.payload,
                botStatsStarted: false
            };
        case GET_BOT_STATS_FAILURE:
            return {
                ...state,
                botStatsErr: action.payload,
                botStatsStarted: false
            };
        case GET_BOT_STATS_CORRECT_DATA:
            return {
                ...state,
                botStatsCorrectData: action.payload
            };
        case GET_WALLET_ASSETS_WITH_BALANCE:
            return {
                ...state,
                walletAssetsWithBalance: action.payload
            };
        case GET_REFILLABLE_WALLETS:
            return {
                ...state,
                refillableWallets: action.payload
            };
        case SEND_TRANSACTIONS_STARTED:
            return {
                ...state,
                sendTransactionsStatusStarted: true
            };
        case SEND_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                sendTransactionsStatus: action.payload,
                sendTransactionsStatusStarted: false
            };
        case SEND_TRANSACTIONS_FAILURE:
            return {
                ...state,
                sendTransactionsStatusErr: action.payload,
                sendTransactionsStatusStarted: false
            };
        case STOP_BOT_STARTED:
            return {
                ...state,
                stopBotStatusStarted: true
            };
        case STOP_BOT_SUCCESS:
            return {
                ...state,
                stopBotStatus: action.payload,
                stopBotStatusStarted: false
            };
        case STOP_BOT_FAILURE:
            return {
                ...state,
                stopBotStatusErr: action.payload,
                stopBotStatusStarted: false
            };
        case GET_BOT_HISTORY_STARTED:
            return {
                ...state,
                botHistoryStarted: true
            };
        case GET_BOT_HISTORY_SUCCESS:
            return {
                ...state,
                botHistory: action.payload,
                botHistoryStarted: false
            };
        case GET_BOT_HISTORY_FAILURE:
            return {
                ...state,
                botHistoryErr: action.payload,
                botHistoryStarted: false
            };
        case CHANGE_PASSWORD_STARTED:
            return {
                ...state,
                changePasswordStatusStarted: true
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordStatus: action.payload,
                changePasswordStatusStarted: false
            };
        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePasswordStatusErr: action.payload,
                changePasswordStatusStarted: false
            };
        case GET_BANNERS_STARTED:
            return {
                ...state,
                bannersStarted: true
            };
        case GET_BANNERS_SUCCESS:
            return {
                ...state,
                banners: action.payload,
                bannersStarted: false
            };
        case GET_BANNERS_FAILURE:
            return {
                ...state,
                bannersErr: action.payload,
                bannersStarted: false
            };
        case GET_BOT_PREVIEW_DATA_STARTED:
            return {
                ...state,
                botPreviewDataStarted: true
            };
        case GET_BOT_PREVIEW_DATA_SUCCESS:
            return {
                ...state,
                botPreviewData: action.payload,
                botPreviewDataStarted: false
            };
        case GET_BOT_PREVIEW_DATA_FAILURE:
            return {
                ...state,
                botPreviewDataErr: action.payload,
                botPreviewDataStarted: false
            };
        case GET_EXCHANGE_DATA_STARTED:
            return {
                ...state,
                exchangeDataStarted: true
            };
        case GET_EXCHANGE_DATA_SUCCESS:
            return {
                ...state,
                exchangeData: action.payload,
                exchangeDataStarted: false
            };
        case GET_EXCHANGE_DATA_FAILURE:
            return {
                ...state,
                exchangeDataErr: action.payload,
                exchangeDataStarted: false
            };
        case EXCHANGE_STARTED:
            return {
                ...state,
                exchangeStatusStarted: true
            };
        case EXCHANGE_SUCCESS:
            return {
                ...state,
                exchangeStatus: action.payload,
                exchangeStatusStarted: false
            };
        case EXCHANGE_FAILURE:
            return {
                ...state,
                exchangeStatusErr: action.payload,
                exchangeStatusStarted: false
            };
        case GET_EXCHANGE_HISTORY_STARTED:
            return {
                ...state,
                exchangeHistoryStarted: true
            };
        case GET_EXCHANGE_HISTORY_SUCCESS:
            return {
                ...state,
                exchangeHistory: action.payload,
                exchangeHistoryStarted: false
            };
        case GET_EXCHANGE_HISTORY_FAILURE:
            return {
                ...state,
                exchangeHistoryErr: action.payload,
                exchangeHistoryStarted: false
            };
        case BOT_INVEST_STARTED:
            return {
                ...state,
                botInvestStatusStarted: true
            };
        case BOT_INVEST_SUCCESS:
            return {
                ...state,
                botInvestStatus: action.payload,
                botInvestStatusStarted: false
            };
        case BOT_INVEST_FAILURE:
            return {
                ...state,
                botInvestStatusErr: action.payload,
                botInvestStatusStarted: false
            };
        case GET_ONE_CURRENT_WALLET_STARTED:
            return {
                ...state,
                oneCurrentWalletStarted: true
            };
        case GET_ONE_CURRENT_WALLET_SUCCESS:
            return {
                ...state,
                oneCurrentWallet: action.payload,
                oneCurrentWalletStarted: false
            };
        case GET_ONE_CURRENT_WALLET_FAILURE:
            return {
                ...state,
                oneCurrentWalletErr: action.payload,
                oneCurrentWalletStarted: false
            };
        case WITHDRAW_STAKING_STARTED:
            return {
                ...state,
                withdrawStakingStarted: true
            };
        case WITHDRAW_STAKING_SUCCESS:
            return {
                ...state,
                withdrawStaking: action.payload,
                withdrawStakingStarted: false
            };
        case WITHDRAW_STAKING_FAILURE:
            return {
                ...state,
                withdrawStakingErr: action.payload,
                withdrawStakingStarted: false
            };
        case SEND_SUPPORT_FORM_STARTED:
            return {
                ...state,
                supportFormStarted: true
            };
        case SEND_SUPPORT_FORM_SUCCESS:
            return {
                ...state,
                supportForm: action.payload,
                supportFormStarted: false
            };
        case SEND_SUPPORT_FORM_FAILURE:
            return {
                ...state,
                supportFormErr: action.payload,
                supportFormStarted: false
            };
        case GET_AMBASSADOR_MAIN_DATA_STARTED:
            return {
                ...state,
                ambassadorMainDataStarted: true
            };
        case GET_AMBASSADOR_MAIN_DATA_SUCCESS:
            return {
                ...state,
                ambassadorMainData: action.payload,
                ambassadorMainDataStarted: false
            };
        case GET_AMBASSADOR_MAIN_DATA_FAILURE:
            return {
                ...state,
                ambassadorMainDataErr: action.payload,
                ambassadorMainDataStarted: false
            };
        case GET_AMBASSADOR_STATUSES_STARTED:
            return {
                ...state,
                ambassadorStatusesStarted: true
            };
        case GET_AMBASSADOR_STATUSES_SUCCESS:
            return {
                ...state,
                ambassadorStatuses: action.payload,
                ambassadorStatusesStarted: false
            };
        case GET_AMBASSADOR_STATUSES_FAILURE:
            return {
                ...state,
                ambassadorStatusesErr: action.payload,
                ambassadorStatusesStarted: false
            };
        case GET_AMBASSADOR_GRAPH_STARTED:
            return {
                ...state,
                ambassadorGraphStarted: true
            };
        case GET_AMBASSADOR_GRAPH_SUCCESS:
            return {
                ...state,
                ambassadorGraph: action.payload,
                ambassadorGraphStarted: false
            };
        case GET_AMBASSADOR_GRAPH_FAILURE:
            return {
                ...state,
                ambassadorGraphErr: action.payload,
                ambassadorGraphStarted: false
            };
        case GET_AMBASSADOR_GRAPH_CORRECT_DATA:
            return {
                ...state,
                ambassadorGraphCorrectData: action.payload,
            };
        case GET_AMBASSADOR_LINKS_STARTED:
            return {
                ...state,
                ambassadorLinksStarted: true
            };
        case GET_AMBASSADOR_LINKS_SUCCESS:
            return {
                ...state,
                ambassadorLinks: action.payload,
                ambassadorLinksStarted: false
            };
        case GET_AMBASSADOR_LINKS_FAILURE:
            return {
                ...state,
                ambassadorLinksErr: action.payload,
                ambassadorLinksStarted: false
            };
        case SEND_AMBASSADOR_LINK_STARTED:
            return {
                ...state,
                sendAmbassadorLinkStarted: true
            };
        case SEND_AMBASSADOR_LINK_SUCCESS:
            return {
                ...state,
                sendAmbassadorLink: action.payload,
                sendAmbassadorLinkStarted: false
            };
        case SEND_AMBASSADOR_LINK_FAILURE:
            return {
                ...state,
                sendAmbassadorLinkErr: action.payload,
                sendAmbassadorLinkStarted: false
            };
        case GET_AMBASSADOR_TURNOVER_STARTED:
            return {
                ...state,
                ambassadorTurnoverStarted: true
            };
        case GET_AMBASSADOR_TURNOVER_SUCCESS:
            return {
                ...state,
                ambassadorTurnover: action.payload,
                ambassadorTurnoverStarted: false
            };
        case GET_AMBASSADOR_TURNOVER_FAILURE:
            return {
                ...state,
                ambassadorTurnoverErr: action.payload,
                ambassadorTurnoverStarted: false
            };
        case AMBASSADOR_LINK_ERRORS:
            let newErr = state.ambassadorLinksErrors;
            if (action.payload.index){
                newErr[action.payload.type] = {err: action.payload.err, index: action.payload.index}
            } else {
                newErr[action.payload.type] = action.payload.err
            }
            return {
                ...state,
                ambassadorLinksErrors: {...newErr}
            };
        case AMBASSADOR_LINK_ERRORS_CLEAR:
            let clearLink = state.ambassadorLinksErrors;
            delete clearLink[action.payload]
            return {
                ...state,
                ambassadorLinksErrors: {...clearLink}
            };
        case AMBASSADOR_CORRECT_LINKS:
            return {
                ...state,
                ambassadorCorrectLinks: action.payload
            };
        case SET_INFLUENCER_MODAL_STATUS:
            return {
                ...state,
                influencerModalStatus: action.payload
            };
        case GET_TICKETS_STARTED: // TODO
            return {
                ...state,
                ticketsStarted: true
            };
        case GET_TICKETS_SUCCESS:
            return {
                ...state,
                tickets: action.payload,
                ticketsStarted: false
            };
        case GET_TICKETS_FAILURE:
            return {
                ...state,
                ticketsErr: action.payload,
                ticketsStarted: false
            };
        case SET_TICKETS_MODAL_STATUS:
            return {
                ...state,
                ticketsModalStatus: action.payload
            };
        case BUY_TICKETS_STARTED:
            return {
                ...state,
                buyTicketStatusStarted: true
            };
        case BUY_TICKETS_SUCCESS:
            return {
                ...state,
                buyTicketStatus: action.payload,
                buyTicketStatusStarted: false
            };
        case BUY_TICKETS_FAILURE:
            return {
                ...state,
                buyTicketStatusErr: action.payload,
                buyTicketStatusStarted: false
            };
        case GET_CURRENT_WALLET_STARTED:
            return {
                ...state,
                currentWalletStarted: true
            };
        case GET_CURRENT_WALLET_SUCCESS:
            return {
                ...state,
                currentWallet: action.payload,
                currentWalletStarted: false
            };
        case GET_CURRENT_WALLET_FAILURE:
            return {
                ...state,
                currentWalletErr: action.payload,
                currentWalletStarted: false
            };
        case CREATE_CURRENT_WALLET_STARTED:
            return {
                ...state,
                createCurrentWalletStarted: true
            };
        case CREATE_CURRENT_WALLET_SUCCESS:
            return {
                ...state,
                createCurrentWallet: action.payload,
                createCurrentWalletStarted: false
            };
        case CREATE_CURRENT_WALLET_FAILURE:
            return {
                ...state,
                createCurrentWalletErr: action.payload,
                createCurrentWalletStarted: false
            };
        case GET_CURRENT_WALLET_TRANSACTIONS_STARTED:
            return {
                ...state,
                currentWalletTransactionsStarted: true
            };
        case GET_CURRENT_WALLET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                currentWalletTransactions: action.payload,
                currentWalletTransactionsStarted: false
            };
        case GET_CURRENT_WALLET_TRANSACTIONS_FAILURE:
            return {
                ...state,
                currentWalletTransactionsErr: action.payload,
                currentWalletTransactionsStarted: false
            };
        case GET_EVENTS_STARTED:
            return {
                ...state,
                eventsStarted: true
            };
        case GET_EVENTS_SUCCESS:
            return {
                ...state,
                events: action.payload,
                eventsStarted: false
            };
        case GET_EVENTS_FAILURE:
            return {
                ...state,
                eventsErr: action.payload,
                eventsStarted: false
            };
        case GET_CURRENT_EVENT_STARTED:
            return {
                ...state,
                currentEventStarted: true
            };
        case GET_CURRENT_EVENT_SUCCESS:
            return {
                ...state,
                currentEvent: action.payload,
                currentEventStarted: false
            };
        case GET_CURRENT_EVENT_FAILURE:
            return {
                ...state,
                currentEventErr: action.payload,
                currentEventStarted: false
            };
        case SET_TASK_STARTED:
            return {
                ...state,
                taskStatusStarted: true
            };
        case SET_TASK_SUCCESS:
            return {
                ...state,
                taskStatus: action.payload,
                taskStatusStarted: false
            };
        case SET_TASK_FAILURE:
            return {
                ...state,
                taskStatusErr: {...state.taskStatusErr, ...action.payload},
                taskStatusStarted: false
            };
        case SET_COMMISSION_ALERT_STATUS:
            return {
                ...state,
                commissionAlertStatus: action.payload
            };
        case GET_WALLET_GRAPH_HISTORY_STARTED:
            return {
                ...state,
                walletGraphHistoryStarted: true
            };
        case GET_WALLET_GRAPH_HISTORY_SUCCESS:
            return {
                ...state,
                walletGraphHistory: action.payload,
                walletGraphHistoryStarted: false
            };
        case GET_WALLET_GRAPH_HISTORY_FAILURE:
            return {
                ...state,
                walletGraphHistoryErr: action.payload,
                walletGraphHistoryStarted: false
            };
        case GET_TOTAL_BALANCE_STARTED:
            return {
                ...state,
                totalBalanceStarted: true
            };
        case GET_TOTAL_BALANCE_SUCCESS:
            return {
                ...state,
                totalBalance: action.payload,
                totalBalanceStarted: false
            };
        case GET_TOTAL_BALANCE_FAILURE:
            return {
                ...state,
                totalBalanceErr: action.payload,
                totalBalanceStarted: false
            };
        case SET_CREATE_WALLET_CURRENCIES:
            return {
                ...state,
                createWalletCurrencies: action.payload
            };
        case GET_CURRENCY_COMMISSION_STARTED:
            return {
                ...state,
                currencyCommissionStarted: true
            };
        case GET_CURRENCY_COMMISSION_SUCCESS:
            return {
                ...state,
                currencyCommission: action.payload,
                currencyCommissionStarted: false
            };
        case GET_CURRENCY_COMMISSION_FAILURE:
            return {
                ...state,
                currencyCommissionErr: action.payload,
                currencyCommissionStarted: false
            };
        case GET_AMBASSADOR_SALARY_DATA_STARTED:
            return {
                ...state,
                ambassadorsSalaryDataStarted: true
            };
        case GET_AMBASSADOR_SALARY_DATA_SUCCESS:
            return {
                ...state,
                ambassadorsSalaryData: action.payload,
                ambassadorsSalaryDataStarted: false
            };
        case GET_AMBASSADOR_SALARY_DATA_FAILURE:
            return {
                ...state,
                ambassadorsSalaryDataErr: action.payload,
                ambassadorsSalaryDataStarted: false
            };
        case GET_OMT_PERIODS_STARTED:
            return {
                ...state,
                omtPeriodsStarted: true
            };
        case GET_OMT_PERIODS_SUCCESS:
            return {
                ...state,
                omtPeriods: action.payload,
                omtPeriodsStarted: false
            };
        case GET_OMT_PERIODS_FAILURE:
            return {
                ...state,
                omtPeriodsErr: action.payload,
                omtPeriodsStarted: false
            };
        case GET_AVAILABLE_WALLETS_STARTED:
            return {
                ...state,
                availableWalletsStarted: true
            };
        case GET_AVAILABLE_WALLETS_SUCCESS:
            return {
                ...state,
                availableWallets: action.payload,
                availableWalletsStarted: false
            };
        case GET_AVAILABLE_WALLETS_FAILURE:
            return {
                ...state,
                availableWalletsErr: action.payload,
                availableWalletsStarted: false
            };
        case GET_BOT_SUBSCRIBE_INFO_STARTED:
            return {
                ...state,
                botSubscribeInfoStarted: true
            };
        case GET_BOT_SUBSCRIBE_INFO_SUCCESS:
            return {
                ...state,
                botSubscribeInfo: action.payload,
                botSubscribeInfoStarted: false
            };
        case GET_BOT_SUBSCRIBE_INFO_FAILURE:
            return {
                ...state,
                botSubscribeInfoErr: action.payload,
                botSubscribeInfoStarted: false
            };
        case GET_CUSTOM_SETTINGS_STARTED:
            return {
                ...state,
                customSettingsStarted: true
            };
        case GET_CUSTOM_SETTINGS_SUCCESS:
            return {
                ...state,
                customSettings: action.payload,
                customSettingsStarted: false
            };
        case GET_CUSTOM_SETTINGS_FAILURE:
            return {
                ...state,
                customSettingsErr: action.payload,
                customSettingsStarted: false
            };
        case SET_CUSTOM_SETTINGS_STARTED:
            return {
                ...state,
                setCustomSettingsStarted: true
            };
        case SET_CUSTOM_SETTINGS_SUCCESS:
            return {
                ...state,
                setCustomSettings: action.payload,
                setCustomSettingsStarted: false
            };
        case SET_CUSTOM_SETTINGS_FAILURE:
            return {
                ...state,
                setCustomSettingsErr: action.payload,
                setCustomSettingsStarted: false
            };
        case BUY_SUBSCRIBE_STARTED:
            return {
                ...state,
                buySubscribeStatusStarted: true
            };
        case BUY_SUBSCRIBE_SUCCESS:
            return {
                ...state,
                buySubscribeStatus: action.payload,
                buySubscribeStatusStarted: false
            };
        case BUY_SUBSCRIBE_FAILURE:
            return {
                ...state,
                buySubscribeStatusErr: action.payload,
                buySubscribeStatusStarted: false
            };
        case GET_STAKING_COMMISSION_STARTED:
            return {
                ...state,
                stakingCommissionStarted: true
            };
        case GET_STAKING_COMMISSION_SUCCESS:
            return {
                ...state,
                stakingCommission: action.payload,
                stakingCommissionStarted: false
            };
        case GET_STAKING_COMMISSION_FAILURE:
            return {
                ...state,
                stakingCommissionErr: action.payload,
                stakingCommissionStarted: false
            };
        case GET_STAKING_AWARD_STARTED:
            return {
                ...state,
                stakingAwardStarted: true
            };
        case GET_STAKING_AWARD_SUCCESS:
            return {
                ...state,
                stakingAward: action.payload,
                stakingAwardStarted: false
            };
        case GET_STAKING_AWARD_FAILURE:
            return {
                ...state,
                stakingAwardErr: action.payload,
                stakingAwardStarted: false
            };
        case GET_TRANSFER_COMMISSION_STARTED:
            return {
                ...state,
                transferCommissionStarted: true
            };
        case GET_TRANSFER_COMMISSION_SUCCESS:
            return {
                ...state,
                transferCommission: action.payload,
                transferCommissionStarted: false
            };
        case GET_TRANSFER_COMMISSION_FAILURE:
            return {
                ...state,
                transferCommissionErr: action.payload,
                transferCommissionStarted: false
            };
        case WITHDRAW_OMT_STAKING_STARTED:
            return {
                ...state,
                withdrawOmtStakingStarted: true
            };
        case WITHDRAW_OMT_STAKING_SUCCESS:
            return {
                ...state,
                withdrawOmtStaking: action.payload,
                withdrawOmtStakingStarted: false
            };
        case WITHDRAW_OMT_STAKING_FAILURE:
            return {
                ...state,
                withdrawOmtStakingErr: action.payload,
                withdrawOmtStakingStarted: false
            };
        case GET_INDICATORS_STARTED:
            return {
                ...state,
                walletIndicatorsStarted: true
            };
        case GET_INDICATORS_SUCCESS:
            return {
                ...state,
                walletIndicators: action.payload,
                walletIndicatorsStarted: false
            };
        case GET_INDICATORS_FAILURE:
            return {
                ...state,
                walletIndicatorsErr: action.payload,
                walletIndicatorsStarted: false
            };
        case GET_CRYPTO_NEWS_STARTED:
            return {
                ...state,
                cryptoNewsDataStarted: true
            };
        case GET_CRYPTO_NEWS_SUCCESS:
            return {
                ...state,
                cryptoNewsData: action.payload,
                cryptoNewsDataStarted: false
            };
        case GET_CRYPTO_NEWS_FAILURE:
            return {
                ...state,
                cryptoNewsDataErr: action.payload,
                cryptoNewsDataStarted: false
            };
        case GET_HONEECHIZE_MAIN_DATA_STARTED:
            return {
                ...state,
                honeechizeMainDataStarted: true
            };
        case GET_HONEECHIZE_MAIN_DATA_SUCCESS:
            return {
                ...state,
                honeechizeMainData: action.payload,
                honeechizeMainDataStarted: false
            };
        case GET_HONEECHIZE_MAIN_DATA_FAILURE:
            return {
                ...state,
                honeechizeMainDataErr: action.payload,
                honeechizeMainDataStarted: false
            };
        case GET_HONEECHIZE_EARNINGS_GRAPH_DATA_STARTED:
            return {
                ...state,
                honeechizeEarningsGraphDataStarted: true
            };
        case GET_HONEECHIZE_EARNINGS_GRAPH_DATA_SUCCESS:
            return {
                ...state,
                honeechizeEarningsGraphData: action.payload,
                honeechizeEarningsGraphDataStarted: false
            };
        case GET_HONEECHIZE_EARNINGS_GRAPH_DATA_FAILURE:
            return {
                ...state,
                honeechizeEarningsGraphDataErr: action.payload,
                honeechizeEarningsGraphDataStarted: false
            };
        case GET_HONEECHIZE_PROFIT_GRAPH_DATA_STARTED:
            return {
                ...state,
                honeechizeProfitGraphDataStarted: true
            };
        case GET_HONEECHIZE_PROFIT_GRAPH_DATA_SUCCESS:
            return {
                ...state,
                honeechizeProfitGraphData: action.payload,
                honeechizeProfitGraphDataStarted: false
            };
        case GET_HONEECHIZE_PROFIT_GRAPH_DATA_FAILURE:
            return {
                ...state,
                honeechizeProfitGraphDataErr: action.payload,
                honeechizeProfitGraphDataStarted: false
            };
        case GET_HONEECHIZE_AFFILIATE_SETTINGS_STARTED:
            return {
                ...state,
                honeechizeAffiliateSettingsStarted: true
            };
        case GET_HONEECHIZE_AFFILIATE_SETTINGS_SUCCESS:
            return {
                ...state,
                honeechizeAffiliateSettings: action.payload,
                honeechizeAffiliateSettingsStarted: false
            };
        case GET_HONEECHIZE_AFFILIATE_SETTINGS_FAILURE:
            return {
                ...state,
                honeechizeAffiliateSettingsErr: action.payload,
                honeechizeAffiliateSettingsStarted: false
            };
        case CHANGE_HONEECHIZE_AFFILIATE_PERCENT_STARTED:
            return {
                ...state,
                changeHoneechizeAffiliatePercentStarted: true
            };
        case CHANGE_HONEECHIZE_AFFILIATE_PERCENT_SUCCESS:
            return {
                ...state,
                changeHoneechizeAffiliatePercent: action.payload,
                changeHoneechizeAffiliatePercentStarted: false
            };
        case CHANGE_HONEECHIZE_AFFILIATE_PERCENT_FAILURE:
            return {
                ...state,
                changeHoneechizeAffiliatePercentErr: action.payload,
                changeHoneechizeAffiliatePercentStarted: false
            };
        case GET_HONEECHIZE_BOT_EARNINGS_STARTED:
            return {
                ...state,
                honeechizeBotEarningsDataStarted: true
            };
        case GET_HONEECHIZE_BOT_EARNINGS_SUCCESS:
            return {
                ...state,
                honeechizeBotEarningsData: action.payload,
                honeechizeBotEarningsDataStarted: false
            };
        case GET_HONEECHIZE_BOT_EARNINGS_FAILURE:
            return {
                ...state,
                honeechizeBotEarningsDataErr: action.payload,
                honeechizeBotEarningsDataStarted: false
            };
        case GET_HONEECHIZE_BOT_SETTINGS_STARTED:
            return {
                ...state,
                honeechizeBotSettingsDataStarted: true
            };
        case GET_HONEECHIZE_BOT_SETTINGS_SUCCESS:
            return {
                ...state,
                honeechizeBotSettingsData: action.payload,
                honeechizeBotSettingsDataStarted: false
            };
        case GET_HONEECHIZE_BOT_SETTINGS_FAILURE:
            return {
                ...state,
                honeechizeBotSettingsDataErr: action.payload,
                honeechizeBotSettingsDataStarted: false
            };
        case SET_HONEECHIZE_BOT_SETTINGS_STARTED:
            return {
                ...state,
                setHoneechizeBotSettingsDataStarted: true
            };
        case SET_HONEECHIZE_BOT_SETTINGS_SUCCESS:
            return {
                ...state,
                setHoneechizeBotSettingsData: action.payload,
                setHoneechizeBotSettingsDataStarted: false
            };
        case SET_HONEECHIZE_BOT_SETTINGS_FAILURE:
            return {
                ...state,
                setHoneechizeBotSettingsDataErr: action.payload,
                setHoneechizeBotSettingsDataStarted: false
            };
        case GET_HONEECHIZE_AFFILIATE_STATISTIC_STARTED:
            return {
                ...state,
                honeechizeAffiliateStatisticStarted: true
            };
        case GET_HONEECHIZE_AFFILIATE_STATISTIC_SUCCESS:
            return {
                ...state,
                honeechizeAffiliateStatistic: action.payload,
                honeechizeAffiliateStatisticStarted: false
            };
        case GET_HONEECHIZE_AFFILIATE_STATISTIC_FAILURE:
            return {
                ...state,
                honeechizeAffiliateStatisticErr: action.payload,
                honeechizeAffiliateStatisticStarted: false
            };
        case GET_COMMUNITY_BOOST_MAIN_DATA_STARTED:
            return {
                ...state,
                communityBoostMainDataStarted: true
            };
        case GET_COMMUNITY_BOOST_MAIN_DATA_SUCCESS:
            return {
                ...state,
                communityBoostMainData: action.payload,
                communityBoostMainDataStarted: false
            };
        case GET_COMMUNITY_BOOST_MAIN_DATA_FAILURE:
            return {
                ...state,
                communityBoostMainDataErr: action.payload,
                communityBoostMainDataStarted: false
            };
        case SEND_COMMUNITY_BOOST_POST_STARTED:
            return {
                ...state,
                sendCbPostStarted: true
            };
        case SEND_COMMUNITY_BOOST_POST_SUCCESS:
            return {
                ...state,
                sendCbPost: action.payload,
                sendCbPostStarted: false
            };
        case SEND_COMMUNITY_BOOST_POST_FAILURE:
            return {
                ...state,
                sendCbPostErr: action.payload,
                sendCbPostStarted: false
            };
        case GET_COMMUNITY_BOOST_LEADERBOARD_DATA_STARTED:
            return {
                ...state,
                communityBoostLeaderboardDataStarted: true
            };
        case GET_COMMUNITY_BOOST_LEADERBOARD_DATA_SUCCESS:
            return {
                ...state,
                communityBoostLeaderboardData: action.payload,
                communityBoostLeaderboardDataStarted: false
            };
        case GET_COMMUNITY_BOOST_LEADERBOARD_DATA_FAILURE:
            return {
                ...state,
                communityBoostLeaderboardDataErr: action.payload,
                communityBoostLeaderboardDataStarted: false
            };
        case GET_INDEX_MAIN_DATA_STARTED:
            return {
                ...state,
                indexMainDataStarted: true
            };
        case GET_INDEX_MAIN_DATA_SUCCESS:
            return {
                ...state,
                indexMainData: action.payload,
                indexMainDataStarted: false
            };
        case GET_INDEX_MAIN_DATA_FAILURE:
            return {
                ...state,
                indexMainDataErr: action.payload,
                indexMainDataStarted: false
            };
        case INDEX_SWAP_STARTED:
            return {
                ...state,
                indexSwapStatusStarted: true
            };
        case INDEX_SWAP_SUCCESS:
            return {
                ...state,
                indexSwapStatus: action.payload,
                indexSwapStatusStarted: false
            };
        case INDEX_SWAP_FAILURE:
            return {
                ...state,
                indexSwapStatusErr: action.payload,
                indexSwapStatusStarted: false
            };
        case GET_INDEX_SWAP_HISTORY_STARTED:
            return {
                ...state,
                indexSwapHistoryStarted: true
            };
        case GET_INDEX_SWAP_HISTORY_SUCCESS:
            return {
                ...state,
                indexSwapHistory: action.payload,
                indexSwapHistoryStarted: false
            };
        case GET_INDEX_SWAP_HISTORY_FAILURE:
            return {
                ...state,
                indexSwapHistoryErr: action.payload,
                indexSwapHistoryStarted: false
            };
        case GET_INDEX_SWAP_PREDICTION_STARTED:
            return {
                ...state,
                indexSwapPredictionStarted: true
            };
        case GET_INDEX_SWAP_PREDICTION_SUCCESS:
            return {
                ...state,
                indexSwapPrediction: action.payload,
                indexSwapPredictionStarted: false
            };
        case GET_INDEX_SWAP_PREDICTION_FAILURE:
            return {
                ...state,
                indexSwapPredictionErr: action.payload,
                indexSwapPredictionStarted: false
            };
        default:
            return state;
    }
}

