import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {setBonusesModalStatus} from "../../store/wallet/action";
import {useTranslation} from "react-i18next";



const BonusesMain = () => {
    const dispatch = useDispatch();
    const refMainData = useSelector(store => store.wallet.refMainData);
    const { t } = useTranslation();

    const [refCodeCopyStatus, setRefCodeCopyStatus] = useState(false);
    const [refLinkCopyStatus, setRefLinkCopyStatus] = useState(false);

    const percentBarHandler = (type) =>{
        let currentPercent;
        if (type){
            currentPercent = ((refMainData?.turnover?.personal) / refMainData?.next_referral_level?.personal_turnover) * 100;
        } else {
            currentPercent = ((refMainData?.turnover?.referral) / refMainData?.next_referral_level?.referral_turnover) * 100;
        }
        if (currentPercent > 100){
            return 100
        } else if (currentPercent > 5){
            return currentPercent
        } else if (currentPercent < 5 && currentPercent > 0){
            return 5
        } else {
            return 0
        }
    }

    useEffect(()=>{
        setRefLinkCopyStatus(false);
        setRefCodeCopyStatus(false);
    },[])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.mainWrap}`}>
                        <div className={`${Styles.title2}`}>{t('Уровень')}: {refMainData?.referral_level?.name[refMainData?.referral_level?.name?.length - 1]}</div>
                        <div className={`${Styles.percent}`}>
                            {`% ${t('вознаграждения')}: `}
                            <span>
                            {refMainData?.referral_level?.reward_percentage?.map((item, index)=>{
                                if (refMainData?.referral_level?.reward_percentage.length - 1 === index){
                                    return (`${item}%`)
                                } else {
                                    return (`${item} - `)
                                }
                            })}
                        </span>
                        </div>
                        <div className={`${Styles.barWrap}`}>
                            <div className={`${Styles.barBox}`}>
                                <div className={`${Styles.barTitle}`}>{t('Личный объем торгов:')}</div>
                                <div className={`${Styles.bar}`}>
                                    <div className={`${Styles.barValues}`}>${refMainData?.turnover?.personal} / ${refMainData?.next_referral_level?.personal_turnover}</div>
                                    <div
                                        className={`${Styles.barBg}`}
                                        style={{width: `${percentBarHandler(true)}%`}}
                                    />
                                </div>
                            </div>
                            <div className={`${Styles.barBox}`}>
                                <div className={`${Styles.barTitle}`}>{t('Объем торгов структуры:')}</div>
                                <div className={`${Styles.bar}`}>
                                    <div className={`${Styles.barValues}`}>${refMainData?.turnover?.referral} / ${refMainData?.next_referral_level?.referral_turnover}</div>
                                    <div
                                        className={`${Styles.barBg}`}
                                        style={{width: `${percentBarHandler(false)}%`}}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.barBoxDetailsBtn}`} onClick={()=>{dispatch(setBonusesModalStatus(true))}}>{t('Подробнее об уровнях заработка')}</div>
                    </div>
                    <div className={`${Styles.profitWrap}`}>
                        <div className={`${Styles.title2}`}>{t('Заработано всего:')}</div>
                        <div className={`${Styles.profitAll}`}>
                            {refMainData?.all_profit?.bot && refMainData?.all_profit?.staking ? Number(refMainData?.all_profit?.bot) + Number(refMainData?.all_profit?.staking) : null} USDT
                        </div>
                        <div className={`${Styles.profitBox}`}>
                            <div className={`${Styles.profitBoxItem}`}>
                                <div className={`${Styles.profitBoxItemTitle}`}>{t('Стейкинг')}</div>
                                <div className={`${Styles.profitBoxItemValue}`}>{refMainData?.all_profit?.staking ? Number(refMainData?.all_profit?.staking) : null} USDT</div>
                            </div>
                            <div className={`${Styles.profitBoxItem}`}>
                                <div className={`${Styles.profitBoxItemTitle}`}>{t('Бот')}</div>
                                <div className={`${Styles.profitBoxItemValue}`}>{refMainData?.all_profit?.bot ? Number(refMainData?.all_profit?.bot) : null} HONEE</div>
                            </div>
                            <div className={`${Styles.profitBoxItem}`}>
                                <div className={`${Styles.profitBoxItemTitle}`}>{t('Приглашения')}</div>
                                <div className={`${Styles.profitBoxItemValue}`}>{refMainData?.all_profit?.ref ? Number(refMainData?.all_profit?.ref) : null} OMT</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.refWrap}`}>
                        <div className={`${Styles.title2}`}>{t('Приглашай друзей:')}</div>
                        <div className={`${Styles.refBox}`}>
                            <div className={`${Styles.refBoxItem}`}>
                                <div className={`${Styles.fakeInputWrap}`}>
                                    <div className={`${Styles.fakeInputTitle}`}>{t('Реферальный код')}</div>
                                    <div className={`${Styles.fakeInput}`}>{refMainData?.referral_code}</div>
                                </div>
                                <div className={`${Styles.copyBtn} ${refCodeCopyStatus ? Styles.copyBtnActive : null}`} onClick={()=>{
                                    navigator.clipboard.writeText(refMainData?.referral_code);
                                    setRefCodeCopyStatus(true);
                                    setRefLinkCopyStatus(false);
                                }}>{refCodeCopyStatus ? t('Скопировано') : t('Скопировать')}</div>
                            </div>
                            <div className={`${Styles.refBoxItem}`}>
                                <div className={`${Styles.fakeInputWrap}`}>
                                    <div className={`${Styles.fakeInputTitle}`}>{t('Реферальная ссылка')}</div>
                                    <div className={`${Styles.fakeInput}`}><span>{`${window.location.origin}/sign-up/ref/${refMainData?.referral_code}`}</span></div>
                                </div>
                                <div className={`${Styles.copyBtn} ${refLinkCopyStatus ? Styles.copyBtnActive : null}`} onClick={()=>{
                                    navigator.clipboard.writeText(`${window.location.origin}/sign-up/ref/${refMainData?.referral_code}`);
                                    setRefLinkCopyStatus(true);
                                    setRefCodeCopyStatus(false);
                                }}>{refLinkCopyStatus ? t('Скопировано') : t('Скопировать')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BonusesMain;
