import React from "react";
import Styles from './styles.module.scss';
import {useSelector} from "react-redux";
import EmptyValues from "../_UI/emtyValues";

import arrow from '../../theme/assets/svg/omt_bot_profit_arrow.svg';
import {useTranslation} from "react-i18next";



const BotProfit = () => {
    const botStatsCorrectData = useSelector(store => store.wallet.botStatsCorrectData);
    const { t } = useTranslation();

    const dayHandler = (day) =>{
        switch (day){
            case 'mon':
                return t('пн')
            case 'tue':
                return t('вт')
            case 'wed':
                return t('ср')
            case 'thu':
                return t('чт')
            case 'fri':
                return t('пт')
            case 'sat':
                return t('сб')
            case 'sun':
                return t('вс')
            default:
                return 'day'
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.title}`}>{t('Доходность за неделю')}</div>
                    <div className={`${Styles.contentBox}`} style={{alignContent: `${botStatsCorrectData ? 'end' : 'center'}`}}>
                        {
                            botStatsCorrectData ? botStatsCorrectData.data.map((item, index)=>{
                                return (
                                    <div className={`${Styles.contentBoxItem}`} key={'botProfit' + index}>
                                        <div className={`${Styles.contentBoxItemBar}`} style={{height: `${(botStatsCorrectData.max === 0 ? '7' : (100 / botStatsCorrectData.max) * item.value)}%`}}>
                                            <div className={`${Styles.contentBoxItemBarValue}`}>
                                                {item.value.toFixed(2) * 1}$
                                                <img src={arrow} alt={item.value}/>
                                            </div>
                                        </div>
                                        <div className={`${Styles.contentBoxItemDay}`}>{dayHandler(item.day.toLowerCase())}</div>
                                    </div>
                                )
                            } ) : <EmptyValues text={t('Запустите бота для просмотра статистики')} withIcon={false} withBtn={false}/>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BotProfit;
