import React from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";



const StakingMain = () => {
    const stakingAvailable = useSelector(store => store.wallet.availableStaking);
    const { t } = useTranslation();
    
    // const [currency, setCurrency] = useState('USDT')
    
    // useEffect(()=>{
    //     if (stakingAvailable?.open?.[0]?.currency?.short_name){
    //         let mainCur = stakingAvailable.open[0].currency.short_name;
    //         let isUSDT = false;
    //         if (stakingAvailable.open.length > 0){
    //             stakingAvailable.open.forEach((item)=>{
    //                 if (item.currency.short_name !== mainCur){
    //                     isUSDT = false
    //                 }
    //             })
    //         }
    //
    //         setCurrency(isUSDT ? 'USDT' : mainCur)
    //     }
    //
    // },[stakingAvailable])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.contentWrapItem}`}>
                        <div className={`${Styles.title2}`}>{t('Стейкинг')}</div>
                        <div className={`${Styles.contentWrapItemValue}`}>{stakingAvailable?.balance ? Number(stakingAvailable?.balance).toFixed(2) * 1 : '0'} {stakingAvailable?.currency ? stakingAvailable?.currency : ''}</div>
                        <div className={`${Styles.contentWrapItemTextWrap}`}>
                            <div className={`${Styles.contentWrapItemTextWrapRow}`}>{t('Ежедневный доход')}: {stakingAvailable?.daily_profit ? Number(stakingAvailable?.daily_profit).toFixed(4) * 1 : '0'} {stakingAvailable?.currency ? stakingAvailable?.currency : ''}</div>
                            <div className={`${Styles.contentWrapItemTextWrapRow}`}>{t('Кумулятивный доход')}: {stakingAvailable?.total_profit ? Number(stakingAvailable?.total_profit).toFixed(4) * 1 : '0'} {stakingAvailable?.currency ? stakingAvailable?.currency : ''}</div>
                        </div>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <div className={`${Styles.title2}`}>{t('Доступны для вывода')}</div>
                        <div className={`${Styles.contentWrapItemValue}`}>{stakingAvailable?.withdrawal_balance ? Number(stakingAvailable?.withdrawal_balance).toFixed(2) * 1 : '0'} {stakingAvailable?.currency ? stakingAvailable?.currency : ''}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StakingMain;
