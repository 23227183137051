import React from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";


const WalletBot = () => {
    const botPreviewData = useSelector(store => store.wallet.botPreviewData);

    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.title3}`}>{t('Бот')}</div>
                    <div className={`${Styles.contentBox}`}>
                        <div className={`${Styles.row}`}>
                            <div className={`${Styles.rowTitleWrap}`}>
                                <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Общий баланс')}</div>
                                <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Доходность за неделю')}</div>
                            </div>
                            <div className={`${Styles.rowContentWrap}`}>
                                <div className={`${Styles.rowContentItem}`}>
                                    {botPreviewData?.bot?.amount && botPreviewData?.honee_wallet?.amount ? (Number(botPreviewData?.bot?.amount) + Number(botPreviewData?.honee_wallet?.amount)).toFixed(2) * 1 : '0'} Honee
                                </div>
                                <div className={`${Styles.rowContentItem}`}>{botPreviewData?.weekly_profit ? botPreviewData?.weekly_profit * 1 : '0'} Honee</div>
                            </div>
                        </div>
                        <div className={`${Styles.title3}`}>{t('В работе')}</div>
                        <div className={`${Styles.row}`}>
                            <div className={`${Styles.rowTitleWrap}`}>
                                <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Общий баланс')}</div>
                                <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Доступно для вывода')}</div>
                            </div>
                            <div className={`${Styles.rowContentWrap}`}>
                                <div className={`${Styles.rowContentItem}`}>{botPreviewData?.bot?.amount ? botPreviewData?.bot?.amount * 1 : '0'} Honee</div>
                                <div className={`${Styles.rowContentItem}`}>{botPreviewData?.honee_wallet?.amount ? botPreviewData?.honee_wallet?.amount * 1 : '0'} Honee</div>
                            </div>
                        </div>
                        <div className={`${Styles.btn}`} onClick={()=>{navigate('/honeeAi')}}>{t('Перейти к боту')}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WalletBot;
