import React from "react";
import Styles from './styles.module.scss'
import {scroller} from "react-scroll";
import {useTranslation} from "react-i18next";


const MainPreview = () => {
    const { t } = useTranslation();
    const scrollHandler = (element) =>{
        scroller.scrollTo(element, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title1}`}>{t('OMT - ĸонтролируй свои аĸтивы, ĸонтролируй свою жизнь')}.</div>
                <div className={`${Styles.note}`}>
                    {t('Стейĸинг, поĸупĸа, обмен Bitcoin, Ethereum и тысячи других аĸтивов')}.
                </div>
                <div className={`${Styles.btn}`} onClick={()=>{scrollHandler('about')}}>{t('Подробнее')}</div>
            </div>
        </section>
    );
};

export default MainPreview;
