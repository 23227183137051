import React, {useState} from "react";
import Styles from './styles.module.scss'

import backIcon from '../../theme/assets/svg/omt_btn_back_arrow.svg';
import checkIcon from '../../theme/assets/svg/omt_check_icon.svg';
import {getPhrase, setConfirmationStep} from "../../store/main/action";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";


const ConfirmationPhrase = () => {
    const dispatch = useDispatch();
    const phrase = useSelector(store => store.main.getPhrase);
    const { t } = useTranslation();

    const [checkStatus, setCheckStatus] = useState(false);
    const [copyStatus, setCopyStatus] = useState(false);

    const backBtnHandler = ()=>{
        dispatch(setConfirmationStep('1'));
        localStorage.setItem('omt-step', '1');
    }

    const dataBtnHandler = () =>{
        if (phrase){
            navigator.clipboard.writeText(phrase);
            setCopyStatus(true);
        } else {
            if (checkStatus){
                dispatch(getPhrase())
            }
        }
    }

    const nextBtnHandler = () =>{
        dispatch(setConfirmationStep('3'));
        // localStorage.setItem('omt-step', '3');
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title}`}>
                    {t('Секретная фраза вашего аккаунта')}
                    <div className={`${Styles.absBackBtn}`} onClick={()=>{backBtnHandler()}}>
                        <img src={backIcon} alt="back arrow"/>
                        <span>{t('Назад')}</span>
                    </div>
                </div>
                <div className={`${Styles.note}`}>
                    {t('Сеĸретная фраза необходима для резервного ĸопирования и восстановления аĸĸаунта')}
                </div>
                <div className={`${Styles.listTitle}`}>{t('ПРЕДУПРЕЖДЕНИЕ')}:</div>
                <div className={`${Styles.listWrap}`}>
                    <div className={`${Styles.listItem}`}>{t('Ниĸогда не передавайте свою сеĸретную фразу восстановления 3-м лицам')};</div>
                    <div className={`${Styles.listItem}`}>{t('Любой, ĸто знает эту фразу, может присвоить ваши средства и аĸĸаунт')};</div>
                    <div className={`${Styles.listItem}`}>
                        {t('Если вы потеряете сеĸретную фразу - вы потеряете доступ ĸ аĸĸаунту навсегда, поэтому - запомните сеĸретную фразу и запишите ее на несĸольĸих устройствах или бумаге. Таĸже, вы можете сĸачать фразу для восстановления и хранить ее в безопасном месте, на внешнем зашифрованном жестĸом дисĸе или носителе.')}
                    </div>
                </div>
                <div className={`${Styles.checkRow}`}>
                    <div className={`${Styles.checkBox}`} onClick={()=>{setCheckStatus(!checkStatus)}}>
                        {checkStatus ? <img src={checkIcon} alt="check accept"/> : null}
                    </div>
                    <div className={`${Styles.checkRowContent}`}>{t('Я подтверждаю, что прочитал и понял/а все вышеперечисленные предупреждения')} </div>
                </div>
                <div className={`${Styles.note}`}>
                    {t('После того, как вы нажмете на кнопку')} <span>“{t('Сгенерировать')}”</span> {t('вы увидите свою секретную фразу, пожалуйста, запомните и скопируйте ее на свое устройство, в следующем окне вам нужно будет повторить ее')}:
                </div>
                <div className={`${Styles.phrase}`}>
                    {phrase ? phrase : null}
                </div>
                <div className={`${Styles.btnWrap}`}>
                    <div className={`${Styles.btn} ${!checkStatus ? Styles.disable : null} ${copyStatus ? Styles.copy : null}`} onClick={()=>{dataBtnHandler()}}>
                        {phrase ? copyStatus ? t('Скопировано!') : t('Скопировать') : t('Сгенерировать')}
                    </div>
                    <div className={`${Styles.btn} ${!copyStatus ? Styles.disable : null}`} onClick={()=>{nextBtnHandler()}}>{t('Далее')}</div>
                </div>
            </div>
        </section>
    );
};

export default ConfirmationPhrase;
