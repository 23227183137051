import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import HoneechizeNavigation from "../../components/honeechizeNavigation";
import HoneechizeMain from "../../components/honeechizeMain";
import {
    getHoneechizeAffiliateSettings,
    getHoneechizeAffiliateStatistic,
    getHoneechizeMainData
} from "../../store/wallet/action";
import HoneechizeAffiliateSettings from "../../components/honeechizeAffiliateSettings";
import HoneechizeAffiliateSettingsModal from "../../components/honeechizeAffiliateSettingsModal";
import HoneechizeAffiliateStatistic from "../../components/honeechizeAffiliateStatistic";
import HoneechizeAffiliateTableModal from "../../components/honeechizeAffiliateTableModal";



const HoneechizeAffiliatePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authStatus = useSelector(store => store.main.authorizationStatus);


    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getHoneechizeMainData());
        dispatch(getHoneechizeAffiliateSettings());
        dispatch(getHoneechizeAffiliateStatistic('week'));
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'Affiliate program settings'}/>
            <div className={`${Styles.contentWrap}`}>
                <HoneechizeNavigation/>
                <HoneechizeMain/>
                <HoneechizeAffiliateSettings/>
                <HoneechizeAffiliateStatistic/>
                <HoneechizeAffiliateSettingsModal/>
                <HoneechizeAffiliateTableModal/>
            </div>
        </section>
    );
};

export default HoneechizeAffiliatePage;