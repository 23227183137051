import React, {useCallback, useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {login, loginFailure} from "../../store/main/action";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";


const LogPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formErr = useSelector(store => store.main.loginErr);
    const authStatus = useSelector(store => store.main.authorizationStatus);
    const newDeviceStatus = useSelector(store => store.main.newDeviceStatus);

    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const sendFormHandler = useCallback(()=>{
        dispatch(login({email, password}));
    }, [email, password, dispatch])


    useEffect(()=>{
        if (authStatus.authorization){
            if (authStatus.phrase){
                navigate('/home');
            } else {
                navigate('/confirmation');
            }
        }
    },[authStatus, navigate])

    useEffect(()=>{
        if (newDeviceStatus){
            navigate(`/device-confirmation/${newDeviceStatus}`)
        }
    },[newDeviceStatus, navigate])

    useEffect(() => {
        const onKeyDown = e => {
            if(e.keyCode === 13) {
                sendFormHandler();
            }
        };
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [sendFormHandler]);

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Вход')}/>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title}`}>{t('Войдите в свой аккаунт')}</div>
                <div className={`${Styles.inputsBox}`}>
                    <div className={`${Styles.inputWrap} ${formErr?.email ? Styles.inputWrapWithErr : null}`}>
                        <div className={`${Styles.inputTitle}`}>{t('Логин (ваш email)')}</div>
                        <input
                            type="email"
                            placeholder='example@gmail.com'
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value)}}
                            onKeyDown={(event)=>{
                                if (formErr.email){
                                    delete formErr.email;
                                    dispatch(loginFailure(formErr));
                                }
                            }}
                        />
                        <div className={`${Styles.inputErr}`}>{formErr?.email ? formErr.email : null}</div>
                    </div>
                    <div className={`${Styles.inputWrap} ${formErr?.password ? Styles.inputWrapWithErr : null}`}>
                        <div className={`${Styles.inputTitle}`}>{t('Пароль')}</div>
                        <input
                            type="password"
                            placeholder='********'
                            value={password}
                            onChange={(e)=>{setPassword(e.target.value)}}
                            onKeyDown={(event)=>{
                                if (formErr.password){
                                    delete formErr.password;
                                    dispatch(loginFailure(formErr));
                                }
                            }}
                        />
                        <div className={`${Styles.inputErr}`}>{formErr?.password?.[0] ? formErr.password[0] : null}</div>
                    </div>
                </div>
                <div className={`${Styles.links}`}>{t('Забыли пароль')}? <span onClick={()=>{navigate('/reset-password')}}>{t('Сброс пароля')}</span></div>
                <div className={`${Styles.links}`}>{t('Еще нет аккаунта?')} <span onClick={()=>{navigate('/sign-up')}}>{t('Зарегестрируйтесь')}</span></div>
                <div className={`${Styles.mainBtn}`} onClick={()=>{sendFormHandler()}}>{t('Войти')}</div>
            </div>
        </section>
    );
};

export default LogPage;
