import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import {getCurrentWallet, getCurrentWalletTransactions, getWalletAssets} from "../../store/wallet/action";
import StandardCurrentWalletMain from "../../components/standardCurrentWalletMain";
import StandardCurrentWalletTransactions from "../../components/standardCurrentWalletTransactions";
import WalletTake from "../../components/walletTake";
import WalletSend from "../../components/walletSend";


const StandardCurrentWalletPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id} = useParams();
    const authStatus = useSelector(store => store.main.authorizationStatus);


    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getWalletAssets());
        dispatch(getCurrentWallet(id));
        dispatch(getCurrentWalletTransactions(id));
    },[dispatch, id])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'Wallet'}/>
            <div className={`${Styles.contentWrap}`}>
                <StandardCurrentWalletMain/>
                <StandardCurrentWalletTransactions/>
                <WalletTake/>
                <WalletSend/>
            </div>
        </section>
    );
};

export default StandardCurrentWalletPage;