import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";



const StandardWalletToken = () => {
    const { t } = useTranslation();

    const assets = useSelector(store => store.wallet.walletAssets);
    const omtPeriods = useSelector(store => store.wallet.omtPeriods);


    const [totalOmt, setTotalOmt] = useState(0);

    useEffect(()=>{
        if (assets){
            assets.forEach((item)=>{
                if (item.name === 'OMT'){
                    setTotalOmt(item.amount * 1)
                }
            })
        }
    },[assets])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.title3}`}>{t('Доступные токены')}</div>
                    <div className={`${Styles.totalRow}`}>
                        <div className={`${Styles.totalRowItem}`}>
                            <div className={`${Styles.title4}`}>{t('Всего токенов')}:</div>
                            <div className={`${Styles.totalRowItemValue} ${Styles.colorOrange}`}>{omtPeriods?.total || omtPeriods?.total === 0 ? `${omtPeriods?.total} OMT` : '--'} </div>
                        </div>
                        <div className={`${Styles.totalRowItem}`}>
                            <div className={`${Styles.title4}`}>{t('Доступно токенов')}:</div>
                            <div className={`${Styles.totalRowItemValue} ${Styles.colorGreen}`}>{omtPeriods?.available || omtPeriods?.available === 0 ? `${Number(omtPeriods?.available).toFixed(2)} OMT` : '--'}</div>
                        </div>
                        <div className={`${Styles.totalRowItem}`}>
                            <div className={`${Styles.title4}`}>{t('Следующий этап')}:</div>
                            <div className={`${Styles.totalRowItemValue} ${Styles.colorGray}`}>{omtPeriods?.date ? `${omtPeriods?.date}` : '--'}</div>
                        </div>
                    </div>
                    <div className={`${Styles.inputRow}`}>
                        <div className={`${Styles.inputWrap}`}>
                            <div className={`${Styles.inputTitle}`}>{t('Сумма')}</div>
                            <input type="text" placeholder={`${totalOmt || totalOmt === 0 ? totalOmt : '0'} OMT`} readOnly/>
                        </div>
                        <div className={`${Styles.button} ${Styles.buttonDisabled}`}>{t('Вывести')}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StandardWalletToken;
