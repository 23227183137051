import React from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";
import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import Moment from "react-moment";

import exchangeIcon from '../../theme/assets/svg/omt_wallet_history_icon4.svg';

const ExchangeHistory = () => {
    const exchangeHistory = useSelector(store => store.wallet.exchangeHistory);
    const { t } = useTranslation();


    const statusContentHandler = (status) =>{
        switch (status){
            case 'confirmed':
                return t('Подтвержден');
            case 'unconfirmed':
                return t('Не подтвержден');
            case 'canceled':
                return t('Отменен');
            case 'created':
                return t('Создан');
            case 'out_of_energy':
                return t('Out_of_energy');
            default:
                return t('Неизвестно')
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBodyWithScroll}`}>
                    <div className={`${Styles.title3}`}>{t('История обмена')}</div>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Тип операции')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.titleRowItemSM}`}>{t('Дата и время')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.titleRowItemD}`}>{t('Пара')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.titleRowItemD}`}>{t('Курс')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Статус')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Количество')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        {exchangeHistory?.data?.length > 0 ? exchangeHistory?.data?.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem}`} key={'exchangeHistory' + index}>
                                    <div className={`${Styles.contentBoxItemNameWrap}`}>
                                        <div className={`${Styles.contentBoxItemNameImgWrap}`}>
                                            <img src={exchangeIcon} alt="icon"/>
                                        </div>
                                        <div className={`${Styles.contentBoxItemNameBox}`}>
                                            {t('Обмен')}
                                        </div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemDateRow}`}><Moment format="DD/MM/YYYY HH:mm">{item.date}</Moment></div>
                                    <div className={`${Styles.contentBoxItemPairRow}`}>{item.currency_from.short_name} - {item.currency_to.short_name}</div>
                                    <div className={`${Styles.contentBoxItemKursRow}`}>1 {item.currency_from.short_name} = {Number(item.currency_from.rate)} {item.currency_to.short_name}</div>
                                    <div className={`${Styles.contentBoxItemStatusRow}`}>{statusContentHandler(item.status)}</div>
                                    <div className={`${Styles.contentBoxItemAmountRow}`}>{item.amount_to * 1} {item.currency_to.short_name}</div>
                                </div>
                            )
                        }) : <EmptyValues text={t('Вы еще не совершали обменов')} withIcon={true} withBtn={false}/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ExchangeHistory;
