import React, {useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {setWalletTakeFormStatus} from "../../store/wallet/action";
import WalletTakeStep2 from "../_UI/walletTakeStep2";
import {useParams} from "react-router-dom";


const WalletTake = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const takeFormStatus = useSelector(store => store.wallet.takeFormStatus);
    // const takeWalletNetwork = useSelector(store => store.wallet.refillableWallets);
    const currentWallet = useSelector(store => store.wallet.currentWallet);
    // const refillableWallets = useSelector(store => store.wallet.refillableWallets);

    // const [step, setStep] = useState(1);
    const [currentToken, setCurrentToken] = useState(takeFormStatus?.data ? takeFormStatus.data : null);

    const closeModal = () =>{
        dispatch(setWalletTakeFormStatus({status: false, data: false}));
    }

    // const contentTypeHandler = (type) =>{
    //     setStep(type);
    // }
    
    useEffect(()=>{
        if (takeFormStatus?.data && !id){
            setCurrentToken(takeFormStatus.data)
        }
    }, [takeFormStatus, id])

    useEffect(()=>{
        if (id){
            setCurrentToken(currentWallet);
        }
    },[id, currentWallet])

    return (
        <Popup
            open={takeFormStatus?.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <WalletTakeStep2
                        currentToken={currentToken}
                        // contentTypeHandler={contentTypeHandler}
                        // takeWalletNetwork={takeWalletNetwork}
                        // setCurrentToken={setCurrentToken}
                    />
                </div>
            </div>
        </Popup>
    );
};

export default WalletTake;
