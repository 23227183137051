import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import WalletMain from "../../components/walletMain";
import WalletAssets from "../../components/walletAssets";
import WalletHistory from "../../components/walletHistory";
import WalletStaking from "../../components/walletStaking";
import WalletBot from "../../components/walletBot";
import WalletSlider from "../../components/walletSlider";
import WalletCreate from "../../components/walletCreate";
import WalletTake from "../../components/walletTake";
import WalletSend from "../../components/walletSend";
import {
    getAvailableWallets,
    getBanners,
    getBotPreviewData,
    getUserStaking,
    getWalletAssets,
    getWalletTransactions
} from "../../store/wallet/action";
import Seo from "../../components/_UI/seo";


const WalletPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authStatus = useSelector(store => store.main.authorizationStatus);

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getAvailableWallets());
        dispatch(getWalletAssets());
        dispatch(getWalletTransactions());
        dispatch(getUserStaking());
        dispatch(getBotPreviewData());
        dispatch(getBanners());
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'Home'}/>
            <div className={`${Styles.contentWrap}`}>
                <WalletMain/>
                <WalletAssets/>
                <WalletHistory/>
                <WalletStaking/>
                <WalletBot/>
                <WalletSlider/>
                <WalletCreate/>
                <WalletTake/>
                <WalletSend/>
            </div>
        </section>
    );
};

export default WalletPage;