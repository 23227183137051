export const TEST = 'TEST';

export const SET_WALLET_CREATE_FORM_STATUS = 'SET_WALLET_CREATE_FORM_STATUS';
export const SET_WALLET_TAKE_FORM_STATUS = 'SET_WALLET_TAKE_FORM_STATUS';
export const SET_WALLET_SEND_FORM_STATUS = 'SET_WALLET_SEND_FORM_STATUS';
export const SET_STAKING_FORM_STATUS = 'SET_STAKING_FORM_STATUS';
export const SET_STAKING_FORM_OMT_STATUS = 'SET_STAKING_FORM_OMT_STATUS';
export const SET_STAKING_WITHDRAW_FORM_STATUS = 'SET_STAKING_WITHDRAW_FORM_STATUS';
export const SET_STAKING_BALANCE_STATUS = 'SET_STAKING_BALANCE_STATUS';
export const SET_BOT_BALANCE_STATUS = 'SET_BOT_BALANCE_STATUS';
export const SET_BONUSES_MODAL_STATUS = 'SET_BONUSES_MODAL_STATUS';
export const SET_HONEECHIZE_AFFILIATE_SETTINGS_MODAL_STATUS = 'SET_HONEECHIZE_AFFILIATE_SETTINGS_MODAL_STATUS';
export const SET_HONEECHIZE_AFFILIATE_TABLE_MODAL_STATUS = 'SET_HONEECHIZE_AFFILIATE_TABLE_MODAL_STATUS';
export const SET_BOT_MODAL_STATUS = 'SET_BOT_MODAL_STATUS';
export const SET_BOT_SUBSCRIBE_MODAL_STATUS = 'SET_BOT_SUBSCRIBE_MODAL_STATUS';
export const SET_BOT_PROMO_MODAL_STATUS = 'SET_BOT_PROMO_MODAL_STATUS';
export const SET_AMBASSADORS_MODAL_STATUS = 'SET_AMBASSADORS_MODAL_STATUS';
export const SET_AMBASSADORS_MODAL_SALARY_STATUS = 'SET_AMBASSADORS_MODAL_SALARY_STATUS';
export const SET_REF_LOGS_CORRECT_DATA = 'SET_REF_LOGS_CORRECT_DATA';
export const SET_REF_LOGS_PAGE_DATA = 'SET_REF_LOGS_PAGE_DATA';
export const GET_BOT_STATS_CORRECT_DATA = 'GET_BOT_STATS_CORRECT_DATA';
export const GET_WALLET_ASSETS_WITH_BALANCE = 'GET_WALLET_ASSETS_WITH_BALANCE';
export const SET_CURRENT_WALLET = 'SET_CURRENT_WALLET';
export const SET_ALERT_MODAL_STATUS = 'SET_ALERT_MODAL_STATUS';
export const SET_HONEECHIZE_ALERT_MODAL_STATUS = 'SET_HONEECHIZE_ALERT_MODAL_STATUS';
export const SET_BOT_SUBSCRIBE_ALERT_MODAL_STATUS = 'SET_BOT_SUBSCRIBE_ALERT_MODAL_STATUS';
export const SET_STANDARD_WALLET_CRYPTO_MODAL_STATUS = 'SET_STANDARD_WALLET_CRYPTO_MODAL_STATUS';
export const SET_CB_TASK_MODAL_STATUS = 'SET_CB_TASK_MODAL_STATUS';
export const SET_CB_REWARD_MODAL_STATUS = 'SET_CB_REWARD_MODAL_STATUS';

export const GET_AVAILABLE_CURRENCIES_STARTED = 'GET_AVAILABLE_CURRENCIES_STARTED';
export const GET_AVAILABLE_CURRENCIES_SUCCESS = 'GET_AVAILABLE_CURRENCIES_SUCCESS';
export const GET_AVAILABLE_CURRENCIES_FAILURE = 'GET_AVAILABLE_CURRENCIES_FAILURE';

export const CREATE_WALLET_STARTED = 'CREATE_WALLET_STARTED';
export const CREATE_WALLET_SUCCESS = 'CREATE_WALLET_SUCCESS';
export const CREATE_WALLET_FAILURE = 'CREATE_WALLET_FAILURE';

export const GET_WALLET_ASSETS_STARTED = 'GET_WALLET_ASSETS_STARTED';
export const GET_WALLET_ASSETS_SUCCESS = 'GET_WALLET_ASSETS_SUCCESS';
export const GET_WALLET_ASSETS_FAILURE = 'GET_WALLET_ASSETS_FAILURE';

export const GET_AVAILABLE_STAKING_STARTED = 'GET_AVAILABLE_STAKING_STARTED';
export const GET_AVAILABLE_STAKING_SUCCESS = 'GET_AVAILABLE_STAKING_SUCCESS';
export const GET_AVAILABLE_STAKING_FAILURE = 'GET_AVAILABLE_STAKING_FAILURE';

export const BALANCE_TRANSACTIONS_STARTED = 'BALANCE_TRANSACTIONS_STARTED';
export const BALANCE_TRANSACTIONS_SUCCESS = 'BALANCE_TRANSACTIONS_SUCCESS';
export const BALANCE_TRANSACTIONS_FAILURE = 'BALANCE_TRANSACTIONS_FAILURE';

export const OPEN_STAKING_STARTED = 'OPEN_STAKING_STARTED';
export const OPEN_STAKING_SUCCESS = 'OPEN_STAKING_SUCCESS';
export const OPEN_STAKING_FAILURE = 'OPEN_STAKING_FAILURE';

export const WITHDRAW_STAKING_STARTED = 'WITHDRAW_STAKING_STARTED';
export const WITHDRAW_STAKING_SUCCESS = 'WITHDRAW_STAKING_SUCCESS';
export const WITHDRAW_STAKING_FAILURE = 'WITHDRAW_STAKING_FAILURE';

export const GET_WALLET_TRANSACTIONS_STARTED = 'GET_WALLET_TRANSACTIONS_STARTED';
export const GET_WALLET_TRANSACTIONS_SUCCESS = 'GET_WALLET_TRANSACTIONS_SUCCESS';
export const GET_WALLET_TRANSACTIONS_FAILURE = 'GET_WALLET_TRANSACTIONS_FAILURE';

export const GET_USER_STAKING_STARTED = 'GET_USER_STAKING_STARTED';
export const GET_USER_STAKING_SUCCESS = 'GET_USER_STAKING_SUCCESS';
export const GET_USER_STAKING_FAILURE = 'GET_USER_STAKING_FAILURE';

export const GET_REF_MAIN_DATA_STARTED = 'GET_REF_MAIN_DATA_STARTED';
export const GET_REF_MAIN_DATA_SUCCESS = 'GET_REF_MAIN_DATA_SUCCESS';
export const GET_REF_MAIN_DATA_FAILURE = 'GET_REF_MAIN_DATA_FAILURE';

export const GET_REF_INFO_STARTED = 'GET_REF_INFO_STARTED';
export const GET_REF_INFO_SUCCESS = 'GET_REF_INFO_SUCCESS';
export const GET_REF_INFO_FAILURE = 'GET_REF_INFO_FAILURE';

export const GET_REF_LOGS_STARTED = 'GET_REF_LOGS_STARTED';
export const GET_REF_LOGS_SUCCESS = 'GET_REF_LOGS_SUCCESS';
export const GET_REF_LOGS_FAILURE = 'GET_REF_LOGS_FAILURE';

export const GET_BOT_MAIN_DATA_STARTED = 'GET_BOT_MAIN_DATA_STARTED';
export const GET_BOT_MAIN_DATA_SUCCESS = 'GET_BOT_MAIN_DATA_SUCCESS';
export const GET_BOT_MAIN_DATA_FAILURE = 'GET_BOT_MAIN_DATA_FAILURE';

export const OPEN_BOT_STARTED = 'OPEN_BOT_STARTED';
export const OPEN_BOT_SUCCESS = 'OPEN_BOT_SUCCESS';
export const OPEN_BOT_FAILURE = 'OPEN_BOT_FAILURE';

export const CHANGE_BOT_RISK_STARTED = 'CHANGE_BOT_RISK_STARTED';
export const CHANGE_BOT_RISK_SUCCESS = 'CHANGE_BOT_RISK_SUCCESS';
export const CHANGE_BOT_RISK_FAILURE = 'CHANGE_BOT_RISK_FAILURE';

export const GET_BOT_STATS_STARTED = 'GET_BOT_STATS_STARTED';
export const GET_BOT_STATS_SUCCESS = 'GET_BOT_STATS_SUCCESS';
export const GET_BOT_STATS_FAILURE = 'GET_BOT_STATS_FAILURE';

export const GET_BOT_PREVIEW_DATA_STARTED = 'GET_BOT_PREVIEW_DATA_STARTED';
export const GET_BOT_PREVIEW_DATA_SUCCESS = 'GET_BOT_PREVIEW_DATA_SUCCESS';
export const GET_BOT_PREVIEW_DATA_FAILURE = 'GET_BOT_PREVIEW_DATA_FAILURE';

export const GET_BOT_HISTORY_STARTED = 'GET_BOT_HISTORY_STARTED';
export const GET_BOT_HISTORY_SUCCESS = 'GET_BOT_HISTORY_SUCCESS';
export const GET_BOT_HISTORY_FAILURE = 'GET_BOT_HISTORY_FAILURE';

export const SEND_TRANSACTIONS_STARTED = 'SEND_TRANSACTIONS_STARTED';
export const SEND_TRANSACTIONS_SUCCESS = 'SEND_TRANSACTIONS_SUCCESS';
export const SEND_TRANSACTIONS_FAILURE = 'SEND_TRANSACTIONS_FAILURE';

export const STOP_BOT_STARTED = 'STOP_BOT_STARTED';
export const STOP_BOT_SUCCESS = 'STOP_BOT_SUCCESS';
export const STOP_BOT_FAILURE = 'STOP_BOT_FAILURE';

export const CHANGE_PASSWORD_STARTED = 'CHANGE_PASSWORD_STARTED';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const GET_BANNERS_STARTED = 'GET_BANNERS_STARTED';
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
export const GET_BANNERS_FAILURE = 'GET_BANNERS_FAILURE';

export const GET_EXCHANGE_DATA_STARTED = 'GET_EXCHANGE_DATA_STARTED';
export const GET_EXCHANGE_DATA_SUCCESS = 'GET_EXCHANGE_DATA_SUCCESS';
export const GET_EXCHANGE_DATA_FAILURE = 'GET_EXCHANGE_DATA_FAILURE';

export const EXCHANGE_STARTED = 'EXCHANGE_STARTED';
export const EXCHANGE_SUCCESS = 'EXCHANGE_SUCCESS';
export const EXCHANGE_FAILURE = 'EXCHANGE_FAILURE';

export const GET_EXCHANGE_HISTORY_STARTED = 'GET_EXCHANGE_HISTORY_STARTED';
export const GET_EXCHANGE_HISTORY_SUCCESS = 'GET_EXCHANGE_HISTORY_SUCCESS';
export const GET_EXCHANGE_HISTORY_FAILURE = 'GET_EXCHANGE_HISTORY_FAILURE';

export const BOT_INVEST_STARTED = 'BOT_INVEST_STARTED';
export const BOT_INVEST_SUCCESS = 'BOT_INVEST_SUCCESS';
export const BOT_INVEST_FAILURE = 'BOT_INVEST_FAILURE';

export const GET_ONE_CURRENT_WALLET_STARTED = 'GET_ONE_CURRENT_WALLET_STARTED';
export const GET_ONE_CURRENT_WALLET_SUCCESS = 'GET_ONE_CURRENT_WALLET_SUCCESS';
export const GET_ONE_CURRENT_WALLET_FAILURE = 'GET_ONE_CURRENT_WALLET_FAILURE';

export const GET_REFILLABLE_WALLETS = 'GET_REFILLABLE_WALLETS';
export const GET_CREATABLE_WALLETS = 'GET_CREATABLE_WALLETS';

export const SEND_SUPPORT_FORM_STARTED = 'SEND_SUPPORT_FORM_STARTED';
export const SEND_SUPPORT_FORM_SUCCESS = 'SEND_SUPPORT_FORM_SUCCESS';
export const SEND_SUPPORT_FORM_FAILURE = 'SEND_SUPPORT_FORM_FAILURE';

export const GET_AMBASSADOR_MAIN_DATA_STARTED = 'GET_AMBASSADOR_MAIN_DATA_STARTED';
export const GET_AMBASSADOR_MAIN_DATA_SUCCESS = 'GET_AMBASSADOR_MAIN_DATA_SUCCESS';
export const GET_AMBASSADOR_MAIN_DATA_FAILURE = 'GET_AMBASSADOR_MAIN_DATA_FAILURE';

export const GET_AMBASSADOR_STATUSES_STARTED = 'GET_AMBASSADOR_STATUSES_STARTED';
export const GET_AMBASSADOR_STATUSES_SUCCESS = 'GET_AMBASSADOR_STATUSES_SUCCESS';
export const GET_AMBASSADOR_STATUSES_FAILURE = 'GET_AMBASSADOR_STATUSES_FAILURE';

export const GET_AMBASSADOR_GRAPH_STARTED = 'GET_AMBASSADOR_GRAPH_STARTED';
export const GET_AMBASSADOR_GRAPH_SUCCESS = 'GET_AMBASSADOR_GRAPH_SUCCESS';
export const GET_AMBASSADOR_GRAPH_FAILURE = 'GET_AMBASSADOR_GRAPH_FAILURE';
export const GET_AMBASSADOR_GRAPH_CORRECT_DATA = 'GET_AMBASSADOR_GRAPH_CORRECT_DATA';

export const GET_AMBASSADOR_TURNOVER_STARTED = 'GET_AMBASSADOR_TURNOVER_STARTED';
export const GET_AMBASSADOR_TURNOVER_SUCCESS = 'GET_AMBASSADOR_TURNOVER_SUCCESS';
export const GET_AMBASSADOR_TURNOVER_FAILURE = 'GET_AMBASSADOR_TURNOVER_FAILURE';

export const GET_AMBASSADOR_LINKS_STARTED = 'GET_AMBASSADOR_LINKS_STARTED';
export const GET_AMBASSADOR_LINKS_SUCCESS = 'GET_AMBASSADOR_LINKS_SUCCESS';
export const GET_AMBASSADOR_LINKS_FAILURE = 'GET_AMBASSADOR_LINKS_FAILURE';
export const AMBASSADOR_CORRECT_LINKS = 'AMBASSADOR_CORRECT_LINKS';

export const SEND_AMBASSADOR_LINK_STARTED = 'SEND_AMBASSADOR_LINK_STARTED';
export const SEND_AMBASSADOR_LINK_SUCCESS = 'SEND_AMBASSADOR_LINK_SUCCESS';
export const SEND_AMBASSADOR_LINK_FAILURE = 'SEND_AMBASSADOR_LINK_FAILURE';

export const AMBASSADOR_LINK_ERRORS = 'AMBASSADOR_LINK_ERRORS';
export const AMBASSADOR_LINK_ERRORS_CLEAR = 'AMBASSADOR_LINK_ERRORS_CLEAR';

export const SET_INFLUENCER_MODAL_STATUS = 'SET_INFLUENCER_MODAL_STATUS';
export const SET_TICKETS_MODAL_STATUS = 'SET_TICKETS_MODAL_STATUS';

export const GET_TICKETS_STARTED = 'GET_TICKETS_STARTED';
export const GET_TICKETS_SUCCESS = 'GET_TICKETS_SUCCESS';
export const GET_TICKETS_FAILURE = 'GET_TICKETS_FAILURE';

export const BUY_TICKETS_STARTED = 'BUY_TICKETS_STARTED';
export const BUY_TICKETS_SUCCESS = 'BUY_TICKETS_SUCCESS';
export const BUY_TICKETS_FAILURE = 'BUY_TICKETS_FAILURE';

export const GET_CURRENT_WALLET_STARTED = 'GET_CURRENT_WALLET_STARTED';
export const GET_CURRENT_WALLET_SUCCESS = 'GET_CURRENT_WALLET_SUCCESS';
export const GET_CURRENT_WALLET_FAILURE = 'GET_CURRENT_WALLET_FAILURE';

export const CREATE_CURRENT_WALLET_STARTED = 'CREATE_CURRENT_WALLET_STARTED';
export const CREATE_CURRENT_WALLET_SUCCESS = 'CREATE_CURRENT_WALLET_SUCCESS';
export const CREATE_CURRENT_WALLET_FAILURE = 'CREATE_CURRENT_WALLET_FAILURE';

export const GET_CURRENT_WALLET_TRANSACTIONS_STARTED = 'GET_CURRENT_WALLET_TRANSACTIONS_STARTED';
export const GET_CURRENT_WALLET_TRANSACTIONS_SUCCESS = 'GET_CURRENT_WALLET_TRANSACTIONS_SUCCESS';
export const GET_CURRENT_WALLET_TRANSACTIONS_FAILURE = 'GET_CURRENT_WALLET_TRANSACTIONS_FAILURE';

export const GET_EVENTS_STARTED = 'GET_EVENTS_STARTED';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';

export const GET_CURRENT_EVENT_STARTED = 'GET_CURRENT_EVENT_STARTED';
export const GET_CURRENT_EVENT_SUCCESS = 'GET_CURRENT_EVENT_SUCCESS';
export const GET_CURRENT_EVENT_FAILURE = 'GET_CURRENT_EVENT_FAILURE';

export const SET_TASK_STARTED = 'SET_TASK_STARTED';
export const SET_TASK_SUCCESS = 'SET_TASK_SUCCESS';
export const SET_TASK_FAILURE = 'SET_TASK_FAILURE';

export const SET_COMMISSION_ALERT_STATUS = 'SET_COMMISSION_ALERT_STATUS';
export const SET_CREATE_WALLET_CURRENCIES = 'SET_CREATE_WALLET_CURRENCIES';

export const GET_WALLET_GRAPH_HISTORY_STARTED = 'GET_WALLET_GRAPH_HISTORY_STARTED';
export const GET_WALLET_GRAPH_HISTORY_SUCCESS = 'GET_WALLET_GRAPH_HISTORY_SUCCESS';
export const GET_WALLET_GRAPH_HISTORY_FAILURE = 'GET_WALLET_GRAPH_HISTORY_FAILURE';

export const GET_TOTAL_BALANCE_STARTED = 'GET_TOTAL_BALANCE_STARTED';
export const GET_TOTAL_BALANCE_SUCCESS = 'GET_TOTAL_BALANCE_SUCCESS';
export const GET_TOTAL_BALANCE_FAILURE = 'GET_TOTAL_BALANCE_FAILURE';

export const GET_CURRENCY_COMMISSION_STARTED = 'GET_CURRENCY_COMMISSION_STARTED';
export const GET_CURRENCY_COMMISSION_SUCCESS = 'GET_CURRENCY_COMMISSION_SUCCESS';
export const GET_CURRENCY_COMMISSION_FAILURE = 'GET_CURRENCY_COMMISSION_FAILURE';

export const GET_AMBASSADOR_SALARY_DATA_STARTED = 'GET_AMBASSADOR_SALARY_DATA_STARTED';
export const GET_AMBASSADOR_SALARY_DATA_SUCCESS = 'GET_AMBASSADOR_SALARY_DATA_SUCCESS';
export const GET_AMBASSADOR_SALARY_DATA_FAILURE = 'GET_AMBASSADOR_SALARY_DATA_FAILURE';

export const GET_OMT_PERIODS_STARTED = 'GET_OMT_PERIODS_STARTED';
export const GET_OMT_PERIODS_SUCCESS = 'GET_OMT_PERIODS_SUCCESS';
export const GET_OMT_PERIODS_FAILURE = 'GET_OMT_PERIODS_FAILURE';

export const GET_AVAILABLE_WALLETS_STARTED = 'GET_AVAILABLE_WALLETS_STARTED';
export const GET_AVAILABLE_WALLETS_SUCCESS = 'GET_AVAILABLE_WALLETS_SUCCESS';
export const GET_AVAILABLE_WALLETS_FAILURE = 'GET_AVAILABLE_WALLETS_FAILURE';

export const GET_BOT_SUBSCRIBE_INFO_STARTED = 'GET_BOT_SUBSCRIBE_INFO_STARTED';
export const GET_BOT_SUBSCRIBE_INFO_SUCCESS = 'GET_BOT_SUBSCRIBE_INFO_SUCCESS';
export const GET_BOT_SUBSCRIBE_INFO_FAILURE = 'GET_BOT_SUBSCRIBE_INFO_FAILURE';

export const GET_CUSTOM_SETTINGS_STARTED = 'GET_CUSTOM_SETTINGS_STARTED';
export const GET_CUSTOM_SETTINGS_SUCCESS = 'GET_CUSTOM_SETTINGS_SUCCESS';
export const GET_CUSTOM_SETTINGS_FAILURE = 'GET_CUSTOM_SETTINGS_FAILURE';

export const SET_CUSTOM_SETTINGS_STARTED = 'SET_CUSTOM_SETTINGS_STARTED';
export const SET_CUSTOM_SETTINGS_SUCCESS = 'SET_CUSTOM_SETTINGS_SUCCESS';
export const SET_CUSTOM_SETTINGS_FAILURE = 'SET_CUSTOM_SETTINGS_FAILURE';

export const BUY_SUBSCRIBE_STARTED = 'BUY_SUBSCRIBE_STARTED';
export const BUY_SUBSCRIBE_SUCCESS = 'BUY_SUBSCRIBE_SUCCESS';
export const BUY_SUBSCRIBE_FAILURE = 'BUY_SUBSCRIBE_FAILURE';

export const GET_STAKING_COMMISSION_STARTED = 'GET_STAKING_COMMISSION_STARTED';
export const GET_STAKING_COMMISSION_SUCCESS = 'GET_STAKING_COMMISSION_SUCCESS';
export const GET_STAKING_COMMISSION_FAILURE = 'GET_STAKING_COMMISSION_FAILURE';

export const GET_STAKING_AWARD_STARTED = 'GET_STAKING_AWARD_STARTED';
export const GET_STAKING_AWARD_SUCCESS = 'GET_STAKING_AWARD_SUCCESS';
export const GET_STAKING_AWARD_FAILURE = 'GET_STAKING_AWARD_FAILURE';

export const GET_TRANSFER_COMMISSION_STARTED = 'GET_TRANSFER_COMMISSION_STARTED';
export const GET_TRANSFER_COMMISSION_SUCCESS = 'GET_TRANSFER_COMMISSION_SUCCESS';
export const GET_TRANSFER_COMMISSION_FAILURE = 'GET_TRANSFER_COMMISSION_FAILURE';

export const WITHDRAW_OMT_STAKING_STARTED = 'WITHDRAW_OMT_STAKING_STARTED';
export const WITHDRAW_OMT_STAKING_SUCCESS = 'WITHDRAW_OMT_STAKING_SUCCESS';
export const WITHDRAW_OMT_STAKING_FAILURE = 'WITHDRAW_OMT_STAKING_FAILURE';

export const GET_INDICATORS_STARTED = 'GET_INDICATORS_STARTED';
export const GET_INDICATORS_SUCCESS = 'GET_INDICATORS_SUCCESS';
export const GET_INDICATORS_FAILURE = 'GET_INDICATORS_FAILURE';

export const GET_CRYPTO_NEWS_STARTED = 'GET_CRYPTO_NEWS_STARTED';
export const GET_CRYPTO_NEWS_SUCCESS = 'GET_CRYPTO_NEWS_SUCCESS';
export const GET_CRYPTO_NEWS_FAILURE = 'GET_CRYPTO_NEWS_FAILURE';

export const GET_HONEECHIZE_MAIN_DATA_STARTED = 'GET_HONEECHIZE_MAIN_DATA_STARTED';
export const GET_HONEECHIZE_MAIN_DATA_SUCCESS = 'GET_HONEECHIZE_MAIN_DATA_SUCCESS';
export const GET_HONEECHIZE_MAIN_DATA_FAILURE = 'GET_HONEECHIZE_MAIN_DATA_FAILURE';

export const GET_HONEECHIZE_EARNINGS_GRAPH_DATA_STARTED = 'GET_HONEECHIZE_EARNINGS_GRAPH_DATA_STARTED';
export const GET_HONEECHIZE_EARNINGS_GRAPH_DATA_SUCCESS = 'GET_HONEECHIZE_EARNINGS_GRAPH_DATA_SUCCESS';
export const GET_HONEECHIZE_EARNINGS_GRAPH_DATA_FAILURE = 'GET_HONEECHIZE_EARNINGS_GRAPH_DATA_FAILURE';

export const GET_HONEECHIZE_PROFIT_GRAPH_DATA_STARTED = 'GET_HONEECHIZE_PROFIT_GRAPH_DATA_STARTED';
export const GET_HONEECHIZE_PROFIT_GRAPH_DATA_SUCCESS = 'GET_HONEECHIZE_PROFIT_GRAPH_DATA_SUCCESS';
export const GET_HONEECHIZE_PROFIT_GRAPH_DATA_FAILURE = 'GET_HONEECHIZE_PROFIT_GRAPH_DATA_FAILURE';

export const GET_HONEECHIZE_AFFILIATE_SETTINGS_STARTED = 'GET_HONEECHIZE_AFFILIATE_SETTINGS_STARTED';
export const GET_HONEECHIZE_AFFILIATE_SETTINGS_SUCCESS = 'GET_HONEECHIZE_AFFILIATE_SETTINGS_SUCCESS';
export const GET_HONEECHIZE_AFFILIATE_SETTINGS_FAILURE = 'GET_HONEECHIZE_AFFILIATE_SETTINGS_FAILURE';

export const GET_HONEECHIZE_BOT_EARNINGS_STARTED = 'GET_HONEECHIZE_BOT_EARNINGS_STARTED';
export const GET_HONEECHIZE_BOT_EARNINGS_SUCCESS = 'GET_HONEECHIZE_BOT_EARNINGS_SUCCESS';
export const GET_HONEECHIZE_BOT_EARNINGS_FAILURE = 'GET_HONEECHIZE_BOT_EARNINGS_FAILURE';

export const GET_HONEECHIZE_BOT_SETTINGS_STARTED = 'GET_HONEECHIZE_BOT_SETTINGS_STARTED';
export const GET_HONEECHIZE_BOT_SETTINGS_SUCCESS = 'GET_HONEECHIZE_BOT_SETTINGS_SUCCESS';
export const GET_HONEECHIZE_BOT_SETTINGS_FAILURE = 'GET_HONEECHIZE_BOT_SETTINGS_FAILURE';

export const SET_HONEECHIZE_BOT_SETTINGS_STARTED = 'SET_HONEECHIZE_BOT_SETTINGS_STARTED';
export const SET_HONEECHIZE_BOT_SETTINGS_SUCCESS = 'SET_HONEECHIZE_BOT_SETTINGS_SUCCESS';
export const SET_HONEECHIZE_BOT_SETTINGS_FAILURE = 'SET_HONEECHIZE_BOT_SETTINGS_FAILURE';

export const CHANGE_HONEECHIZE_AFFILIATE_PERCENT_STARTED = 'CHANGE_HONEECHIZE_AFFILIATE_PERCENT_STARTED';
export const CHANGE_HONEECHIZE_AFFILIATE_PERCENT_SUCCESS = 'CHANGE_HONEECHIZE_AFFILIATE_PERCENT_SUCCESS';
export const CHANGE_HONEECHIZE_AFFILIATE_PERCENT_FAILURE = 'CHANGE_HONEECHIZE_AFFILIATE_PERCENT_FAILURE';

export const GET_HONEECHIZE_AFFILIATE_STATISTIC_STARTED = 'GET_HONEECHIZE_AFFILIATE_STATISTIC_STARTED';
export const GET_HONEECHIZE_AFFILIATE_STATISTIC_SUCCESS = 'GET_HONEECHIZE_AFFILIATE_STATISTIC_SUCCESS';
export const GET_HONEECHIZE_AFFILIATE_STATISTIC_FAILURE = 'GET_HONEECHIZE_AFFILIATE_STATISTIC_FAILURE';

export const SET_HONEECHIZE_AFFILIATE_STATISTIC_PAGE_DATA = 'SET_HONEECHIZE_AFFILIATE_STATISTIC_PAGE_DATA';
export const SET_HONEECHIZE_AFFILIATE_STATISTIC_CORRECT_DATA = 'SET_HONEECHIZE_AFFILIATE_STATISTIC_CORRECT_DATA';

export const GET_COMMUNITY_BOOST_MAIN_DATA_STARTED = 'GET_COMMUNITY_BOOST_MAIN_DATA_STARTED';
export const GET_COMMUNITY_BOOST_MAIN_DATA_SUCCESS = 'GET_COMMUNITY_BOOST_MAIN_DATA_SUCCESS';
export const GET_COMMUNITY_BOOST_MAIN_DATA_FAILURE = 'GET_COMMUNITY_BOOST_MAIN_DATA_FAILURE';

export const SEND_COMMUNITY_BOOST_POST_STARTED = 'SEND_COMMUNITY_BOOST_POST_STARTED';
export const SEND_COMMUNITY_BOOST_POST_SUCCESS = 'SEND_COMMUNITY_BOOST_POST_SUCCESS';
export const SEND_COMMUNITY_BOOST_POST_FAILURE = 'SEND_COMMUNITY_BOOST_POST_FAILURE';

export const GET_COMMUNITY_BOOST_LEADERBOARD_DATA_STARTED = 'GET_COMMUNITY_BOOST_LEADERBOARD_DATA_STARTED';
export const GET_COMMUNITY_BOOST_LEADERBOARD_DATA_SUCCESS = 'GET_COMMUNITY_BOOST_LEADERBOARD_DATA_SUCCESS';
export const GET_COMMUNITY_BOOST_LEADERBOARD_DATA_FAILURE = 'GET_COMMUNITY_BOOST_LEADERBOARD_DATA_FAILURE';

export const GET_INDEX_MAIN_DATA_STARTED = 'GET_INDEX_MAIN_DATA_STARTED';
export const GET_INDEX_MAIN_DATA_SUCCESS = 'GET_INDEX_MAIN_DATA_SUCCESS';
export const GET_INDEX_MAIN_DATA_FAILURE = 'GET_INDEX_MAIN_DATA_FAILURE';

export const INDEX_SWAP_STARTED = 'INDEX_SWAP_STARTED';
export const INDEX_SWAP_SUCCESS = 'INDEX_SWAP_SUCCESS';
export const INDEX_SWAP_FAILURE = 'INDEX_SWAP_FAILURE';

export const GET_INDEX_SWAP_HISTORY_STARTED = 'GET_INDEX_SWAP_HISTORY_STARTED';
export const GET_INDEX_SWAP_HISTORY_SUCCESS = 'GET_INDEX_SWAP_HISTORY_SUCCESS';
export const GET_INDEX_SWAP_HISTORY_FAILURE = 'GET_INDEX_SWAP_HISTORY_FAILURE';

export const GET_INDEX_SWAP_PREDICTION_STARTED = 'GET_INDEX_SWAP_PREDICTION_STARTED';
export const GET_INDEX_SWAP_PREDICTION_SUCCESS = 'GET_INDEX_SWAP_PREDICTION_SUCCESS';
export const GET_INDEX_SWAP_PREDICTION_FAILURE = 'GET_INDEX_SWAP_PREDICTION_FAILURE';