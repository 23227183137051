import React, {useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {setInfluencerModalStatus} from "../../store/wallet/action";



const AmbassadorsInfluencerModal = () => {
    const address = 'TFf83RMDHMFtAFwFANGmFu9JK63gx8PNfS';
    const dispatch = useDispatch();
    const influencerModalStatus = useSelector(store => store.wallet.influencerModalStatus);
    const [addressCopyStatus, setAddressCopyStatus] = useState(false);
    const closeModal = () =>{
        dispatch(setInfluencerModalStatus(false))
    }


    return (
        <Popup
            open={influencerModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
            className={`modalWithoutP`}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>

                <div className={`${Styles.modalContentWrap}`}>
                    <div>Please transfer the funds to the following address:</div>
                    <div className={`${Styles.address}`}>{address}</div>
                    <div className={`${Styles.copyblock}`}>
                        <button
                            className={`${Styles.rowItemFakeInputCopyBtn} ${addressCopyStatus ? Styles.rowItemFakeInputCopyBtnCopied : null} `}
                            onClick={()=>{
                                setAddressCopyStatus(true);
                                navigator.clipboard.writeText(address);
                            }}
                        >
                            {addressCopyStatus ? 'Copied' : 'Copy'}
                        </button>
                    </div>
                    <div>
                        After the transfer is completed, the project tokens will be automatically credited to your account.
                    </div>
                    <div>
                        Thank you for your support and trust in our project. If you have any questions or need further assistance, please feel free to contact us.
                    </div>
                    <div>
                        Thank you!
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default AmbassadorsInfluencerModal;
