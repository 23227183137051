import React, {useCallback} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {setCbRewardModalStatus} from "../../store/wallet/action";
import {useTranslation} from "react-i18next";


const CommunityBoostRewardModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const cbModalStatus = useSelector(store => store.wallet.cbRewardModalStatus);




    const closeModal = useCallback(() =>{
        dispatch(setCbRewardModalStatus(false));
    }, [dispatch])

    return (
        <Popup
            open={cbModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.title}`}>{t('Награды')}:</div>
                    <div className={`${Styles.rewardWrap}`}>
                        <div className={`${Styles.rewardItem}`}>
                            <div className={`${Styles.place}`}>4-6 {t('место')}</div>
                            <div className={`${Styles.reward}`}>{t('Ежемесячный доход HoneeAi без комиссий')}</div>
                        </div>
                        <div className={`${Styles.rewardItem}`}>
                            <div className={`${Styles.place}`}>7-10 {t('место')}</div>
                            <div className={`${Styles.reward}`}>{t('Обмен Honee без комиссий на USDT на один месяц')}</div>
                        </div>
                        <div className={`${Styles.rewardItem}`}>
                            <div className={`${Styles.place}`}>11-14 {t('место')}</div>
                            <div className={`${Styles.reward}`}>{t('Подписка PRO+ на год (активируется автоматически, как только призы будут розданы)')}</div>
                        </div>
                        <div className={`${Styles.rewardItem}`}>
                            <div className={`${Styles.place}`}>15-20 {t('место')}</div>
                            <div className={`${Styles.reward}`}>{t('Подписка PRO на год (активируется автоматически, как только призы будут розданы)')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default CommunityBoostRewardModal;
