import React from "react";
import Styles from './styles.module.scss'


const TestMain = () => {


    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.contentBox}`}>
                        <div className={`${Styles.contentBoxItem}`}>
                            <div className={`${Styles.title2}`}>Settings</div>
                            <div className={`${Styles.inputsWrap}`}>
                                <div className={`${Styles.inputRow}`}>
                                    <div className={`${Styles.inputWrap}`}>
                                        <div className={`${Styles.title4}`}>Buy tokens</div>
                                        <input type="text"/>
                                    </div>
                                    <div className={`${Styles.btn}`}>Buy</div>
                                </div>
                                <div className={`${Styles.inputRow}`}>
                                    <div className={`${Styles.inputWrap}`}>
                                        <div className={`${Styles.title4}`}>Staking</div>
                                        <input type="text"/>
                                    </div>
                                    <div className={`${Styles.btn}`}>Deposit</div>
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.contentBoxItem}`}>
                            <div className={`${Styles.title2}`}>Quantity tokens:</div>
                            <div className={`${Styles.quantityWrap}`}>
                                <div className={`${Styles.quantityItem}`}>
                                    <div className={`${Styles.title4}`}>Total tokens:</div>
                                    <div className={`${Styles.quantityValue} ${Styles.colorOrange}`}>2.779 OMT</div>
                                </div>
                                <div className={`${Styles.quantityItem}`}>
                                    <div className={`${Styles.title4}`}>Available tokens:</div>
                                    <div className={`${Styles.quantityValue} ${Styles.colorGreen}`}>1.876 OMT</div>
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.contentBoxItem}`}>
                            <div className={`${Styles.title2}`}>Staking accruals:</div>
                            <div className={`${Styles.stakingWrap}`}>
                                <div className={`${Styles.stakingItem}`}>
                                    <div className={`${Styles.title4}`}>Total:</div>
                                    <div className={`${Styles.stakingValue} ${Styles.colorOrange}`}>2.779 OMT</div>
                                </div>
                                <div className={`${Styles.stakingItem}`}>
                                    <div className={`${Styles.title4}`}>Days:</div>
                                    <div className={`${Styles.stakingValue} ${Styles.colorGreen}`}>14</div>
                                </div>
                                <div className={`${Styles.stakingItem}`}>
                                    <div className={`${Styles.title4}`}>Token burn:</div>
                                    <div className={`${Styles.stakingValue} ${Styles.colorGreen}`}>14</div>
                                </div>
                                <div className={`${Styles.stakingItem}`}>
                                    <div className={`${Styles.title4}`}>Earnings:</div>
                                    <div className={`${Styles.stakingValue} ${Styles.colorGreen}`}>14</div>
                                </div>
                            </div>
                            <div className={`${Styles.withdrawWrap}`}>
                                <div className={`${Styles.inputRow}`}>
                                    <div className={`${Styles.inputWrap}`}>
                                        <div className={`${Styles.title4}`}>Withdraw</div>
                                        <input type="text"/>
                                    </div>
                                    <div className={`${Styles.btn}`}>Withdrawal</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestMain;
