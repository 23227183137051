import React, {useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {buySubscribe, setBotSubscribeModalStatus} from "../../store/wallet/action";

import subCheckIcon from '../../theme/assets/svg/omt_subscribe_check.svg';
import subCheckProIcon from '../../theme/assets/svg/omt_subscribe_check_pro.svg';
import subCheckRegularIcon from '../../theme/assets/svg/omt_subscribe_check_regular.svg';
import {useTranslation} from "react-i18next";


const BotModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const botSubscribeModalStatus = useSelector(store => store.wallet.botSubscribeModalStatus);
    const botSubscribeInfo = useSelector(store => store.wallet.botSubscribeInfo);
    const buySubscribeStatusStarted = useSelector(store => store.wallet.buySubscribeStatusStarted);
    const botMainData = useSelector(store => store.wallet.botMainData);


    const [currentSubscribe, setCurrentSubscribe] = useState(0);
    const [regularAmount, setRegularAmount] = useState(0);
    const [proAmount, setProAmount] = useState(0);

    const closeModal = () =>{
        dispatch(setBotSubscribeModalStatus(false))
    }

    const balanceHandler = (subId) =>{
        let curPrice = false
        botSubscribeInfo.subscriptions.forEach((item)=>{
            if (item.id === subId){
                curPrice = item.name === 'Pro' ? (item.periods[proAmount].amount - botSubscribeInfo?.amount) : (item.periods[regularAmount].amount - botSubscribeInfo?.amount)
            }
        })

        return botSubscribeInfo?.balance?.amount * 1 >= curPrice
    }

    const subscribeSendHandler = (subId, subIndex) =>{
        if (balanceHandler(subId) && !buySubscribeStatusStarted){
            dispatch(buySubscribe({subscription_id: subId, period_id: botSubscribeInfo.subscriptions[subIndex].periods[botSubscribeInfo.subscriptions[subIndex].name === 'Pro' ? proAmount : regularAmount].id}));
        }
    }

    return (
        <Popup
            open={botSubscribeModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap} ${botSubscribeInfo?.subscriptions?.length < 2 ? Styles.modalContentWrapShort : null}`}>
                    {!botSubscribeInfo?.subscriptions?.length > 0 ?
                        <div className={`${Styles.textWrap}`}>
                            <div className={`${Styles.textWrapItem}`}>{t('Ваш запрос на покупку подписки успешно отправлен!')}</div>
                            <div className={`${Styles.textWrapItem}`}>{t('Чтобы начать использовать все возможности нашего бота, дождитесь завершения транзакции.')}</div>
                        </div> : null
                    }
                    <div className={`${Styles.titleRow}`}>
                        {botSubscribeInfo?.subscriptions?.length > 0 ? botSubscribeInfo.subscriptions.map((item, index)=>{
                            return (
                                <div
                                    className={`${Styles.titleRowItem} ${currentSubscribe === index ? Styles.titleRowItemActive : null} ${item.name === 'Pro' ? Styles.bgPro : Styles.bgReg}`}
                                    key={'titleRowItem' + index}
                                    onClick={()=>{setCurrentSubscribe(index)}}
                                >
                                    {item.name}
                                </div>
                            )
                        }) : null}
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        {botSubscribeInfo?.subscriptions?.length > 0 ? botSubscribeInfo.subscriptions.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem} ${currentSubscribe === index ? Styles.contentBoxItemActive : null}`} key={'contentBoxItem' + index}>
                                    <div className={`${Styles.contentBoxItemName} ${item.name === 'Pro' ? Styles.colorOrange : null}`}>{item.name}</div>
                                    <div className={`${Styles.contentBoxItemPrice}`}>
                                        {t('Цена')}: {botMainData?.subscription?.subscription?.name === item.name ? item.periods[item.name === 'Pro' ? proAmount : regularAmount]?.amount * 1 : item.periods[item.name === 'Pro' ? proAmount : regularAmount]?.amount - botSubscribeInfo?.amount} OMT_BEP20
                                    </div>
                                    <div className={`${Styles.contentBoxItemPeriodWrap}`}>
                                        {item?.periods?.map((itemPeriod, indexPeriod)=>{
                                            return (
                                                <div
                                                    className={`${Styles.contentBoxItemPeriodItem}`}
                                                    key={'contentBoxItemPeriodItem' + indexPeriod}
                                                    onClick={()=>{
                                                        if (item.name === 'Pro'){
                                                            setProAmount(indexPeriod)
                                                        } else {
                                                            setRegularAmount(indexPeriod)
                                                        }
                                                    }}
                                                >
                                                    <div className={`${Styles.contentBoxItemPeriodItemImgWrap}`}>
                                                        <img src={item.name === 'Pro' ? proAmount === indexPeriod ? subCheckProIcon : subCheckIcon : regularAmount === indexPeriod ? subCheckRegularIcon : subCheckIcon } alt="check icon"/>
                                                    </div>
                                                    <div className={`${Styles.contentBoxItemPeriodItemValue}`}>{itemPeriod.month} {t('мес.')}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className={`${Styles.contentBoxItemLink} ${item.name === 'Pro' ? Styles.colorOrange : null}`} onClick={()=>{window.open("https://omt.technology/white-paper/honee-ai", "_blank")}}>{t('Узнать больше о плане')}</div>
                                    <div className={`${Styles.btn} ${balanceHandler(item.id) && !buySubscribeStatusStarted ? null : Styles.btnDisabled} ${item.name === 'Pro' ? null : Styles.btnRegular}`} onClick={()=>{subscribeSendHandler(item.id, index)}}>
                                        {buySubscribeStatusStarted ? t('Загрузка...') : t('Подписаться')}
                                    </div>
                                    <div className={`${Styles.balance}`}>{t('Доступный баланс')}: <span className={Styles.colorOrange}>{botSubscribeInfo?.balance?.amount * 1} OMT_BEP20</span></div>
                                </div>
                            )
                        }) : null}
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default BotModal;
