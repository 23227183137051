import React, {useState} from "react";
import {scroller} from "react-scroll";
import Styles from './styles.module.scss'

import logo from '../../theme/assets/svg/omt_final_logo.svg';
// import nyLogo from '../../theme/assets/svg/omt_ny_logo.svg';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {pageScrollHandler} from "../../helpers/pageScrollHandler";
import {setLinkScrollStatus} from "../../store/main/action";
import LangSwitcher from "../langSwitcher";

const MainHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const authStatus = useSelector(store => store.main.authorizationStatus);
    const [mobileNavStatus, setMobileNavStatus] = useState(false);

    const scrollHandler = (element) =>{
        scroller.scrollTo(element, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${mobileNavStatus ? Styles.contentWrapActive : null}`}>
                <div
                    className={`${Styles.navBtn} ${mobileNavStatus ? Styles.navBtnActive : null}`}
                    onClick={()=>{setMobileNavStatus(!mobileNavStatus)}}
                >
                    <div className={`${Styles.navBtnItem}`}/>
                    <div className={`${Styles.navBtnItem}`}/>
                    <div className={`${Styles.navBtnItem}`}/>
                </div>
                <div className={`${Styles.langBox}`}>
                    <LangSwitcher/>
                </div>
                <div className={`${Styles.dropContent} ${mobileNavStatus ? Styles.dropContentActive : null}`}>
                    <div className={`${Styles.navWrap}`}>
                        <div
                            className={`${Styles.navItem}`}
                            onClick={()=>{
                                if (pageScrollHandler()){
                                    dispatch(setLinkScrollStatus('about'));
                                    navigate('/');
                                } else {
                                    scrollHandler('about');
                                }
                            }}
                        >
                            {t('Что представляет собой ОМТ')}?
                        </div>
                        <div
                            className={`${Styles.navItem}`}
                            onClick={()=>{
                                if (pageScrollHandler()){
                                    dispatch(setLinkScrollStatus('start'));
                                    navigate('/');
                                } else {
                                    scrollHandler('start');
                                }
                            }}
                        >
                            {t('Как стать пользователем')}?
                        </div>
                        <div
                            className={`${Styles.navItem}`}
                            onClick={()=>{navigate('/white-paper/executive-summary')}}
                        >
                            {t('Доĸументация')}
                        </div>
                        <div
                            className={`${Styles.navItem}`}
                            onClick={()=>{navigate('/news/page/1')}}
                        >

                            {t('Новости')}
                        </div>
                    </div>
                    <div className={`${Styles.langWrap}`}>
                        <LangSwitcher isVertical={true}/>
                    </div>
                    {authStatus?.phrase && authStatus?.authorization ?
                        <div className={`${Styles.btnWrapAuth}`}>
                            <div className={`${Styles.btnItem}`} onClick={()=>{navigate('/home')}}>{t('Кабинет')}</div>
                        </div> :
                        <div className={`${Styles.btnWrap}`}>
                            <div className={`${Styles.btnItem}`} onClick={()=>{navigate('/sign-up')}}>{t('Регистрация')}</div>
                            <div className={`${Styles.btnItem}`} onClick={()=>{navigate('/sign-in')}}>{t('Вход')}</div>
                        </div>
                    }

                </div>
                <div className={`${Styles.logoWrap}`} onClick={()=>{navigate('/')}}>
                    <img src={logo} alt="logotype"/>
                </div>
            </div>
        </section>
    );
};

export default MainHeader;
