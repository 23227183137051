import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import honeechizeIcon from '../../theme/assets/svg/omt_honeechize_icon.svg';
import {dotsHandler} from "../../helpers/dotsHandler";



const HoneechizeMain = () => {
    const honeechizeMainData = useSelector(store => store.wallet.honeechizeMainData);
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.mainWrap}`}>
                        <div className={`${Styles.titleRow}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={honeechizeIcon} alt="honeechize"/>
                            </div>
                            <div className={`${Styles.title2} ${Styles.colorOrange}`}>Honeechize</div>
                        </div>
                        <div className={`${Styles.contentRow}`}>
                            <div className={`${Styles.contentRowNote}`}>
                                {t('Наш искусственный интеллект, осуществляющий торговлю на рынке вместо вас! Вы можете найти подробную информацию.')}
                            </div>
                            <div className={`${Styles.contentRowInfo}`}>
                                <div className={`${Styles.infoBox}`}>
                                    <div className={`${Styles.title4} ${Styles.colorGreen}`}>{t('Баланс')}:</div>
                                    <div className={`${Styles.infoValue}`}>${honeechizeMainData ? dotsHandler(honeechizeMainData?.balance) : 0}</div>
                                </div>
                                <div className={`${Styles.infoBox}`}>
                                    <div className={`${Styles.title4} ${Styles.colorOrange}`}>{t('Структура')}:</div>
                                    <div className={`${Styles.infoValue}`}>{honeechizeMainData ? dotsHandler(honeechizeMainData?.in_work) : 0}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.otherWrap}`}>
                        <div className={`${Styles.otherTitleRow}`}>
                            <div className={`${Styles.title2}`}>{t('Информация')}:</div>
                        </div>
                        <div className={`${Styles.otherRowInfo}`}>
                            <div className={`${Styles.infoBox}`}>
                                <div className={`${Styles.title4} ${Styles.colorYellow}`}>{t('Заработок')}:</div>
                                <div className={`${Styles.infoValue}`}>${honeechizeMainData ? dotsHandler(honeechizeMainData?.money_earned) : 0}</div>
                            </div>
                            <div className={`${Styles.infoBox}`}>
                                <div className={`${Styles.title4} ${Styles.colorYellow}`}>{t('Ваша структура')}:</div>
                                <div className={`${Styles.infoValue}`}>{honeechizeMainData ? dotsHandler(honeechizeMainData?.structure_size) : 0}</div>
                            </div>
                            <div className={`${Styles.infoBox}`}>
                                <div className={`${Styles.title4} ${Styles.colorYellow}`}>{t('Инвестировано')}:</div>
                                <div className={`${Styles.infoValue}`}>${honeechizeMainData ? dotsHandler(honeechizeMainData?.money_invested) : 0}</div>
                            </div>
                            <div className={`${Styles.infoBox}`}>
                                <div className={`${Styles.title4} ${Styles.colorYellow}`}>{t('Выплачено')}:</div>
                                <div className={`${Styles.infoValue}`}>${honeechizeMainData ? dotsHandler(honeechizeMainData?.paid_out) : 0}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HoneechizeMain;
