import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {Route, Routes, Outlet} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MainPage from "../../pages/mainPage";
import RegPage from "../../pages/regPage";
import LogPage from "../../pages/logPage";
import Header from "../header";
import Footer from "../footer";
import ConfirmationPage from "../../pages/confirmationPage";
import WalletPage from "../../pages/walletPage";
import {useDispatch, useSelector} from "react-redux";
import {getUserData} from "../../store/main/action";
import WalletNavigation from "../walletNavigation";
import WalletHeader from "../walletHeader";
import StakingPage from "../../pages/stakingPage";
import MainHeader from "../mainHeader";
import MainFooter from "../mainFooter";
import BotPage from "../../pages/botPage";
import BonusesPage from "../../pages/bonusesPage";
import SettingsPage from "../../pages/settingsPage";
import SupportPage from "../../pages/supportPage";
import ExchangePage from "../../pages/exchangePage";
import TransactionsPage from "../../pages/transactionsPage";
import NewsPage from "../../pages/newsPage";
import PostPage from "../../pages/postPage";
import PrivacyPolicyPage from "../../pages/privacyPolicyPage";
import UserAgreementPage from "../../pages/userAgreementPage";
import WhitePaperNavigation from "../whitePaperNavigation";
import WhitePaperExecutiveSummary from "../../pages/whitepaperPages/whitePaperExecutiveSummary";
import WhitePaperMission from "../../pages/whitepaperPages/whitePaperMission";
import WhitePaperHistory from "../../pages/whitepaperPages/whitePaperHistory";
import WhitePaperGrowthStrategy from "../../pages/whitepaperPages/whitePaperGrowthStrategy";
import WhitePaperCommunity from "../../pages/whitepaperPages/whitePaperCommunity";
import WhitePaperMarketChallenges from "../../pages/whitepaperPages/whitePaperMarketChallenges";
import WhitePaperRoadmap from "../../pages/whitepaperPages/whitePaperRoadmap";
import WhitePaperMAmarketStatus from "../../pages/whitepaperPages/whitePaperMAmarketStatus";
import WhitePaperMAadvantages from "../../pages/whitepaperPages/whitePaperMAadvantages";
import WhitePaperMAdevelopmentTrends from "../../pages/whitepaperPages/whitePaperMAdevelopmentTrends";
import WhitePaperMAforecast from "../../pages/whitepaperPages/whitePaperMAforecast";
import WhitePaperOEoverview from "../../pages/whitepaperPages/whitePaperOEoverview";
import WhitePaperOEomtcStaking from "../../pages/whitepaperPages/whitePaperOEomtcStaking";
import WhitePaperOEstakingTPcrypto from "../../pages/whitepaperPages/whitePaperOEstakingTPcrypto";
import WhitePaperOEhoneeAi from "../../pages/whitepaperPages/whitePaperOEhoneeAi";
import WhitePaperTMdeflationaryModel from "../../pages/whitepaperPages/whitePaperTMdeflationaryModel";
import WhitePaperMarketAnalysis from "../../pages/whitepaperPages/whitePaperMarketAnalysis";
import WhitePaperOmtEcosystem from "../../pages/whitepaperPages/whitePaperOmtEcosystem";
import WhitePaperTokenomics from "../../pages/whitepaperPages/whitePaperTokenomics";
import AmbassadorsPage from "../../pages/ambassadorsPage";
import PublicPresalePage from "../../pages/publicPresalePage";
// import TicketsPage from "../../pages/ticketsPage";
import ResetPasswordPage from "../../pages/resetPasswordPage";
import StandardWalletPage from "../../pages/standardWalletPage";
import StandardCurrentWalletPage from "../../pages/standardCurrentWalletPage";
// import EventsPage from "../../pages/eventsPage";
// import EventPage from "../../pages/eventPage";
import ExternalChat from "../externalChat";
import TestPage from "../../pages/testPage";
import NewDevicePage from "../../pages/newDevicePage";
import WhitePaperHoneyAi from "../../pages/whitepaperPages/whitePaperHoneyAi";
import WhitePaperHAtechnicalPart from "../../pages/whitepaperPages/whitePaperHAtechnicalPart";
import WhitePaperHAanalysing from "../../pages/whitepaperPages/whitePaperHAanalysing";
import WhitePaperHAmoneyFlowIndex from "../../pages/whitepaperPages/whitePaperHAmoneyFlowIndex";
import WhitePaperHAparabolicSar from "../../pages/whitepaperPages/whitePaperHAparabolicSar";
import WhitePaperHAmethodOfTrading from "../../pages/whitepaperPages/whitePaperHAmethodOfTrading";
import WhitePaperHArelativeAverageConditions from "../../pages/whitepaperPages/whitePaperHArelativeAverageConditions";
// import AlertModal from "../alertModal";
import {
    // setAlertModalStatus,
    setBotSubscribeAlertModalStatus,
    // setHoneechizeAlertModalStatus
} from "../../store/wallet/action";
import BotSubscribeAlertModal from "../botSubscribeAlertModal";
import HoneechizeMetricsPage from "../../pages/honeechizeMetricsPage";
import HoneechizeBotPage from "../../pages/honeechizeBotPage";
import HoneechizeAffiliatePage from "../../pages/honeechizeAffiliatePage";
// import HoneechizeAlertModal from "../honeechizeAlertModal";
import CommunityBoostPage from "../../pages/communityBoostPage";
import IndexPage from "../../pages/indexPage";
import WhitePaperOEindex from "../../pages/whitepaperPages/whitePaperOEindex";

const AuthenticationLayout = () => (
    <div className={`${Styles.authentication}`}>
        <Header/>
        <Outlet />
        <Footer/>
    </div>
);

const WalletLayout = () => (
    <div className={`${Styles.wallet}`}>
        <WalletHeader isMain={true}/>
        <WalletNavigation/>
        <Outlet />
        <ExternalChat/>
        {/*<AlertModal/>*/}
        <BotSubscribeAlertModal/>
        {/*<HoneechizeAlertModal/>*/}
    </div>
);

const MainLayout = () => (
    <div className={`${Styles.main}`}>
        <MainHeader/>
        <Outlet />
        <MainFooter/>
        <ExternalChat/>
    </div>
);

const WhitePaperLayout = () =>(
    <div className={`${Styles.whitepaper}`}>
        <WalletHeader isMain={false}/>
        <WhitePaperNavigation/>
        <Outlet />
    </div>
);





const App = () => {
    const dispatch = useDispatch();
    const authStatus = useSelector(store => store.main.authorizationStatus);
    const userData = useSelector(store => store.main.userData);

    useEffect(()=>{
        if(authStatus?.authorization){
            dispatch(getUserData())
        }
    },[authStatus, dispatch])

    // useEffect(()=>{
    //     if (userData?.show_notification && !localStorage.getItem('alert-modal-status')){
    //         dispatch(setAlertModalStatus(true));
    //     }
    // },[dispatch, userData])

    // useEffect(()=>{
    //     if (userData?.honeechize_structure && !localStorage.getItem('h-ams')){
    //         dispatch(setHoneechizeAlertModalStatus(true));
    //     }
    // },[dispatch, userData])

    useEffect(()=>{
        if (userData?.is_subscription_expired && !localStorage.getItem('bot-subscribe-alert-modal-status')){
            dispatch(setBotSubscribeAlertModalStatus(true));
        }
    },[dispatch, userData])

    return (
        <section>
            <Routes>
                <Route element={<MainLayout/>}>
                    <Route exact path='/' element={<MainPage/>}/>
                    <Route exact path='/news/page/:page' element={<NewsPage/>}/>
                    <Route exact path='/news/:title' element={<PostPage/>}/>
                    <Route exact path='/privacy-policy' element={<PrivacyPolicyPage/>}/>
                    <Route exact path='/user-agreement' element={<UserAgreementPage/>}/>
                    <Route exact path='/public-presale' element={<PublicPresalePage/>}/>
                </Route>
                <Route element={<AuthenticationLayout/>}>
                    {/*<Route exact path='/' element={<MainPage/>}/>*/}
                    <Route exact path='/sign-up' element={<RegPage/>}/>
                    <Route exact path='/sign-up/ref/:code' element={<RegPage/>}/>
                    <Route exact path='/sign-in' element={<LogPage/>}/>
                    <Route exact path='/confirmation' element={<ConfirmationPage/>}/>
                    <Route exact path='/reset-password' element={<ResetPasswordPage/>}/>
                    <Route exact path='/device-confirmation/:hash' element={<NewDevicePage/>}/>
                </Route>
                <Route element={<WalletLayout/>}>
                    <Route exact path='/home' element={<WalletPage/>}/>
                    <Route exact path='/staking' element={<StakingPage/>}/>
                    <Route exact path='/community-boost' element={<CommunityBoostPage/>}/>
                    <Route exact path='/index' element={<IndexPage/>}/>
                    <Route exact path='/honeeAi' element={<BotPage/>}/>
                    <Route exact path='/bonuses' element={<BonusesPage/>}/>
                    <Route exact path='/settings' element={<SettingsPage/>}/>
                    <Route exact path='/support' element={<SupportPage/>}/>
                    <Route exact path='/exchange' element={<ExchangePage/>}/>
                    <Route exact path='/transactions' element={<TransactionsPage/>}/>
                    <Route exact path='/ambassadors' element={<AmbassadorsPage/>}/>
                    <Route exact path='/honeechize/metrics' element={<HoneechizeMetricsPage/>}/>
                    <Route exact path='/honeechize/bot' element={<HoneechizeBotPage/>}/>
                    <Route exact path='/honeechize/affiliate' element={<HoneechizeAffiliatePage/>}/>
                    {/*<Route exact path='/tickets' element={<TicketsPage/>}/>*/}
                    <Route exact path='/wallet' element={<StandardWalletPage/>}/>
                    <Route exact path='/wallet/:id' element={<StandardCurrentWalletPage/>}/>
                    {/*<Route exact path='/events' element={<EventsPage/>}/>*/}
                    {/*<Route exact path='/events/:id' element={<EventPage/>}/>*/}
                    {userData?.test ? <Route exact path='/test' element={<TestPage/>}/> : null}
                </Route>
                <Route element={<WhitePaperLayout/>}>
                    <Route exact path='/white-paper/executive-summary' element={<WhitePaperExecutiveSummary/>}/>
                    <Route exact path='/white-paper/mission' element={<WhitePaperMission/>}/>
                    <Route exact path='/white-paper/history' element={<WhitePaperHistory/>}/>
                    <Route exact path='/white-paper/growth-strategy' element={<WhitePaperGrowthStrategy/>}/>
                    <Route exact path='/white-paper/community' element={<WhitePaperCommunity/>}/>
                    <Route exact path='/white-paper/market-challenges' element={<WhitePaperMarketChallenges/>}/>
                    <Route exact path='/white-paper/roadmap' element={<WhitePaperRoadmap/>}/>
                    <Route exact path='/white-paper/market-analysis' element={<WhitePaperMarketAnalysis/>}/>
                    <Route exact path='/white-paper/market-analysis/market-status' element={<WhitePaperMAmarketStatus/>}/>
                    <Route exact path='/white-paper/market-analysis/advantages' element={<WhitePaperMAadvantages/>}/>
                    <Route exact path='/white-paper/market-analysis/development-trends' element={<WhitePaperMAdevelopmentTrends/>}/>
                    <Route exact path='/white-paper/market-analysis/forecast' element={<WhitePaperMAforecast/>}/>
                    <Route exact path='/white-paper/omt-ecosystem' element={<WhitePaperOmtEcosystem/>}/>
                    <Route exact path='/white-paper/omt-ecosystem/overview' element={<WhitePaperOEoverview/>}/>
                    <Route exact path='/white-paper/omt-ecosystem/omt-staking' element={<WhitePaperOEomtcStaking/>}/>
                    <Route exact path='/white-paper/omt-ecosystem/staking-of-third-party-crypto' element={<WhitePaperOEstakingTPcrypto/>}/>
                    <Route exact path='/white-paper/omt-ecosystem/honee-ai' element={<WhitePaperOEhoneeAi/>}/>
                    <Route exact path='/white-paper/omt-ecosystem/index-omt20' element={<WhitePaperOEindex/>}/>
                    <Route exact path='/white-paper/tokenomics' element={<WhitePaperTokenomics/>}/>
                    <Route exact path='/white-paper/tokenomics/deflationary-model' element={<WhitePaperTMdeflationaryModel/>}/>
                    <Route exact path='/white-paper/honee-ai' element={<WhitePaperHoneyAi/>}/>
                    <Route exact path='/white-paper/honee-ai/technical-part' element={<WhitePaperHAtechnicalPart/>}/>
                    <Route exact path='/white-paper/honee-ai/analysing' element={<WhitePaperHAanalysing/>}/>
                    <Route exact path='/white-paper/honee-ai/money-flow-index' element={<WhitePaperHAmoneyFlowIndex/>}/>
                    <Route exact path='/white-paper/honee-ai/parabolic-sar' element={<WhitePaperHAparabolicSar/>}/>
                    <Route exact path='/white-paper/honee-ai/method-of-trading' element={<WhitePaperHAmethodOfTrading/>}/>
                    <Route exact path='/white-paper/honee-ai/relative-average-conditions' element={<WhitePaperHArelativeAverageConditions/>}/>
                </Route>
            </Routes>
            <ToastContainer/>
        </section>
    );
};

export default App;