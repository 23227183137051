import React from "react";
import Styles from './styles.module.scss'
import logo from "../../theme/assets/svg/omt_final_logo.svg";
// import nyLogo from '../../theme/assets/svg/omt_ny_logo.svg';
import {useNavigate} from "react-router-dom";


const Footer = () => {
    const navigate = useNavigate();
    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div
                    className={`${Styles.logotypeWrap}`}
                    onClick={()=>{
                        if (!localStorage.getItem('isApp')){
                            navigate('/');
                        }
                    }}
                >
                    <img src={logo} alt="omt logotype"/>
                </div>
            </div>
        </section>
    );
};

export default Footer;
