import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    changeBorRisk,
    changeBorRiskSuccess,
    openBot,
    setBotModalStatus,
    setBotPromoModalStatus, setBotSubscribeModalStatus
} from "../../store/wallet/action";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";


const BotSettings = () => {
    const dispatch = useDispatch();
    const botMainData = useSelector(store => store.wallet.botMainData);
    const customSettings = useSelector(store => store.wallet.customSettings);
    const newChangeRiskType = useSelector(store => store.wallet.newChangeRiskType);
    const newOpenBotStarted = useSelector(store => store.wallet.newOpenBotStarted);
    const newOpenBot = useSelector(store => store.wallet.newOpenBot);



    const { t } = useTranslation();
    

    const [currentRisk, setCurrentRisk] = useState(botMainData?.bot?.next_risk_type ? botMainData?.bot?.next_risk_type : botMainData?.bot?.risk_type ? botMainData?.bot?.risk_type : botMainData?.subscription?.subscription?.name === 'Pro' ? 'default' : 'middle');
    const [sum, setSum] = useState('');
    const [riskPopupStatus, setRiskPopupStatus] = useState(false);


    const riskHandler = (id) =>{
        if (botMainData?.bot){
            dispatch(changeBorRisk(botMainData?.bot?.id, {risk_type: id}));
            setCurrentRisk(id);
        } else {
            // setCurrentRisk(id);
        }
    }

    const  mainBtnHandler = () =>{

        if (sum >= 0.1 && sum <= getAvailableAmount() && !newOpenBotStarted){
            if (!botMainData?.bot){
                dispatch(setBotPromoModalStatus({status: true, balance: Number(sum), sendAction: openBot, botId: botMainData.bot.id, isOpen: true, currentRisk: currentRisk}))
            }
            // dispatch(openBot({amount: Number(sum), risk_type: currentRisk}));
        }
    }

    const percentBarHandler = () =>{
        let currentPercent = ((Number(botMainData?.bot?.turnover).toFixed(2) * 1) / (Number(botMainData?.next_level?.amount).toFixed(2) * 1)) * 100;
        if (currentPercent > 5 && currentPercent < 100){
            return currentPercent
        } else if (currentPercent > 100){
            return 101
        } else {
            return 5
        }
    }


    const toastTextHandler = (text) =>{
        switch (text){
            case 'low':
                return t('Низкий');
            case 'middle':
                return t('Умеренный');
            case 'high':
                return t('Высокий');
            case 'custom':
                return t('Пользовательский');
            case 'default':
                return t('По умолчанию')
            default:
                return ''
        }
    }

    const getAvailableAmount = () => {
        const tempAmount = Math.floor(botMainData?.max_available_amount * 100);
        return Number(Number(tempAmount / 100).toFixed(2));
    }

    const getAvailableBalance = () => {
        const tempAmount = Math.floor(botMainData?.wallet?.amount * 100);
        return Number(Number(tempAmount / 100).toFixed(2));
    }

    useEffect(()=>{
        if (botMainData){
            setCurrentRisk(botMainData?.bot?.next_risk_type ? botMainData?.bot?.next_risk_type : botMainData?.bot?.risk_type ? botMainData?.bot?.risk_type : botMainData?.subscription?.subscription?.name === 'Pro' ? 'default' : 'middle')
        }
    }, [botMainData])
    
    useEffect(()=>{
        if (newChangeRiskType){
            toast(`${t('Ваш новый уровень риска')} - ${toastTextHandler(newChangeRiskType?.next_risk_type)}. ${t('Изменения вступят в силу в начале следующего торгового дня!')}`)
            dispatch(changeBorRiskSuccess(false))
        }
    },[newChangeRiskType]) // eslint-disable-line

    useEffect(()=>{
        if (newOpenBot){
            setSum('')
        }
    },[newOpenBot])



    return (
        <section className={`${Styles.container} ${botMainData?.bot ? Styles.containerActive : Styles.containerPasive}`}>
            <div className={`${Styles.card}`}>
                {botMainData?.subscription && botMainData?.subscription?.status === 'active' ?
                    <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title}`}>{t('Настройки бота')} <br/> ({botMainData?.version})</div>
                        {
                            botMainData?.subscription?.subscription?.name === 'Pro' ?
                                <div
                                    className={`${Styles.settingsBtn} ${Styles.settingsBtnFP} ${botMainData?.bot && customSettings ? Styles.settingsBtnFPActive : null}`}
                                    onClick={()=>{
                                        if (botMainData?.bot && customSettings){
                                            dispatch(setBotModalStatus(true))
                                        }
                                    }}>
                                    {t('Пользовательские настройки')}
                                </div> :
                                <div className={`${Styles.settingsBtn}`} onClick={()=>{dispatch(setBotSubscribeModalStatus(true))}}>
                                    {t('Если вы хотите использовать пользовательские')}<br/>
                                    {t('настройки, ОБНОВИТЕ ДО')} PRO
                                </div>
                        }
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        <div className={`${Styles.levelRow}`}>
                            <div className={`${Styles.botLevelWrap}`}>
                                <div className={`${Styles.botLevelTitle}`}>{t('Уровень бота')}: <span>{botMainData?.bot?.level?.level ? botMainData?.bot?.level?.level : '0'}</span></div>
                                <div className={`${Styles.botLevel}`}>
                                    <div className={`${Styles.botLevelBar}`} style={{width: `${percentBarHandler()}%`}}/>
                                    <div className={`${Styles.botLevelText}`}>
                                        ${botMainData?.bot?.turnover ? Number(botMainData?.bot?.turnover).toFixed(2) * 1 : '0'} {botMainData?.bot?.level?.level === 7 ? '' : `/ $${botMainData?.next_level?.amount ? Number(botMainData?.next_level?.amount).toFixed(2) * 1 : '0'}`}
                                    </div>
                                </div>
                            </div>
                            <div className={`${Styles.botRiskWrap}`}>
                                <div className={`${Styles.botRiskTitle}`}>{t('Уровень риска')}:</div>
                                <div className={`${Styles.botRiskBox} ${botMainData?.subscription?.subscription?.name === 'Pro' ? Styles.botRiskBoxPro : null}`}>
                                    <div className={`${Styles.botRiskAbsNote} ${riskPopupStatus ? Styles.botRiskAbsNoteActive : null}`}>{t('Изменения вступят в силу в начале следующего торгового дня! Потому что ваш бот уже активен!')}</div>
                                    {botMainData?.subscription?.subscription?.name === 'Pro' ?
                                        <>
                                            <div
                                                className={`${Styles.botRiskItem} ${currentRisk === 'default' ? Styles.botRiskItemActive : null} ${Styles.botRiskItemCustom} ${botMainData?.subscription?.subscription?.name === 'Pro' ? Styles.botRiskItemCustomActive : null}`}
                                                onClick={()=>{
                                                    if (botMainData?.subscription?.subscription?.name === 'Pro'){
                                                        riskHandler('default');
                                                    }
                                                }}
                                                onMouseEnter={()=>{setRiskPopupStatus(true)}}
                                                onMouseLeave={()=>{setRiskPopupStatus(false)}}
                                            >
                                                {t('По умолчанию')}
                                            </div>
                                            <div
                                                className={`${Styles.botRiskItem} ${currentRisk === 'custom' ? Styles.botRiskItemActive : null} ${Styles.botRiskItemCustom} ${botMainData?.subscription?.subscription?.name === 'Pro' ? Styles.botRiskItemCustomActive : null}`}
                                                onClick={()=>{
                                                    if (botMainData?.subscription?.subscription?.name === 'Pro'){
                                                        riskHandler('custom');
                                                    }
                                                }}
                                                onMouseEnter={()=>{setRiskPopupStatus(true)}}
                                                onMouseLeave={()=>{setRiskPopupStatus(false)}}
                                            >
                                                {t('Пользовательский')}
                                            </div>
                                        </> :
                                        <>
                                            <div
                                                className={`${Styles.botRiskItem} ${currentRisk === 'low' ? Styles.botRiskItemActive : null}`}
                                                onClick={()=>{riskHandler('low')}}
                                                onMouseEnter={()=>{setRiskPopupStatus(true)}}
                                                onMouseLeave={()=>{setRiskPopupStatus(false)}}
                                            >
                                                {t('Низкий')}
                                            </div>
                                            <div
                                                className={`${Styles.botRiskItem} ${currentRisk === 'middle' ? Styles.botRiskItemActive : null}`}
                                                onClick={()=>{riskHandler('middle')}}
                                                onMouseEnter={()=>{setRiskPopupStatus(true)}}
                                                onMouseLeave={()=>{setRiskPopupStatus(false)}}
                                            >
                                                {t('Умеренный')}
                                            </div>
                                            <div
                                                className={`${Styles.botRiskItem} ${currentRisk === 'high' ? Styles.botRiskItemActive : null}`}
                                                onClick={()=>{riskHandler('high')}}
                                                onMouseEnter={()=>{setRiskPopupStatus(true)}}
                                                onMouseLeave={()=>{setRiskPopupStatus(false)}}
                                            >
                                                {t('Высокий')}
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.startRow}`}>
                            <div className={`${Styles.inputWrap}`}>
                                <div className={`${Styles.inputTitle}`}>{t('Введите сумму')}</div>
                                <div className={`${Styles.inputBox}`}>
                                    <input
                                        disabled={botMainData?.bot}
                                        type="number"
                                        placeholder={`${t('От')} 250 ${(botMainData?.wallet?.amount ? getAvailableAmount() : '0') >= 250 ? `${t('до')} ${botMainData?.wallet?.amount ? getAvailableAmount() : '0'}` : ''}`}
                                        onChange={(e)=>{
                                            if (e.target.value < 0){
                                                setSum(0)
                                            } else if (e.target.value > getAvailableBalance() || e.target.value > getAvailableAmount()){
                                                if (Number(getAvailableBalance()) > Number(getAvailableAmount())){
                                                    setSum(getAvailableAmount());
                                                } else {
                                                    setSum(getAvailableBalance());
                                                }
                                            } else {
                                                setSum(e.target.value)
                                            }
                                        }}
                                        className={`${Styles.inputSum}`}
                                        value={sum}
                                    />
                                    <div className={`${Styles.inputAbs}`}>
                                        <span className={`${Styles.inputAbsCur}`}>Honee</span>
                                        <span className={`${Styles.inputAbsBtn}`} onClick={()=>{
                                            if (getAvailableBalance() > getAvailableAmount()){
                                                setSum(getAvailableAmount());
                                            } else {
                                                setSum(getAvailableBalance());
                                            }
                                        }}>{t('Все')}</span>
                                    </div>
                                    <div className={`${Styles.inputNote}`}>{t('Доступный баланс')}: <span>{botMainData?.wallet?.amount ? getAvailableBalance() : '0'} Honee</span></div>
                                </div>
                            </div>
                            <div className={`${Styles.mainBtn} ${sum >= 0.1 && sum <= getAvailableAmount() ? Styles.mainBtnActive : null}`} onClick={()=>{mainBtnHandler()}}>{botMainData?.bot ? t('В работе') : t('Начать работу')}</div>
                        </div>
                    </div>
                </div> :
                    <div className={`${Styles.cardBody}`}>
                        <div className={`${Styles.subscribeWrap}`}>
                            <div className={`${Styles.title2}`}>{t('Добро пожаловать в HoneeAI')}!</div>
                            <div className={`${Styles.subscribeNoteWrap}`}>
                                <div className={`${Styles.subscribeNoteRow}`}>{botMainData?.subscription ? t('Ваш запрос на покупку подписки успешно отправлен!') : t('Мы рады видеть ваш интерес ĸ HoneeAI!')}</div>
                                <div className={`${Styles.subscribeNoteRow}`}>{botMainData?.subscription ? t('Чтобы начать использовать все возможности нашего бота, дождитесь завершения транзакции.') : t('Чтобы начать пользоваться всеми возможностями нашего бота, пожалуйста, оформите подписĸу.')}</div>
                            </div>
                            {botMainData?.subscription ? null : <div className={`${Styles.btn}`} onClick={()=>{dispatch(setBotSubscribeModalStatus(true))}}>{t('Подписаться')}</div>}
                        </div>
                    </div>
                }
            </div>
        </section>
    );
};

export default BotSettings;
