import React, {useEffect, useState} from "react";
import QRCode from "react-qr-code";
import Styles from './styles.module.scss'
// import CryptoIcon from "../cryptoIcon";
//
// import listArrowIcon from '../../../theme/assets/svg/omt_list_arrow_icon.svg';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createCurrentWallet} from "../../../store/wallet/action";



const WalletTakeStep2 = (props) => {
    // const {currentToken, setCurrentToken, takeWalletNetwork} = props;
    const {currentToken} = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const createdWallet = useSelector(store => store.wallet.createCurrentWallet);

    const [correctWallet, setCorrectWallet] = useState(false);
    const [copyBtnStatus, setCopyBtnStatus] = useState(false);
    
    useEffect(()=>{
        if (createdWallet){
            setCorrectWallet(createdWallet);
        } else {
            if (currentToken?.address){
                setCorrectWallet(currentToken);
            } else {
                dispatch(createCurrentWallet(currentToken?.currency?.id));
            }
        }
    },[createdWallet, currentToken, dispatch])



    return (
        <section className={`${Styles.container}`}>
            {correctWallet ?
                <div className={`${Styles.contentWrap}`}>
                    <div className={`${Styles.title}`}>{`${t('Получить')} ${correctWallet?.currency?.short_name}`}</div>
                    {/*<div className={`${Styles.listWrap} ${Styles.listWrapN}`}>*/}
                    {/*    <div className={`${Styles.listPosWrap} ${Styles.listPosWrapToken}`}>*/}
                    {/*        <div className={`${Styles.listPosWrapTitle}`}>{t('Токен')}</div>*/}
                    {/*        <div className={`${Styles.listBox}`} onClick={()=>{setTokenListStatus(!tokenListStatus)}}>*/}
                    {/*            <div className={`${Styles.listBtn} ${tokenListStatus ? Styles.listBtnActive : null}`}>*/}
                    {/*                <img src={listArrowIcon} alt="list arrow"/>*/}
                    {/*            </div>*/}
                    {/*            <div className={`${Styles.listResult}`}>*/}
                    {/*                <div className={`${Styles.listResultImgWrap}`}>*/}
                    {/*                    <CryptoIcon short_name={currentToken?.currency?.short_name}/>*/}
                    {/*                </div>*/}
                    {/*                <div className={`${Styles.listResultText}`}>{currentToken?.currency?.name}</div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className={`${Styles.abslistWrap} ${tokenListStatus ? Styles.abslistWrapActive : null}`}>*/}
                    {/*            <div className={`${Styles.abslistBorderWrap}`}>*/}
                    {/*                <div className={`${Styles.abslistBox}`}>*/}
                    {/*                    {takeWalletNetwork?.map((item, index)=>{*/}
                    {/*                        return (*/}
                    {/*                            <div*/}
                    {/*                                className={`${Styles.listBoxItem}`}*/}
                    {/*                                key={'walletNetworkToken' + index}*/}
                    {/*                                onClick={()=>{*/}
                    {/*                                    setCurrentToken(item);*/}
                    {/*                                    setTokenListStatus(false);*/}
                    {/*                                }}*/}
                    {/*                            >*/}
                    {/*                                <div className={`${Styles.listBoxItemImgWrap}`}>*/}
                    {/*                                    <CryptoIcon short_name={item.currency?.short_name}/>*/}
                    {/*                                </div>*/}
                    {/*                                <div className={`${Styles.listBoxItemText}`}>{item.currency?.name}</div>*/}
                    {/*                            </div>*/}
                    {/*                        )*/}
                    {/*                    })}*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <div className={`${Styles.addressWrap}`}>
                        <div className={`${Styles.addressBox}`}>
                            <div className={`${Styles.addressTitle}`}>{t('Депозит адрес')}</div>
                            <div className={`${Styles.address}`}><span>{correctWallet?.address}</span></div>
                            <div className={`${Styles.addressNote}`}>{t('На этот адрес можно внести только')} {correctWallet.currency?.short_name}</div>
                            <div
                                className={`${Styles.copyBtn}`}
                                onClick={()=>{
                                    navigator.clipboard.writeText(correctWallet.address);
                                    setCopyBtnStatus(true)
                                }}
                            >
                                {copyBtnStatus ? t('Скопировано') : t('Скопировать')}
                            </div>
                        </div>
                        <div className={`${Styles.qrBox}`}>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={correctWallet.address}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </div>
                    <div className={`${Styles.noteWrap}`}>
                        <div className={`${Styles.text}`}>{t('Минимальная сумма депозита')} - <span className={`${Styles.colored}`}>{correctWallet.min_amount ? correctWallet.min_amount : '0.01'} {correctWallet?.currency?.short_name}</span></div>
                        <div className={`${Styles.text}`}>{t('Необходимое количество подтверждений')} - <span className={`${Styles.colored}`}>{correctWallet.net_accept ? correctWallet.net_accept : '32'} {t('подтверждения сети')}</span></div>
                        <div className={`${Styles.text} ${Styles.colored}`}>
                            {t('Пожалуйста, не вносите на данный адрес никаких других цифровых активов, кроме')} {correctWallet?.currency?.short_name}.
                            {t('Ваш депозит должен быть подтвержден узлами сети. Депозит будет зачислен сразу после')} {correctWallet.net_accept ? correctWallet.net_accept : '32'} {t('подтверждений сети')}.
                        </div>
                    </div>
                </div> :
                <div className={`${Styles.contentWrapLoader}`}>
                    Loading...
                </div>
            }
        </section>
    );
};

export default WalletTakeStep2;
