import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {sendSupportForm, sendSupportFormFailure, sendSupportFormSuccess} from "../../store/wallet/action";
import {toast} from "react-toastify";


const SupportMain = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userData = useSelector(store => store.main.userData);
    const formSuccess = useSelector(store => store.wallet.supportForm);
    const formErr = useSelector(store => store.wallet.supportFormErr);


    const [file, setFile] = useState(null);
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');


    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const sendFormHandler = () =>{
        const formData = new FormData();
        if (file){
            formData.append('file', file)
        }
        formData.append("content", content);
        formData.append("subject", subject);
        dispatch(sendSupportForm(formData));
    }
    
    useEffect(()=>{
        if (formSuccess){
            toast(t('Ваше обращение в службу поддержки успешно отправлено!'));
            setSubject('');
            setContent('');
            setFile(null);
            dispatch(sendSupportFormSuccess(false));
        }
    },[dispatch, formSuccess, t])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.title3}`}>{t('Обращение в службу поддержки')}</div>
                    <div className={`${Styles.inputsWrap}`}>
                        <div className={`${Styles.inputBox} ${formErr?.subject ? Styles.inputWrapWithErr : null}`}>
                            <div className={`${Styles.inputTitle}`}>{t('Тема обращения')}</div>
                            <input
                                type="text"
                                placeholder={t('Пример: Не могу запустить бота')}
                                onChange={(e)=>{setSubject(e.target.value)}}
                                onKeyDown={(event)=>{
                                    if (formErr.subject){
                                        delete formErr.subject;
                                        dispatch(sendSupportFormFailure(formErr));
                                    }
                                }}
                                value={subject}
                            />
                            <div className={`${Styles.inputErr}`}>{formErr?.subject ? formErr.subject : null}</div>
                        </div>
                        <div className={`${Styles.inputBox}`}>
                            <div className={`${Styles.inputTitle}`}>{t('Ваш e-mail')}</div>
                            <div className={`${Styles.fakeInput}`}>{userData?.email}</div>
                        </div>
                    </div>
                    <div className={`${Styles.areaWrap}`}>
                        <div className={`${Styles.areaBox} ${formErr?.content ? Styles.inputWrapWithErr : null}`}>
                            <div className={`${Styles.areaTitle}`}>{t('Опишите подробнее вашу проблему')}</div>
                            <textarea
                                onChange={(e)=>{setContent(e.target.value)}}
                                onKeyDown={(event)=>{
                                    if (formErr.content){
                                        delete formErr.content;
                                        dispatch(sendSupportFormFailure(formErr));
                                    }
                                }}
                                value={content}
                            />
                            <div className={`${Styles.inputErr}`}>{formErr?.content ? formErr.content : null}</div>
                        </div>
                    </div>
                    <div className={`${Styles.fileWrap}`}>
                        <div className={`${Styles.fileBox}`}>
                            <div className={`${Styles.fileTitle}`}>{t('Прикрепите файл (при наличии)')}</div>
                            <div className={`${Styles.posWrap}`}>
                                <div className={`${Styles.fakeInput}`}>{file ? `${file.name}` : t('Файл не выбран')}</div>
                                <label htmlFor="file-upload" className={`${Styles.button} ${Styles.buttonType1}`}>
                                    {t('Выбрать файл')}
                                </label>
                                <input type="file" id="file-upload" onChange={handleFileChange} />
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.button}`} onClick={()=>{sendFormHandler()}}>{t('Отправить')}</div>
                    <div className={`${Styles.textWrap}`}>
                        {t('Для более быстрой обработки вашего обращения, опишите проблемы максимально подробно и при необходимости прикрепите файл')}.
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SupportMain;
