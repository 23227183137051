import React from "react";
import { Helmet } from "react-helmet";



const Seo = (props) => {
    const {title} = props;

    return (
        <Helmet>
            <title>OMT ▶ {title}</title>
        </Helmet>
    );
};

export default Seo;
