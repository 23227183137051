import React from "react";
import Styles from './styles.module.scss'

import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const HoneechizeNavigation = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div
                    className={`${Styles.contentWrapItem} ${window.location.pathname.indexOf('honeechize/metrics') === 1 ? Styles.contentWrapItemActive : null}`}
                    onClick={()=>{navigate('/honeechize/metrics')}}
                >
                    {t('Метрики')}
                </div>
                <div
                    className={`${Styles.contentWrapItem} ${window.location.pathname.indexOf('honeechize/bot') === 1 ? Styles.contentWrapItemActive : null}`}
                    onClick={()=>{navigate('/honeechize/bot')}}
                >
                    {t('Настройки бота')}
                </div>
                <div
                    className={`${Styles.contentWrapItem} ${window.location.pathname.indexOf('honeechize/affiliate') === 1 ? Styles.contentWrapItemActive : null}`}
                    onClick={()=>{navigate('/honeechize/affiliate')}}
                >
                    {t('Настройки партнерской программы')}
                </div>
            </div>
        </section>
    );
};

export default HoneechizeNavigation;
