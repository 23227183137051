import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import {getAvailableWallets, getOmtPeriods, getWalletAssets, getWalletGraphHistory} from "../../store/wallet/action";
import StandardWalletAssets from "../../components/standardWalletAssets";
import WalletTake from "../../components/walletTake";
import WalletSend from "../../components/walletSend";
import WalletMain from "../../components/walletMain";
import WalletCreate from "../../components/walletCreate";
import StandardWalletGraph from "../../components/standardWalletGraph";
import StandardWalletToken from "../../components/standardWalletToken";
import StandardWalletCryptoModal from "../../components/standardWalletCryptoModal";


const StandardWalletPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authStatus = useSelector(store => store.main.authorizationStatus);
    const assets = useSelector(store => store.wallet.availableWallets);
    const currentWalletIndex = useSelector(store => store.wallet.currentWalletIndex);

    const allWalletHistory = useSelector(store => store.wallet.walletGraphHistory);

    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
    const [allGraphWalletData, setAllGraphWalletData] = useState(false);

    const graphDataHandler = (isToken) =>{
        const data = {
            name: '',
            rate: 0,
        }

        if (assets){
            if (isToken){
                data.name = 'OMT'
                assets.forEach((item)=>{
                    if (item.name === 'OMT'){
                        // data.rate = item.currency.rate * 1
                    }
                })
            } else {
                data.name = assets[currentWalletIndex ? currentWalletIndex : 0].currency.name;
                data.rate = assets[currentWalletIndex ? currentWalletIndex : 0].currency.rate * 1;
            }
        }

        return data
    }


    useEffect(()=>{
        if (searchParams.get('app')){
            localStorage.setItem('isApp', true);
        }
    },[searchParams])

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getAvailableWallets());
        dispatch(getWalletAssets());
        dispatch(getOmtPeriods());
        // dispatch(getWalletGraphHistory(3));
    },[dispatch])

    useEffect(()=>{
        if (assets){
            if (currentWalletIndex){
                dispatch(getWalletGraphHistory(assets?.[currentWalletIndex]?.currency?.id));
            } else {
                dispatch(getWalletGraphHistory(assets?.[0]?.currency?.id));
            }
        }
    },[currentWalletIndex, dispatch, assets])

    useEffect(()=>{
        if (allWalletHistory?.length > 0){
            let correctData = {names: [], values: []}
            allWalletHistory.forEach((item)=>{
                correctData.names.push(new Date(item.date).toLocaleString());
                correctData.values.push(Number(item.rate))
            })
            setAllGraphWalletData(correctData)
        } else {
            setAllGraphWalletData(false)
        }
    },[allWalletHistory])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'Wallet'}/>
            <div className={`${Styles.contentWrap}`}>
                <WalletMain posStatus={true}/>
                <StandardWalletGraph isToken={false} data={graphDataHandler(false)} graphData={allGraphWalletData}/>
                <StandardWalletAssets/>
                <StandardWalletToken/>
                <StandardWalletGraph isToken={true} data={graphDataHandler(true)}/>
                <WalletTake/>
                <WalletSend/>
                <WalletCreate/>
                <StandardWalletCryptoModal/>
            </div>
        </section>
    );
};

export default StandardWalletPage;