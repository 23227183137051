import React from "react";
import Slider from "react-slick";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";



const WalletSlider = () => {
    const banners = useSelector(store => store.wallet.banners);

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false
    };

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                {banners?.length > 0 ?
                    <Slider {...settings}>
                        {banners.map((item, index)=>{
                            return (
                                <div
                                    className={`${Styles.slideWrap}`}
                                    key={'banners' + index}
                                    onClick={()=>{window.open(item.url, '_blank');}}
                                >
                                    <img src={item.picture} alt="slide"/>
                                </div>
                            )
                        })}
                    </Slider>
                    : null}
            </div>
        </section>
    );
};

export default WalletSlider;
