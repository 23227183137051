import React, {useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {getAvailableCurrencies, setWalletCreateFormStatus} from "../../store/wallet/action";
import WalletChoice from "../_UI/walletChoice";
import WalletCreateStep1 from "../_UI/walletCreateStep1";
import WalletCreateStep2 from "../_UI/walletCreateStep2";
import WalletImportStep1 from "../_UI/walletImportStep1";


const WalletCreate = () => {
    const dispatch = useDispatch();
    const createFormStatus = useSelector(store => store.wallet.createFormStatus);
    const availableCurrencies = useSelector(store => store.wallet.createWalletCurrencies);

    const [contentType, setContentType] = useState({type: 'init', step: null});
    const [phrase, setPhrase] = useState('');
    const [currentNet, setCurrentNet] = useState(availableCurrencies?.[0] ? availableCurrencies[0] : null)

    const closeModal = () =>{
        dispatch(setWalletCreateFormStatus(false));
        setContentType({type: 'init', step: null});
    }

    const contentTypeHandler = (type) =>{
        setContentType(type);
    }
    
    useEffect(()=>{
        dispatch(getAvailableCurrencies());
    },[dispatch])

    useEffect(()=>{
        if (availableCurrencies){
            setCurrentNet(availableCurrencies[0])
        }
    },[availableCurrencies])

    return (
        <Popup
            open={createFormStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    {contentType.type === 'init' ? <WalletChoice contentTypeHandler={contentTypeHandler} availableCurrencies={availableCurrencies}/> : null}
                    {contentType.type === 'create' && contentType.step === 1 ? <WalletCreateStep1 contentTypeHandler={contentTypeHandler} currentNet={currentNet} setCurrentNet={setCurrentNet} walletNetwork={availableCurrencies}/> : null}
                    {contentType.type === 'create' && contentType.step === 2 ? <WalletCreateStep2 closeModal={closeModal} currentNet={currentNet} type='create'/> : null}
                    {contentType.type === 'import' && contentType.step === 1 ? <WalletImportStep1 contentTypeHandler={contentTypeHandler} phrase={phrase} setPhrase={setPhrase}/> : null}
                    {contentType.type === 'import' && contentType.step === 2 ? <WalletCreateStep2 closeModal={closeModal} phrase={phrase} type='import'/> : null}
                </div>
            </div>
        </Popup>
    );
};

export default WalletCreate;
