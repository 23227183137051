import {
    TEST,
    SET_AUTHORIZATION_STATUS,
    SET_MOBILE_NAV_STATUS,
    SET_MOBILE_WP_NAV_STATUS,
    SET_CONFIRMATION_STEP,
    REGISTRATION_STARTED,
    REGISTRATION_SUCCESS,
    REGISTRATION_FAILURE,
    LOGIN_STARTED,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_STARTED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    GET_PHRASE_STARTED,
    GET_PHRASE_SUCCESS,
    GET_PHRASE_FAILURE,
    SET_PHRASE_STARTED,
    SET_PHRASE_SUCCESS,
    SET_PHRASE_FAILURE,
    GET_USER_DATA_STARTED,
    GET_USER_DATA_SUCCESS,
    GET_USER_DATA_FAILURE,
    CONFIRMATION_CLEAR,
    GET_LATEST_NEWS_STARTED,
    GET_LATEST_NEWS_SUCCESS,
    GET_LATEST_NEWS_FAILURE,
    GET_MAIN_NEWS_STARTED,
    GET_MAIN_NEWS_SUCCESS,
    GET_MAIN_NEWS_FAILURE,
    GET_POST_STARTED,
    GET_POST_SUCCESS,
    GET_POST_FAILURE,
    GET_TOP_NEWS_STARTED,
    GET_TOP_NEWS_SUCCESS,
    GET_TOP_NEWS_FAILURE,
    SET_LINK_SCROLL_STATUS,
    GET_PRESALE_DATA_STARTED,
    GET_PRESALE_DATA_SUCCESS,
    GET_PRESALE_DATA_FAILURE,
    RESET_PASSWORD_STARTED,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    SET_NEW_DEVICE_STATUS,
    LOGIN_WITH_HASH_STARTED,
    LOGIN_WITH_HASH_SUCCESS,
    LOGIN_WITH_HASH_FAILURE,
    SET_HASH_STATUS
} from "./types";

import newsImg1 from '../../theme/assets/other/omt_news_img1.png';
import newsImg2 from '../../theme/assets/other/omt_news_img2.png';
import newsImg3 from '../../theme/assets/other/omt_news_img3.png';




const initialState = {
    authorizationStatus: {authorization: !!localStorage.getItem('omt-token'), phrase: !!localStorage.getItem('omt-phrase-status')},
    confirmationStep: localStorage.getItem('omt-step') ? localStorage.getItem('omt-step') : false,
    newDeviceStatus: false,
    hashIsNotAlive: false,
    linkScrollStatus: false,
    mobileNavStatus: false,
    mobileWpNavStatus: false,
    registrationStarted: false,
    registration: false,
    registrationErr: false,
    loginStarted: false,
    login: false,
    loginErr: false,
    loginWithHashStarted: false,
    loginWithHash: false,
    loginWithHashErr: false,
    resetPasswordStatusStarted: false,
    resetPasswordStatus: false,
    resetPasswordStatusErr: false,
    logoutStarted: false,
    logout: false,
    logoutErr: false,
    getPhraseStarted: false,
    getPhrase: false,
    getPhraseErr: false,
    setPhraseStarted: false,
    setPhrase: false,
    setPhraseErr: false,
    userDataStarted: false,
    userData: false,
    userDataErr: false,
    latestNewsStarted: false,
    latestNews: false,
    latestNewsErr: false,
    mainNewsStarted: false,
    mainNews: false,
    mainNewsErr: false,
    postStarted: false,
    post: false,
    postErr: false,
    topNewsStarted: false,
    topNews: false,
    topNewsErr: false,
    news: [
        {img: newsImg1, title: 'Most popular design systems to learn from in 2022', category: 'Design Systems'},
        {img: newsImg2, title: 'Understanding accessibility makes you a better', category: 'Accessibility'},
        {img: newsImg3, title: '15 best tools that will help you build your website', category: 'Tech'},
    ],
    preSaleDataStarted: false,
    preSaleData: false,
    preSaleDataErr: false,
    // latestNews: [
    //     {id: 1, img: newsImg1, date: '15 Jan 2022', read_time: 2, title: 'Crypto market', description: 'How do you create compelling presentations that wow your colleagues and impress your managers?', category: 'Design Systems'},
    //     {id: 2, img: newsImg1, date: '15 Jan 2022', read_time: 2, title: 'Crypto market', description: 'How do you create compelling presentations that wow your colleagues and impress your managers?', category: 'Design Systems'},
    //     {id: 3, img: newsImg1, date: '15 Jan 2022', read_time: 2, title: 'Crypto market', description: 'How do you create compelling presentations that wow your colleagues and impress your managers?', category: 'Design Systems'},
    // ],
    // mainNews: [
    //     {id: 4, img: newsImg1, date: '15 Jan 2022', read_time: 2, title: 'Crypto market', description: 'How do you create compelling presentations that wow your colleagues and impress your managers?', category: 'Design Systems'},
    //     {id: 5, img: newsImg1, date: '15 Jan 2022', read_time: 2, title: 'Crypto market', description: 'How do you create compelling presentations that wow your colleagues and impress your managers?', category: 'Design Systems'},
    //     {id: 6, img: newsImg1, date: '15 Jan 2022', read_time: 2, title: 'Crypto market', description: 'How do you create compelling presentations that wow your colleagues and impress your managers?', category: 'Design Systems'},
    //     {id: 7, img: newsImg1, date: '15 Jan 2022', read_time: 2, title: 'Crypto market', description: 'How do you create compelling presentations that wow your colleagues and impress your managers?', category: 'Design Systems'},
    //     {id: 8, img: newsImg1, date: '15 Jan 2022', read_time: 2, title: 'Crypto market', description: 'How do you create compelling presentations that wow your colleagues and impress your managers?', category: 'Design Systems'},
    //     {id: 9, img: newsImg1, date: '15 Jan 2022', read_time: 2, title: 'Crypto market', description: 'How do you create compelling presentations that wow your colleagues and impress your managers?', category: 'Design Systems'},
    // ],
    mostReadNews: [
        {id: 4, img: newsImg1, date: '15 Jan 2022', read_time: 2, title: 'Crypto market', description: 'How do you create compelling presentations that wow your colleagues and impress your managers?', category: 'Design Systems'},
        {id: 5, img: newsImg1, date: '15 Jan 2022', read_time: 2, title: 'Crypto market', description: 'How do you create compelling presentations that wow your colleagues and impress your managers?', category: 'Design Systems'},
        {id: 6, img: newsImg1, date: '15 Jan 2022', read_time: 2, title: 'Crypto market', description: 'How do you create compelling presentations that wow your colleagues and impress your managers?', category: 'Design Systems'},
        {id: 7, img: newsImg1, date: '15 Jan 2022', read_time: 2, title: 'Crypto market', description: 'How do you create compelling presentations that wow your colleagues and impress your managers?', category: 'Design Systems'},
    ]
};

export function mainReducer(state = initialState, action) {
    switch (action.type) {
        case TEST:
            return {
                ...state,
                status: action.payload
            };
        case CONFIRMATION_CLEAR:
            return {
                ...state,
                confirmationStep: false,
                getPhrase: false,
                getPhraseErr: false,
                setPhrase: false,
                setPhraseErr: false
            };
        case SET_MOBILE_NAV_STATUS:
            return {
                ...state,
                mobileNavStatus: action.payload
            };
        case SET_NEW_DEVICE_STATUS:
            return {
                ...state,
                newDeviceStatus: action.payload
            };
        case SET_HASH_STATUS:
            return {
                ...state,
                hashIsNotAlive: action.payload
            };
        case SET_MOBILE_WP_NAV_STATUS:
            return {
                ...state,
                mobileWpNavStatus: action.payload
            };
        case SET_AUTHORIZATION_STATUS:
            return {
                ...state,
                authorizationStatus: action.payload
            };
        case SET_CONFIRMATION_STEP:
            return {
                ...state,
                confirmationStep: action.payload
            };
        case REGISTRATION_STARTED:
            return {
                ...state,
                registrationStarted: true
            };
        case REGISTRATION_SUCCESS:
            return {
                ...state,
                registration: action.payload,
                registrationStarted: false
            };
        case REGISTRATION_FAILURE:
            return {
                ...state,
                registrationErr: action.payload,
                registrationStarted: false
            };
        case LOGIN_STARTED:
            return {
                ...state,
                loginStarted: true
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: action.payload,
                loginStarted: false
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                loginErr: action.payload,
                loginStarted: false
            };
        case LOGIN_WITH_HASH_STARTED:
            return {
                ...state,
                loginWithHashStarted: true
            };
        case LOGIN_WITH_HASH_SUCCESS:
            return {
                ...state,
                loginWithHash: action.payload,
                loginWithHashStarted: false
            };
        case LOGIN_WITH_HASH_FAILURE:
            return {
                ...state,
                loginWithHashErr: action.payload,
                loginWithHashStarted: false
            };
        case LOGOUT_STARTED:
            return {
                ...state,
                logoutStarted: true
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                logout: action.payload,
                logoutStarted: false
            };
        case LOGOUT_FAILURE:
            return {
                ...state,
                logoutErr: action.payload,
                logoutStarted: false
            };
        case GET_PHRASE_STARTED:
            return {
                ...state,
                getPhraseStarted: true
            };
        case GET_PHRASE_SUCCESS:
            return {
                ...state,
                getPhrase: action.payload,
                getPhraseStarted: false
            };
        case GET_PHRASE_FAILURE:
            return {
                ...state,
                getPhraseErr: action.payload,
                getPhraseStarted: false
            };
        case SET_PHRASE_STARTED:
            return {
                ...state,
                setPhraseStarted: true
            };
        case SET_PHRASE_SUCCESS:
            return {
                ...state,
                setPhrase: action.payload,
                setPhraseStarted: false
            };
        case SET_PHRASE_FAILURE:
            return {
                ...state,
                setPhraseErr: action.payload,
                setPhraseStarted: false
            };
        case GET_USER_DATA_STARTED:
            return {
                ...state,
                userDataStarted: true
            };
        case GET_USER_DATA_SUCCESS:
            return {
                ...state,
                userData: action.payload,
                userDataStarted: false
            };
        case GET_USER_DATA_FAILURE:
            return {
                ...state,
                userDataErr: action.payload,
                userDataStarted: false
            };
        case GET_LATEST_NEWS_STARTED:
            return {
                ...state,
                latestNewsStarted: true
            };
        case GET_LATEST_NEWS_SUCCESS:
            return {
                ...state,
                latestNews: action.payload,
                latestNewsStarted: false
            };
        case GET_LATEST_NEWS_FAILURE:
            return {
                ...state,
                latestNewsErr: action.payload,
                latestNewsStarted: false
            };
        case GET_MAIN_NEWS_STARTED:
            return {
                ...state,
                mainNewsStarted: true
            };
        case GET_MAIN_NEWS_SUCCESS:
            return {
                ...state,
                mainNews: action.payload,
                mainNewsStarted: false
            };
        case GET_MAIN_NEWS_FAILURE:
            return {
                ...state,
                mainNewsErr: action.payload,
                mainNewsStarted: false
            };
        case GET_POST_STARTED:
            return {
                ...state,
                postStarted: true
            };
        case GET_POST_SUCCESS:
            return {
                ...state,
                post: action.payload,
                postStarted: false
            };
        case GET_POST_FAILURE:
            return {
                ...state,
                postErr: action.payload,
                postStarted: false
            };
        case GET_TOP_NEWS_STARTED:
            return {
                ...state,
                topNewsStarted: true
            };
        case GET_TOP_NEWS_SUCCESS:
            return {
                ...state,
                topNews: action.payload,
                topNewsStarted: false
            };
        case GET_TOP_NEWS_FAILURE:
            return {
                ...state,
                topNewsErr: action.payload,
                topNewsStarted: false
            };
        case SET_LINK_SCROLL_STATUS:
            return {
                ...state,
                linkScrollStatus: action.payload
            };
        case GET_PRESALE_DATA_STARTED:
            return {
                ...state,
                preSaleDataStarted: true
            };
        case GET_PRESALE_DATA_SUCCESS:
            return {
                ...state,
                preSaleData: action.payload,
                preSaleDataStarted: false
            };
        case GET_PRESALE_DATA_FAILURE:
            return {
                ...state,
                preSaleDataErr: action.payload,
                preSaleDataStarted: false
            };
        case RESET_PASSWORD_STARTED:
            return {
                ...state,
                resetPasswordStatusStarted: true
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordStatus: action.payload,
                resetPasswordStatusStarted: false
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetPasswordStatusErr: action.payload,
                resetPasswordStatusStarted: false
            };
        default:
            return state;
    }
}

