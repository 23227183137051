import React from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import influencer_ticket from "../../theme/assets/other/influencer_ticket.png";
import {setInfluencerModalStatus} from "../../store/wallet/action";

// import omtIcon from '../../theme/assets/svg/omt_token_border_icon.svg';
// import honeeIcon from '../../theme/assets/crypto/omt_crypto_honee_icon.svg';
// import {setAmbassadorsModalStatus} from "../../store/wallet/action";

const AmbassadorsInfluencer = () => {
    const dispatch = useDispatch();
    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.titleRow}`}>
                    <div className={`${Styles.title}`}>Marketing program allocation</div>
                </div>
                <div className={`${Styles.mainTaskWrap}`}>
                    <div className={`${Styles.subtitle}`}>Ticket</div>
                    <div className={`${Styles.ticketblock}`}>
                        <img className={`${Styles.ticket_img}`} src={`${influencer_ticket}`} alt=""/>
                        <button className={`${Styles.subtitle}`} onClick={()=> {dispatch(setInfluencerModalStatus(true))}}>Buy ticket</button>
                    </div>
                    <div className={`${Styles.subtitle}`}>Congratulations!</div>

                    <div className={`${Styles.description}`}>
                        <p>
                            You received a ticket for allocation, you have the opportunity to redeem it within <span>24 hours</span>.
                        </p>
                        <p>
                            You need to have the amount of your ticket on the wallet after the purchase will automatically accrue tokens.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
};
export default AmbassadorsInfluencer;