import React, {useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {setBotModalStatus, setCustomSettings} from "../../store/wallet/action";
import backIcon from "../../theme/assets/svg/omt_btn_back_arrow.svg";

import head1 from '../../theme/assets/bee/omt_honee_head1.svg';
import head2 from '../../theme/assets/bee/omt_honee_head2.svg';
import head3 from '../../theme/assets/bee/omt_honee_head3.svg';

import body1 from '../../theme/assets/bee/omt_honee_body1.svg';
import body2 from '../../theme/assets/bee/omt_honee_body2.svg';
import body3 from '../../theme/assets/bee/omt_honee_body3.svg';

import wings1 from '../../theme/assets/bee/omt_honee_wings1.svg';
import wings2 from '../../theme/assets/bee/omt_honee_wings2.svg';
import wings3 from '../../theme/assets/bee/omt_honee_wings3.svg';

import sting1 from '../../theme/assets/bee/omt_honee_sting1.svg';
import sting2 from '../../theme/assets/bee/omt_honee_sting2.svg';
import sting3 from '../../theme/assets/bee/omt_honee_sting3.svg';

import legs1 from '../../theme/assets/bee/omt_honee_legs1.svg';
import legs2 from '../../theme/assets/bee/omt_honee_legs2.svg';
import legs3 from '../../theme/assets/bee/omt_honee_legs3.svg';
import {useTranslation} from "react-i18next";



const BotModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const botMainData = useSelector(store => store.wallet.botMainData);
    const botModalStatus = useSelector(store => store.wallet.botModalStatus);
    const customSettings = useSelector(store => store.wallet.customSettings);
    const userData = useSelector(store => store.main.userData);

    const indexDetected = (obj) =>{
        let defaultValue = 0;
        obj.options.forEach((item, index)=>{
            if (item.id === obj.current_option){
                defaultValue = index
            }
        })

        return defaultValue
    }

    const settingsIndexDetected = (setId) =>{
        let defaultValue = 0;
        customSettings.forEach((item, index)=>{
            if (item.id === setId){
                defaultValue = index
            }
        })

        return String(defaultValue)
    }

    const [setting1, setSetting1] = useState(customSettings ? indexDetected(customSettings[0]) : 0);
    const [setting2, setSetting2] = useState(customSettings ? indexDetected(customSettings[1]) : 0);
    const [setting3, setSetting3] = useState(customSettings ? indexDetected(customSettings[2]) : 0);
    const [setting4, setSetting4] = useState(customSettings ? indexDetected(customSettings[3]) : 0);
    const [setting5, setSetting5] = useState(customSettings ? indexDetected(customSettings[4]) : 0);

    const [dropStatus, setDropStatus] = useState(false);


    const imgData = [
        [head1, head2, head3],
        [body1, body2, body3],
        [wings1, wings2, wings3],
        [sting1, sting2, sting3],
        [legs1, legs2, legs3]
    ]

    const closeModal = () =>{
        dispatch(setBotModalStatus(false))
    }

    const showCurrentChoice = (index) =>{
        switch (index){
            case '0':
                return setting1
            case '1':
                return setting2
            case '2':
                return setting3
            case '3':
                return setting4
            case '4':
                return setting5
            default:
                return setting5
        }
    }

    const riskShowHandler = (action, index) =>{

        const settingChoice = () =>{
                switch (dropStatus){
                    case '0':
                        return setSetting1
                    case '1':
                        return setSetting2
                    case '2':
                        return setSetting3
                    case '3':
                        return setSetting4
                    case '4':
                        return setSetting5
                    default:
                        return setSetting5
                }
        }
        if (dropStatus){
            if (action === 'enter'){
                settingChoice()(index);
            }

            if (action === 'leave'){
                settingChoice()(indexDetected(customSettings[index]))
            }

            if (action === 'click'){
                dispatch(setCustomSettings(botMainData?.bot?.id, {bot_custom_setting_id: customSettings[dropStatus]?.id, bot_custom_setting_option_id: customSettings[dropStatus]?.options[index]?.id}))
                setDropStatus(false);
            }
        }
    }

    useEffect(()=>{
        if (customSettings){
            setSetting1(indexDetected(customSettings[0]));
            setSetting2(indexDetected(customSettings[1]));
            setSetting3(indexDetected(customSettings[2]));
            setSetting4(indexDetected(customSettings[3]));
            setSetting5(indexDetected(customSettings[4]));
        }
    },[customSettings])

    return (
        <Popup
            open={botModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.title}`}>{t('Пользовательские настройки')}</div>
                    <div className={`${Styles.modalContentBox}`}>
                        <div className={`${Styles.settingsWrap}`}>
                            {dropStatus ?
                                <div className={`${Styles.dropBox}`}>
                                    <div className={`${Styles.dropTitle}`} onClick={()=>{setDropStatus(false)}}>
                                        <div className={`${Styles.dropTitleBack}`}>
                                            <div className={`${Styles.dropTitleBackImgWrap}`}>
                                                <img src={backIcon} alt="back arrow"/>
                                            </div>
                                            <div className={`${Styles.dropTitleBackNote}`}>{t('Назад')}</div>
                                        </div>
                                        {userData ? <div className={`${Styles.dropTitleArrow}`}>V</div> : null}
                                    </div>
                                    <div
                                        className={`${Styles.dropItem} ${Styles.green}`}
                                        onClick={()=>{riskShowHandler('click', 0)}}
                                        onMouseEnter={()=>{riskShowHandler('enter', 0)}}
                                        onMouseLeave={()=>{riskShowHandler('leave', 0)}}
                                    >
                                        {customSettings?.[dropStatus]?.options?.[0].name}
                                    </div>
                                    <div
                                        className={`${Styles.dropItem} ${Styles.orange}`}
                                        onClick={()=>{riskShowHandler('click', 1)}}
                                        onMouseEnter={()=>{riskShowHandler('enter', 1)}}
                                        onMouseLeave={()=>{riskShowHandler('leave', 1)}}
                                    >
                                        {customSettings?.[dropStatus]?.options?.[1].name}
                                    </div>
                                    <div
                                        className={`${Styles.dropItem} ${Styles.red}`}
                                        onClick={()=>{riskShowHandler('click', 2)}}
                                        onMouseEnter={()=>{riskShowHandler('enter', 2)}}
                                        onMouseLeave={()=>{riskShowHandler('leave', 2)}}
                                    >
                                        {customSettings?.[dropStatus]?.options?.[2].name}
                                    </div>
                                </div> :
                                <div className={`${Styles.settingsBox}`}>
                                    {customSettings ? customSettings.map((item, index)=>{
                                        return (
                                            <div
                                                className={`${Styles.settingsItem}`}
                                                key={'bee_settings_item' + index}
                                                onClick={()=>{
                                                    if (userData){
                                                        setDropStatus(settingsIndexDetected(item.id));
                                                    }
                                                }}
                                            >
                                                <div className={`${Styles.settingsItemNote}`}>{item.name} ({item.options[showCurrentChoice(String(index))].name})</div>
                                                {userData ? <div className={`${Styles.settingsItemArrow}`}>V</div> : null}
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            }
                        </div>
                        <div className={`${Styles.beeWrap}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={imgData[0][setting1]} alt="bee head"/>
                                <img src={imgData[1][setting2]} alt="bee body"/>
                                <img src={imgData[2][setting3]} alt="bee wings"/>
                                <img src={imgData[3][setting4]} alt="bee sting"/>
                                <img src={imgData[4][setting5]} alt="bee legs"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default BotModal;
