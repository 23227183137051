import React from "react";
import Styles from './styles.module.scss'
import logo from "../../theme/assets/svg/omt_final_logo.svg";
// import nyLogo from '../../theme/assets/svg/omt_ny_logo.svg';
import {useDispatch, useSelector} from "react-redux";
import {setMobileNavStatus, setMobileWpNavStatus} from "../../store/main/action";
import {useNavigate} from "react-router-dom";



const WalletHeader = (props) => {
    const {isMain} = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mobileNavStatus = useSelector(store => store.main.mobileNavStatus);
    const mobileWpNavStatus = useSelector(store => store.main.mobileWpNavStatus);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div
                    className={`${Styles.navBtn} ${isMain ? mobileNavStatus ? Styles.navBtnActive : null : mobileWpNavStatus ? Styles.navBtnActive : null}`}
                    onClick={()=>{
                        if (isMain){
                            dispatch(setMobileNavStatus(!mobileNavStatus));
                        } else {
                            dispatch(setMobileWpNavStatus(!mobileWpNavStatus));
                        }
                    }}
                >
                    <div className={`${Styles.navBtnItem}`}/>
                    <div className={`${Styles.navBtnItem}`}/>
                    <div className={`${Styles.navBtnItem}`}/>
                </div>
                <div className={`${Styles.logoWrap}`} onClick={()=>{navigate('/')}}>
                    <img src={logo} alt="omt logotype"/>
                </div>
            </div>
        </section>
    );
};

export default WalletHeader;
