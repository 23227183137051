import React from "react";
import Styles from './styles.module.scss';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const PublicPresaleInfo = () => {
    const preSaleData = useSelector(store => store.main.preSaleData);
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentRow}`}>
                        <div className={`${Styles.contentRowTitle}`}>{t('Распределение')}</div>
                        <div className={`${Styles.contentRowValue}`}>{preSaleData?.allocation?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</div>
                    </div>
                    <div className={`${Styles.contentRow}`}>
                        <div className={`${Styles.contentRowTitle}`}>{t('Остаток')}</div>
                        <div className={`${Styles.contentRowValue}`}>{preSaleData?.reminders?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</div>
                    </div>
                    <div className={`${Styles.contentRow}`}>
                        <div className={`${Styles.contentRowTitle}`}>{t('Продано')}</div>
                        <div className={`${Styles.contentRowValue}`}>{preSaleData?.sold?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</div>
                    </div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentRow}`}>
                        <div className={`${Styles.contentRowTitle}`}>{t('Цена')}</div>
                        <div className={`${Styles.contentRowValue} ${Styles.contentRowValueColored}`}>~0.016 USDT</div>
                    </div>
                    <div className={`${Styles.positionWrap}`}>
                        <div className={`${Styles.contentRow}`}>
                            <div className={`${Styles.contentRowTitle}`}>{t('Дата начала')}</div>
                            <div className={`${Styles.contentRowValue} ${Styles.contentRowValueColored}`}>{preSaleData?.starts?.replace( /-/g, "." )}</div>
                        </div>
                        <div className={`${Styles.contentRow}`}>
                            <div className={`${Styles.contentRowTitle}`}>{t('Дата окончания')}</div>
                            <div className={`${Styles.contentRowValue} ${Styles.contentRowValueColored}`}>{preSaleData?.ends?.replace( /-/g, "." )}</div>
                        </div>
                    </div>
                    <div className={`${Styles.mainBtn}`} onClick={()=>{navigate('/tickets')}}>{t('Купить')}</div>
                </div>
            </div>
        </section>
    )
}

export default PublicPresaleInfo;