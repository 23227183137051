import React from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";

import EmptyValues from "../_UI/emtyValues";
import Moment from 'react-moment';

import {useTranslation} from "react-i18next";
import HistoryIcon from "../_UI/historyIcon";


const WalletHistory = () => {
    const walletTransactions = useSelector(store => store.wallet.walletTransactions);
    const { t } = useTranslation();

    const historyContentHandler = (type) =>{
        switch (type){
            case 'bot':
                return {text: t('Бот'), class: 'colorViolet'}
            case 'staking':
                return {text: t('Стейкинг'), class: 'colorBlue'}
            case 'omt_staking':
                return {text: t('Стейкинг'), class: 'colorBlue'}
            case 'staking_profit':
                return {text: t('Заработоĸ'), class: 'colorGreen'}
            case 'profit_staking':
                return {text: t('Заработоĸ'), class: 'colorGreen'}
            case 'salary':
                return {text: t('Заработоĸ'), class: 'colorGreen'}
            case 'omt_staking_award':
                return {text: t('Заработоĸ'), class: 'colorGreen'}
            case 'receive':
                return {text: t('Пополнение'), class: 'colorGreen'}
            case 'sent':
                return {text: t('Вывод'), class: 'colorViolet'}
            case 'transaction':
                return {text: t('Обмен'), class: 'colorOrange'}
            case 'simple':
                return {text: t('Трансфер'), class: 'colorViolet'}
            case 'buy_honey':
                return {text: t('Обмен'), class: 'colorOrange'}
            case 'sell_honey':
                return {text: t('Обмен'), class: 'colorOrange'}
            case 'buy_ticket':
                return {text: t('Покупка билета'), class: 'colorViolet'}
            case 'bot_subscription':
                return {text: t('Подписка'), class: 'colorViolet'}
            default:
                return {text: t('Неизвестно'), class: 'colorWhite'}
        }
    }

    const historyIconColorHandler = (customClass) =>{
        switch (customClass){
            case 'colorGreen':
                return '#03B759';
            case 'colorOrange':
                return '#F7931A';
            case 'colorViolet':
                return '#b57edc';
            case 'colorBlue':
                return '#58c1ef';
            default:
                return '#F5F5F5'
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBodyWithScroll}`}>
                    <div className={`${Styles.title3}`}>{t('Последние транзакции')}</div>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Тип операции')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Дата и время')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Код операции')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Сумма')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        {walletTransactions?.data?.length > 0 ? walletTransactions.data.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem} ${Styles[historyContentHandler(item.type).class]}`} key={'walletHistory' + index}>
                                    <div className={`${Styles.contentBoxItemNameWrap}`}>
                                        <div className={`${Styles.contentBoxItemNameImgWrap}`}>
                                            <HistoryIcon type={item.type} color={historyIconColorHandler(historyContentHandler(item.type).class)}/>
                                        </div>
                                        <div className={`${Styles.contentBoxItemNameBox}`}>
                                            {historyContentHandler(item.type).text}
                                        </div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemValues}`}><Moment format="DD/MM/YYYY HH:mm">{item.date}</Moment></div>
                                    <div className={`${Styles.contentBoxItemValues}`}>{`${item.hash.slice(0, 5)}...${item.hash.slice(item.hash.length - 5, item.hash.length)}`}</div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemValuesSpecial}`}>
                                        {Number(item.amount).toFixed(6) * 1} {item?.currency?.short_name ? item?.currency?.short_name : item?.currency?.name}
                                    </div>
                                </div>
                            )
                        }) : <EmptyValues text={t('У вас пока что нет транзакций')} withIcon={false} withBtn={false}/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WalletHistory;
