import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss';
import {toast} from "react-toastify";

import wallet_bg_pic1_type1 from '../../../theme/assets/other/omt_wallet_bg_pic1_type1.png';
import wallet_bg_pic1_type2 from '../../../theme/assets/other/omt_wallet_bg_pic1_type2.png';
import wallet_bg_pic1_type3 from '../../../theme/assets/other/omt_wallet_bg_pic1_type3.png';
import wallet_bg_pic2_type1 from '../../../theme/assets/other/omt_wallet_bg_pic2_type1.png';
import wallet_bg_pic2_type2 from '../../../theme/assets/other/omt_wallet_bg_pic2_type2.png';
import wallet_bg_pic2_type3 from '../../../theme/assets/other/omt_wallet_bg_pic2_type3.png';
import wallet_bg_pic3_type1 from '../../../theme/assets/other/omt_wallet_bg_pic3_type1.png';
import wallet_bg_pic3_type2 from '../../../theme/assets/other/omt_wallet_bg_pic3_type2.png';
import wallet_bg_pic3_type3 from '../../../theme/assets/other/omt_wallet_bg_pic3_type3.png';
import {useDispatch, useSelector} from "react-redux";
import {createNewWallet, createWalletFailure, createWalletSuccess} from "../../../store/wallet/action";
import {useTranslation} from "react-i18next";



const WalletCreateStep2 = (props) => {
    const {type, closeModal, phrase, currentNet} = props;
    const dispatch = useDispatch();
    const createWallet = useSelector(store => store.wallet.createWallet);
    const createWalletErr = useSelector(store => store.wallet.createWalletErr);

    const { t } = useTranslation();

    const pictures = [
        [wallet_bg_pic1_type1, wallet_bg_pic1_type2, wallet_bg_pic1_type3],
        [wallet_bg_pic2_type1, wallet_bg_pic2_type2, wallet_bg_pic2_type3],
        [wallet_bg_pic3_type1, wallet_bg_pic3_type2, wallet_bg_pic3_type3]
    ]

    const [pictureStatus, setPictureStatus] = useState(1);
    const [typeStatus, setTypeStatus] = useState(1);
    const [walletName, setWalletName] = useState('');

    const mainBtnHandler = () =>{
        if (type === 'create'){
            dispatch(createNewWallet({net: currentNet, wallet_picture: pictureStatus, wallet_picture_type: typeStatus, name: walletName}));
        } else {
            console.log({phrase: phrase, wallet_picture: pictureStatus, wallet_picture_type: typeStatus});
            closeModal();
            toast(t('Поздравляем! Ваш кошелек успешно импортирован!'));
        }
    }
    
    useEffect(()=>{
        dispatch(createWalletSuccess(false));

        if (createWallet){
            closeModal();
            toast(t('Поздравляем! Ваш кошелек успешно создан!'));
        }
    },[closeModal, createWallet, dispatch, t])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title}`}>{type === 'create' ? t('Создание кошелька') : t('Импорт кошелька')}</div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.previewWrap}`}>
                        <img src={pictures[pictureStatus - 1][typeStatus - 1]} alt="wallet bg"/>
                    </div>
                    <div className={`${Styles.navigationWrap}`}>
                        <div className={`${Styles.navigationRow}`}>
                            <div className={`${Styles.navigationRowTitle}`}>{t('Название')}</div>
                            <input
                                className={`${createWalletErr?.name ? Styles.inputWithErr : null}`}
                                type="text"
                                value={walletName}
                                onChange={(e)=>{setWalletName(e.target.value)}}
                                onKeyDown={(event)=>{
                                    if (createWalletErr.name){
                                        delete createWalletErr.name;
                                        dispatch(createWalletFailure(createWalletErr));
                                    }
                                }}
                            />
                            <div className={`${Styles.inputErr}`}>{createWalletErr?.name ? createWalletErr.name : null}</div>
                        </div>
                        <div className={`${Styles.navigationRow}`}>
                            <div className={`${Styles.navigationRowTitle}`}>{t('Цвет')}</div>
                            <div className={`${Styles.navigationRowBox} ${Styles.navigationRowBoxColored}`}>
                                <div
                                    className={`${Styles.navigationRowBoxItem} ${typeStatus === 1 ? Styles.navigationRowBoxItemActiveC : null}`}
                                    onClick={()=>{setTypeStatus(1)}}
                                />
                                <div
                                    className={`${Styles.navigationRowBoxItem} ${typeStatus === 2 ? Styles.navigationRowBoxItemActiveC : null}`}
                                    onClick={()=>{setTypeStatus(2)}}
                                />
                                <div
                                    className={`${Styles.navigationRowBoxItem} ${typeStatus === 3 ? Styles.navigationRowBoxItemActiveC : null}`}
                                    onClick={()=>{setTypeStatus(3)}}
                                />
                            </div>
                        </div>
                        <div className={`${Styles.navigationRow}`}>
                            <div className={`${Styles.navigationRowTitle}`}>{t('Картинка')}</div>
                            <div className={`${Styles.navigationRowBox}`}>
                                <div
                                    className={`${Styles.navigationRowBoxItem} ${pictureStatus === 1 ? Styles.navigationRowBoxItemActiveT : null}`}
                                    onClick={()=>{setPictureStatus(1)}}
                                >
                                    <img src={pictures[0][typeStatus - 1]} alt="wallet choice bg"/>
                                </div>
                                <div
                                    className={`${Styles.navigationRowBoxItem} ${pictureStatus === 2 ? Styles.navigationRowBoxItemActiveT : null}`}
                                    onClick={()=>{setPictureStatus(2)}}
                                >
                                    <img src={pictures[1][typeStatus - 1]} alt="wallet choice bg"/>
                                </div>
                                <div
                                    className={`${Styles.navigationRowBoxItem} ${pictureStatus === 3 ? Styles.navigationRowBoxItemActiveT : null}`}
                                    onClick={()=>{setPictureStatus(3)}}
                                >
                                    <img src={pictures[2][typeStatus - 1]} alt="wallet choice bg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`${Styles.btn}`} onClick={()=>{mainBtnHandler()}}
                >
                    {type === 'create' ? t('Создать кошелек') : t('Импортировать кошелек')}
                </div>
            </div>
        </section>
    );
};

export default WalletCreateStep2;
