import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";
import ConfirmationService from "../../components/confirmationService";
import ConfirmationSecurity from "../../components/confirmationSecurity";
import ConfirmationPhrase from "../../components/confirmationPhrase";
import ConfirmationFinish from "../../components/confirmationFinish";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";


const ConfirmationPage = () => {
    const step = useSelector(store => store.main.confirmationStep);
    const authStatus = useSelector(store => store.main.authorizationStatus);
    const { t } = useTranslation();

    const navigate = useNavigate();


    useEffect(()=>{
        if (authStatus.phrase){
            navigate('/home')
        } else {
            if (authStatus.authorization){
                navigate('/confirmation');
            } else {
                navigate('/sign-in');
            }
        }
    },[authStatus, navigate])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Аутентификация')}/>
            {!step ? <ConfirmationService/> : null}
            {step === '1' ? <ConfirmationSecurity/> : null}
            {step === '2' ? <ConfirmationPhrase/> : null}
            {step === '3' ? <ConfirmationFinish/> : null}
        </section>
    );
};

export default ConfirmationPage;
