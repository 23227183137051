import React, {useEffect} from "react";
import {Element, scroller} from 'react-scroll';
import Styles from './styles.module.scss'
import MainPreview from "../../components/mainPreview";
import MainAbout from "../../components/mainAbout";
import MainStart from "../../components/mainStart";
import MainUsed from "../../components/mainUsed";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import CryptoMarquee from "../../components/cryptoMarquee";
import MainAdvantages from "../../components/mainAdvantages";
import MainNews from "../../components/mainNews";
import {getLatestNews, setLinkScrollStatus} from "../../store/main/action";
import MainApp from "../../components/mainApp";
import MainMedia from "../../components/mainMedia";


const MainPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authStatus = useSelector(store => store.main.authorizationStatus);
    const linkScrollStatus = useSelector(store => store.main.linkScrollStatus);

    const scrollHandler = (element) =>{
        scroller.scrollTo(element, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }


    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                // navigate('/confirmation');
            } else {
                // navigate('/wallet');
            }
        }

    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getLatestNews());
    },[dispatch])
    
    useEffect(()=>{
        if (linkScrollStatus){
            scrollHandler(linkScrollStatus);
            dispatch(setLinkScrollStatus(false));
        }
    },[dispatch, linkScrollStatus])

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'Oracle Meta Technologies'}/>
            <MainPreview/>
            <CryptoMarquee/>
            <Element name="about">
                <MainAbout/>
            </Element>
            <MainMedia/>
            <MainAdvantages/>
            <Element name="start">
                <MainStart/>
            </Element>
            <MainUsed/>
            <MainApp/>
            <MainNews/>
        </section>
    );
};

export default MainPage;