import React, {useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {setWalletSendFormStatus} from "../../store/wallet/action";
// import WalletTakeStep2 from "../_UI/walletTakeStep2";
import WalletSendStep1 from "../_UI/walletSendStep1";
import WalletSendStep2 from "../_UI/walletSendStep2";
import WalletSendStep3 from "../_UI/walletSendStep3";
import {useParams} from "react-router-dom";


const WalletSend = () => {
    const dispatch = useDispatch();
    const {id} = useParams();

    const sendFormStatus = useSelector(store => store.wallet.sendFormStatus);
    const sendWalletNetwork = useSelector(store => store.wallet.walletAssetsWithBalance);
    const currentWallet = useSelector(store => store.wallet.currentWallet);

    const [step, setStep] = useState(1);
    const [currentToken, setCurrentToken] = useState(sendFormStatus?.data ? sendFormStatus.data : null);

    const closeModal = () =>{
        dispatch(setWalletSendFormStatus({status: false, data: false}));
        if (id){
            setStep(2);
        } else {
            setStep(1);
        }
    }

    const contentTypeHandler = (type) =>{
        setStep(type);
    }


    useEffect(()=>{
        if (sendFormStatus.data && !id){
            setCurrentToken(sendFormStatus.data)
        }
    },[sendFormStatus, id])

    useEffect(()=>{
        if (id){
            setStep(2);
            setCurrentToken(currentWallet);
        }
    },[id, currentWallet])

    return (
        <Popup
            open={sendFormStatus?.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    {step === 1 ?
                        <WalletSendStep1
                            contentTypeHandler={contentTypeHandler}
                            takeWalletNetwork={sendWalletNetwork}
                            currentToken={currentToken}
                            setCurrentToken={setCurrentToken}
                        /> : null}
                    {step === 2 ?
                        <WalletSendStep2
                            contentTypeHandler={contentTypeHandler}
                            takeWalletNetwork={sendWalletNetwork}
                            currentToken={currentToken}
                            setCurrentToken={setCurrentToken}
                            closeModal={closeModal}
                        /> : null}
                    {step === 3 ? <WalletSendStep3/> : null}
                </div>
            </div>
        </Popup>
    );
};

export default WalletSend;
