
export const api = {
    host:  process.env.REACT_APP_API_URL,
    // host:  'https://apis.omt.technology/',
    // host:  'http://api.omt.adtdev.pro/',
    login: 'api/v1/login',
    loginWH: 'api/v1/login-via-code',
    registration: 'api/v1/register', 
    logout: 'api/v1/logout',
    phrase: 'api/v1/backup-phrase',
    user: 'api/v1/auth/user',
    reset: 'api/v1/reset-password',
    wallet: {
        availableCurrencies: 'api/v1/currencies/available',
        historyCurrencies: 'api/v1/currencies',
        createWallet: 'api/v1/wallets',
        transaction: 'api/v1/wallets',
        transactions: 'api/v1/transactions',
        staking: 'api/v1/user/staking',
        banners: 'api/v1/banners',
        periods: 'api/v1/omt-periods',
    },
    staking: {
        available: 'api/v1/staking/main',
        open: 'api/v1/staking',
        commission: 'api/v2/estimate'
    },
    bonuses: {
        main: 'api/v1/referral/main',
        info: 'api/v1/referral-info',
        logs: 'api/v1/referral/logs/stats'
    },
    bot: {
        main: 'api/v1/bot/main',
        open: 'api/v1/bot',
        show: 'api/v1/bot/show',
        subscribe: 'api/v1/bot-subscriptions/available',
        custom: 'api/v1/bot/custom-settings',
        buySubscribe: 'api/v1/bot-subscriptions/buy'
    },
    settings: {
        password: 'api/v1/auth/user/password'
    },
    exchange: {
        data: 'api/v1/honey/exchanger',
        history: 'api/v1/exchanger-logs'
    },
    support:{
        main: 'api/v1/support'
    },
    news:{
        latest: 'api/v1/posts/latest',
        post: 'api/v1/posts',
        top: 'api/v1/posts/top',
        paginate: 'api/v1/posts/pagination',
        crypto: 'api/v3/currencies'
    },
    ambassador: {
        main: 'api/v1/ambassadors/main',
        statuses: 'api/v1/ambassadors/statuses',
        graph: 'api/v1/ambassadors/graph',
        links: 'api/v1/ambassadors/attach-links',
        turnover: 'api/v1/ambassadors/turnover',
        salary: 'api/v1/ambassadors/salaries'
    },
    tickets: {
        all: 'api/v1/tickets',
        current: 'api/v1/tickets/current',
        buy: 'api/v1/tickets/buy',
        total: 'api/v1/tickets/total'
    },
    events: {
        all: 'api/v1/events'
    },
    v2: {
        wallet: {
            availableWallets: 'api/v2/wallets',
            createWallet: 'api/v2/wallets',
            transactionCommission: 'api/v2/estimate',
            indicators: 'api/v2/currencies'
        },
        staking: {
            award: 'api/v2/estimate',
            withdraw: 'api/v2/omt-staking'
        }
    },
    v3: {
        wallet: {
            indicators: 'api/v3/currencies',
            honeechizeMain: 'api/v3/honeechize/info',
            honeechizeEarningsGraph: 'api/v3/honeechize/earnings-graph',
            honeechizeProfitGraph: 'api/v3/honeechize/profit-forecast',
            honeechizeAffiliateSettings: 'api/v3/honeechize/settings',
            honeechizeAffiliateStatistic: 'api/v3/honeechize/referral-stats',
            honeechizeBotEarnings: 'api/v3/honeechize/earnings',
            honeechizeBotSettings: 'api/v3/honeechize/bot-settings'
        },
    },
    v4: {
        wallet: {
            cbMain: 'api/v4/community-boost/main',
            cbPost: 'api/v4/community-boost/link',
            cbLead: 'api/v4/community-boost/leader-board',
            indexMain: 'api/v1/crypto-index/omt20/main',
            indexSwap: 'api/v1/crypto-index/omt20/buy',
            indexHistory: 'api/v1/crypto-index/omt20/history',
            indexPrediction: 'api/v1/crypto-index/omt20/comparison'
        }
    }
}