import React from "react";
import Styles from './styles.module.scss';

import tokenomics from '../../theme/assets/svg/omt_ps_tokenomics.svg';
import roadmap from '../../theme/assets/svg/omt_whitepaper_roadmap.svg';
import rocket from '../../theme/assets/svg/omt_presale_rocket.svg';
import rocketMobile from '../../theme/assets/svg/omt_presale_rocket_mobile.svg';

const PublicPresaleImgs = () => {

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.contentWrapItem}`}>
                    <img src={tokenomics} alt="tokenomics"/>
                </div>
                <div className={`${Styles.contentWrapItem}`}>
                    <img src={roadmap} alt="roadmap"/>
                </div>
                <div className={`${Styles.contentWrapItem} ${Styles.contentWrapItemWB}`}>
                    <img className={`${Styles.dImg}`} src={rocket} alt="roadmap"/>
                    <img className={`${Styles.mImg}`} src={rocketMobile} alt="roadmap"/>
                </div>
            </div>
        </section>
    )
}

export default PublicPresaleImgs;