import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import PostSubscribe from "../../components/postSubscribe";
import PostMostRead from "../../components/postMostRead";
import PostMain from "../../components/postMain";
import {getPost, getTopNews} from "../../store/main/action";


const PostPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let {title} = useParams();
    const authStatus = useSelector(store => store.main.authorizationStatus);

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                // navigate('/confirmation');
            } else {
                // navigate('/wallet');
            }
        }

    },[authStatus, navigate])

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    useEffect(()=>{
        dispatch(getPost(title.slice(title.lastIndexOf('n') + 1, title.length)));
        dispatch(getTopNews())
    },[dispatch, title])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'News'}/>
            <div className={`${Styles.contentWrap}`}>
                <PostMain/>
                <PostMostRead/>
                <PostSubscribe/>
            </div>
        </section>
    );
};

export default PostPage;