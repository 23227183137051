import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import AmbassadorsRequest from "../../components/ambassadorsRequest";
import AmbassadorsMain from "../../components/ambassadorsMain";
import AmbassadorsProfit from "../../components/ambassadorsProfit";
import AmbassadorsPromo from "../../components/ambassadorsPromo";
import AmbassadorsTasks from "../../components/ambassadorsTasks";
import AmbassadorsModal from "../../components/ambassadorsModal";
import AmbassadorsInfluencer from "../../components/ambassadorsInfluencer";
import AmbassadorsInfluencerModal from "../../components/ambassadorsInfluencerModal";
import {
    getAmbassadorGraphData,
    getAmbassadorLinks,
    getAmbassadorMainData,
    getAmbassadorStatuses, getAmbassadorTurnover
} from "../../store/wallet/action";
import AmbassadorsModalSalary from "../../components/amabassadorsModalSalary";
import AmbassadorsProgress from "../../components/ambassadorsProgress";



const AmbassadorsPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authStatus = useSelector(store => store.main.authorizationStatus);
    const ambassadorsData = useSelector(store => store.wallet.ambassadors);
    const userData = useSelector(store => store.main.userData);


    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])

    useEffect(()=>{
        if (userData?.ambassador_status === 'approved'){
            dispatch(getAmbassadorMainData());
            dispatch(getAmbassadorStatuses());
            dispatch(getAmbassadorGraphData());
            dispatch(getAmbassadorLinks());
        }
    },[ambassadorsData, dispatch, userData]);
    
    useEffect(()=>{
        dispatch(getAmbassadorTurnover());
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'Ambassadors'}/>
            <div className={`${Styles.contentWrap} ${userData?.is_influencer === true ? 'influencer' : ' '}`}>
                {userData?.ambassador_status === 'approved' ? <>
                    <AmbassadorsMain/>
                    <AmbassadorsProgress/>
                    {userData?.is_influencer === true ? <AmbassadorsInfluencer/> : <AmbassadorsTasks/>}
                    <AmbassadorsProfit/>
                    <AmbassadorsPromo/>
                    <AmbassadorsModal/>
                    <AmbassadorsModalSalary/>
                    <AmbassadorsInfluencerModal/>
                </> : <AmbassadorsRequest/>}
            </div>
        </section>
    );
};

export default AmbassadorsPage;