import React, {useState} from "react";
import Styles from './styles.module.scss'
import CryptoIcon from "../cryptoIcon";

import listArrowIcon from '../../../theme/assets/svg/omt_list_arrow_icon.svg';
import {useTranslation} from "react-i18next";



const WalletSendStep1 = (props) => {
    const {contentTypeHandler, currentToken, setCurrentToken, takeWalletNetwork} = props;
    const { t } = useTranslation();

    const [listStatus, setListStatus] = useState(false);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title}`}>{t('Выберите токен')}</div>
                <div className={`${Styles.listWrap}`}>
                    <div className={`${Styles.listPosWrap}`}>
                        <div className={`${Styles.listBox}`} onClick={()=>{setListStatus(!listStatus)}}>
                            <div className={`${Styles.listBtn} ${listStatus ? Styles.listBtnActive : null}`}>
                                <img src={listArrowIcon} alt="list arrow"/>
                            </div>
                            <div className={`${Styles.listResult}`}>
                                <div className={`${Styles.listResultImgWrap}`}>
                                    <CryptoIcon short_name={currentToken?.currency?.short_name}/>
                                </div>
                                <div className={`${Styles.listResultText}`}>{currentToken?.currency?.name}</div>
                                <div className={`${Styles.listResultText} ${Styles.listResultTextBalance}`}>{currentToken?.amount * 1} {currentToken?.currency?.short_name}</div>
                            </div>
                        </div>
                        <div className={`${Styles.abslistWrap} ${listStatus ? Styles.abslistWrapActive : null}`}>
                            <div className={`${Styles.abslistBorderWrap}`}>
                                <div className={`${Styles.abslistBox}`}>
                                    {takeWalletNetwork?.map((item, index)=>{
                                        return (
                                            <div
                                                className={`${Styles.listBoxItem}`}
                                                key={'walletNetwork' + index}
                                                onClick={()=>{
                                                    setCurrentToken(item);
                                                    setListStatus(false);
                                                }}
                                            >
                                                <div className={`${Styles.listBoxItemImgWrap}`}>
                                                    <CryptoIcon short_name={item.currency?.short_name}/>
                                                </div>
                                                <div className={`${Styles.listBoxItemText}`}>{item.currency?.name}</div>
                                                <div className={`${Styles.listBoxItemText} ${Styles.listBoxItemTextBalance}`}>{item.amount * 1} {item.currency?.short_name}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.btn}`} onClick={()=>{contentTypeHandler(2)}}>{t('Далее')}</div>
                </div>
            </div>
        </section>
    );
};

export default WalletSendStep1;
