import React from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import CryptoIcon from "../_UI/cryptoIcon";
import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import {setStakingWithdrawFormStatus} from "../../store/wallet/action";


const StakingInWork = () => {
    const stakingAvailable = useSelector(store => store.wallet.availableStaking);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const statusHandler = (status) =>{
        switch (status){
            case 'new':
                return  t('Новый')
            case 'active':
                return t('Активный')
            case 'closed':
                return t('Завершенный')
            default:
                return t('Неизвестно')
        }
    }

    const withdrawButton = (item, mobStatus) => {
        if (!item.is_active) {
            return (<div></div>);
        }
        return (
            <div
                className={`${Styles.contentBoxItemValues} ${mobStatus ? null : Styles.contentBoxItemD}`}
                onClick={()=>{dispatch(setStakingWithdrawFormStatus({status: true, item: item, balance: false}))}}
                style={{textAlign : `${mobStatus ? 'center' : 'right'}`}}
            >
                {t('Вывод')}
            </div>
        );
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBodyWithScroll}`}>
                    <div className={`${Styles.title3}`}>{t('В работе')}</div>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Монета')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Всего')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.titleRowItemM} ${Styles.titleRowItemSM}`}>{t('Дни')} | APY %</div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.titleRowItemD}`}>{t('Дни')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.titleRowItemD}`}>APY %</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Доходность')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.titleRowItemD}`}>{t('Статус')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.titleRowItemD}`}>{t('Действие')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        {stakingAvailable?.open?.length > 0 ? stakingAvailable.open.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem}`} key={'stakingAvailable' + index}>
                                    <div className={`${Styles.contentBoxItemNameWrap}`}>
                                        <div className={`${Styles.contentBoxItemNameImgWrap}`}>
                                            <CryptoIcon short_name={item.currency.short_name}/>
                                        </div>
                                        <div className={`${Styles.contentBoxItemNameBox}`}>
                                            <div className={`${Styles.contentBoxItemShortName}`}>{item.currency.short_name}</div>
                                            <div className={`${Styles.contentBoxItemFullName}`}>{item.currency.name}</div>
                                        </div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemValues}`}>{item.total * 1} <span className={`${Styles.tabletShow}`}>{withdrawButton(item, true)}</span></div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemM} ${Styles.contentBoxItemSM} ${Styles.contentBoxItemAmount}`}>
                                        <div className={`${Styles.contentBoxItemValuesRowWrap}`}>
                                            <div className={`${Styles.contentBoxItemValuesRow}`}>{item.days}</div>
                                            <div className={`${Styles.contentBoxItemValuesRow}`}>{item.apy * 1}</div>
                                        </div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemD}`}>{item.days}</div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemD}`}>{item.apy * 1}</div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemD}`}>{item.profit * 1}</div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemTotal}`}>
                                        <div className={`${Styles.contentBoxItemTotalRow}`}>{item.profit * 1}</div>
                                        <div className={`${Styles.contentBoxItemTotalRow} ${Styles.fluc}`}>{statusHandler(item.status)}</div>
                                    </div>
                                    {withdrawButton(item)}

                                </div>
                            )
                        }) : <EmptyValues text={t('У вас пока что нет активов')} withIcon={true} withBtn={false}/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StakingInWork;
