import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperHoneyAi = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('HoneeAi')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>HoneeAi</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    For those seeking a reliable assistant in the world of cryptocurrency trading, we
                                    introduce our HoneeAi, designed to operate on DEX exchanges. Our robot is
                                    available in two subscription options to cater to the needs of various types of
                                    traders.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    The Standard subscription is perfect for those just starting their journey with
                                    automated trading. It offers the chance to level up your bot to a maximum of
                                    level 3, with expected returns ranging from 3% to 6% monthly. You'll have
                                    access to three types of settings: moderate, average, and high, so you can
                                    choose the optimal risk for your portfolio.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The Pro Version is crafted for seasoned traders who want to maximize their
                                    potential. With this subscription, your bot can be leveled up to 7, unlocking the
                                    potential to earn from 3% up to an astounding 28% monthly. The enhanced
                                    settings functionality offers you a plethora of tools for fine-tuning and
                                    optimizing trading strategies.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    Choose the subscription that aligns with your goals and kickstart your trading
                                    career with our bot!
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperHoneyAi;