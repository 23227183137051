import React from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import CryptoIcon from "../_UI/cryptoIcon";
import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {setStandardWalletCryptoModalStatus} from "../../store/wallet/action";


const StandardWalletAssets = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const availableWallets = useSelector(store => store.wallet.availableWallets);

    const walletPageHandler = (id) =>{
        navigate(`/wallet/${id}`)
    }


    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBodyWithScroll}`}>
                    <div className={`${Styles.title3}`}>{t('Активы')}</div>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Токен')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Сумма')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{window.screen.width >= 600 ? t('Информация') : t('Инфо')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        {availableWallets.length > 0 ? availableWallets.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem}`} key={'walletAssets' + index}>
                                    <div className={`${Styles.contentBoxItemNameWrap}`} onClick={()=>{walletPageHandler(item.currency.id)}}>
                                        <div className={`${Styles.contentBoxItemNameImgWrap}`}>
                                            <CryptoIcon short_name={item?.currency?.short_name}/>
                                        </div>
                                        <div className={`${Styles.contentBoxItemNameBox}`}>
                                            <div className={`${Styles.contentBoxItemShortName}`}>{item?.currency?.short_name}</div>
                                            <div className={`${Styles.contentBoxItemFullName}`}>
                                                <span className={`${Styles.contentBoxItemRate}`}>{Number(item?.currency?.rate)}$</span> <span className={`${Number(item?.currency?.percent_change_24h) > 0 ? Styles.colorGreen : Styles.colorRed}`}>{Number(item?.currency?.percent_change_24h) > 0 ? '+' : ''}{Number(item?.currency?.percent_change_24h).toFixed(2)}%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemAmount}`}>{(item.amount * 1).toFixed(2)}</div>
                                    {item?.currency?.ticker ?
                                        <div
                                            className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemTotal}`}
                                            onClick={()=>{
                                                dispatch(setStandardWalletCryptoModalStatus({status: true, item: item}))
                                            }}
                                        >
                                            {t('Инфо')}
                                        </div> : null
                                    }
                                </div>
                            )
                        }) : <EmptyValues text={t('У вас пока что нет активов')} withIcon={true} withBtn={true} type='walletAssets'/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StandardWalletAssets;
