export const TEST = 'TEST';

export const REGISTRATION_STARTED = 'REGISTRATION_STARTED';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGIN_WITH_HASH_STARTED = 'LOGIN_WITH_HASH_STARTED';
export const LOGIN_WITH_HASH_SUCCESS = 'LOGIN_WITH_HASH_SUCCESS';
export const LOGIN_WITH_HASH_FAILURE = 'LOGIN_WITH_HASH_FAILURE';

export const LOGOUT_STARTED = 'LOGOUT_STARTED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const GET_PHRASE_STARTED = 'GET_PHRASE_STARTED';
export const GET_PHRASE_SUCCESS = 'GET_PHRASE_SUCCESS';
export const GET_PHRASE_FAILURE = 'GET_PHRASE_FAILURE';

export const SET_PHRASE_STARTED = 'SET_PHRASE_STARTED';
export const SET_PHRASE_SUCCESS = 'SET_PHRASE_SUCCESS';
export const SET_PHRASE_FAILURE = 'SET_PHRASE_FAILURE';

export const GET_USER_DATA_STARTED = 'GET_USER_DATA_STARTED';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';

export const SET_AUTHORIZATION_STATUS = 'SET_AUTHORIZATION_STATUS';
export const SET_CONFIRMATION_STEP = 'SET_CONFIRMATION_STEP';
export const SET_MOBILE_NAV_STATUS = 'SET_MOBILE_NAV_STATUS';
export const SET_MOBILE_WP_NAV_STATUS = 'SET_MOBILE_WP_NAV_STATUS';
export const CONFIRMATION_CLEAR = 'CONFIRMATION_CLEAR';

export const GET_LATEST_NEWS_STARTED = 'GET_LATEST_NEWS_STARTED';
export const GET_LATEST_NEWS_SUCCESS = 'GET_LATEST_NEWS_SUCCESS';
export const GET_LATEST_NEWS_FAILURE = 'GET_LATEST_NEWS_FAILURE';

export const GET_MAIN_NEWS_STARTED = 'GET_MAIN_NEWS_STARTED';
export const GET_MAIN_NEWS_SUCCESS = 'GET_MAIN_NEWS_SUCCESS';
export const GET_MAIN_NEWS_FAILURE = 'GET_MAIN_NEWS_FAILURE';

export const GET_POST_STARTED = 'GET_POST_STARTED';
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export const GET_POST_FAILURE = 'GET_POST_FAILURE';

export const GET_TOP_NEWS_STARTED = 'GET_TOP_NEWS_STARTED';
export const GET_TOP_NEWS_SUCCESS = 'GET_TOP_NEWS_SUCCESS';
export const GET_TOP_NEWS_FAILURE = 'GET_TOP_NEWS_FAILURE';

export const SET_LINK_SCROLL_STATUS = 'SET_LINK_SCROLL_STATUS';

export const GET_PRESALE_DATA_STARTED = 'GET_PRESALE_DATA_STARTED';
export const GET_PRESALE_DATA_SUCCESS = 'GET_PRESALE_DATA_SUCCESS';
export const GET_PRESALE_DATA_FAILURE = 'GET_PRESALE_DATA_FAILURE';

export const RESET_PASSWORD_STARTED = 'RESET_PASSWORD_STARTED';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const SET_NEW_DEVICE_STATUS = 'SET_NEW_DEVICE_STATUS';
export const SET_HASH_STATUS = 'SET_HASH_STATUS';





