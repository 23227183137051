import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import {
    botInvest,
    botInvestSuccess,
    getBotMainData,
    setBotPromoModalStatus,
    stopBot
} from "../../store/wallet/action";
import {toast} from "react-toastify";


const BotInfo = () => {
    const botMainData = useSelector(store => store.wallet.botMainData);
    const botInvestStatus = useSelector(store => store.wallet.botInvestStatus);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [sum, setSum] = useState('');

    const stopBotHandler = () =>{
        if (botMainData.bot.id){
            dispatch(stopBot(botMainData.bot.id));
            if (botMainData.bot.is_active){
                toast(t('Бот остановлен!'));
            } else {
                toast(t('Бот запущен!'));
            }
        }
    }

    const botInvestHandler = () =>{
        if (sum >= 1 && sum <= getAvailableAmount() && botMainData.bot.id){
            // dispatch(botInvest(botMainData.bot.id, {amount: sum}))
            dispatch(setBotPromoModalStatus({status: true, balance: sum, sendAction: botInvest, botId: botMainData.bot.id, isOpen: false, currentRisk: false}))
        }
    }

    const getAvailableAmount = () => {
        const tempAmount = Math.floor(botMainData?.max_available_amount * 100);
        return Number(Number(tempAmount / 100).toFixed(2));
    }

    const getAvailableBalance = () => {
        const tempAmount = Math.floor(botMainData?.wallet?.amount * 100);
        return Number(Number(tempAmount / 100).toFixed(2));
    }

    useEffect(()=>{
        if (botInvestStatus){
            dispatch(botInvestSuccess(false))
            dispatch(getBotMainData());
            toast(t('Вы успешно пополнили баланс бота!'));
            setSum('');
        }
    },[botInvestStatus, dispatch, t])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.title3}`}>{t('Бот в работе')}</div>
                    <div className={`${Styles.contentBox}`}>
                        {botMainData?.bot?.id ?
                            <div className={`${Styles.contentBoxWrap}`}>
                                <div className={`${Styles.contentBoxSettingsWrap}`}>
                                    <div className={`${Styles.contentBoxSettingsItem}`}>
                                        <div className={`${Styles.contentBoxSettingsItemValue}`}>{botMainData?.spread}%</div>
                                        <div className={`${Styles.contentBoxSettingsItemName}`}>{t('Спред')}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxSettingsItem}`}>
                                        <div className={`${Styles.contentBoxSettingsItemValue}`}>${botMainData?.profit_avg}</div>
                                        <div className={`${Styles.contentBoxSettingsItemName}`}>{t('Средний доход сделки')}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxSettingsItem}`}>
                                        <div className={`${Styles.contentBoxSettingsItemValue}`}>{botMainData?.deal_count_avg}</div>
                                        <div className={`${Styles.contentBoxSettingsItemName}`}>{t('Кол-во сделок в день')}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxSettingsItem}`}>
                                        <div className={`${Styles.contentBoxSettingsItemValue}`}>{botMainData?.stock_count}</div>
                                        <div className={`${Styles.contentBoxSettingsItemName}`}>{t('Кол-во бирж')}</div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxDepositWrap}`}>
                                    <div className={`${Styles.inputWrap}`}>
                                        <input
                                            type="number"
                                            placeholder={`${t('От')} 1 ${(botMainData?.wallet?.amount ? getAvailableAmount() : '0') >= 250 ? `${t('до')} ${botMainData?.wallet?.amount ? getAvailableAmount() : '0'}` : ''}`}
                                            onChange={(e)=>{
                                                if (e.target.value < 0){
                                                    setSum(0)
                                                } else if (e.target.value > getAvailableBalance() || e.target.value > getAvailableAmount()){
                                                    if (Number(getAvailableBalance()) > Number(getAvailableAmount())){
                                                        setSum(getAvailableAmount());
                                                    } else {
                                                        setSum(getAvailableBalance());
                                                    }
                                                } else {
                                                    setSum(e.target.value)
                                                }
                                            }}
                                            className={`${Styles.inputSum}`}
                                            value={sum}
                                        />
                                        <div className={`${Styles.inputAbs}`} onClick={()=>{
                                            if (getAvailableBalance() > getAvailableAmount()){
                                                setSum(getAvailableAmount());
                                            } else {
                                                setSum(getAvailableBalance());
                                            }
                                        }}>
                                            <span className={`${Styles.inputAbsBtn}`}>{t('Все')}</span>
                                        </div>
                                        <div className={`${Styles.inputNote}`}>{t('Доступный баланс')}: <span>{getAvailableBalance()} Honee</span></div>
                                    </div>
                                    <div className={`${Styles.btnWrap}`}>
                                        <div className={`${Styles.btnItem} ${sum >= 1 && sum <= getAvailableAmount() && botMainData.bot.id ? Styles.btnItemActive : null}`} onClick={()=>{botInvestHandler()}}>{t('Добавить средства')}</div>
                                        <div className={`${Styles.btnItem}`} onClick={()=>{stopBotHandler()}}>{botMainData?.bot?.is_active ? t('Остановить') : t('Запустить')}</div>
                                    </div>
                                </div>
                            </div>
                            : <EmptyValues text={t('У вас пока что нет активного бота')} withIcon='bot'/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BotInfo;
