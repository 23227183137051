import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import logo from "../../theme/assets/svg/omt_final_logo.svg";
// import nyLogo from '../../theme/assets/svg/omt_ny_logo.svg';
import NavIcon from "../_UI/navIcon";
import { useTranslation } from "react-i18next";

import soc_icon1 from '../../theme/assets/svg/omt_nav_soc_icon1.svg';
import soc_icon2 from '../../theme/assets/svg/omt_nav_soc_icon2.svg';
import soc_icon3 from '../../theme/assets/svg/omt_nav_soc_icon3.svg';
import soc_icon5 from '../../theme/assets/svg/omt_nav_soc_icon5.svg';
import soc_icon7 from "../../theme/assets/svg/omt_nav_soc_icon7.svg";
import soc_icon6 from "../../theme/assets/svg/omt_nav_soc_icon6.svg";

import honeechizeIcon from '../../theme/assets/svg/omt_honeechize_icon.svg';

import log_out_icon from '../../theme/assets/svg/omt_nav_log_out_icon.svg';
import {useDispatch, useSelector} from "react-redux";
import {logout, setMobileNavStatus} from "../../store/main/action";
import {useNavigate} from "react-router-dom";
import LangSwitcher from "../langSwitcher";
import {getTotalBalance} from "../../store/wallet/action";


const WalletNavigation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const userData = useSelector(store => store.main.userData);
    const totalBalanceStore = useSelector(store => store.wallet.totalBalance);
    const mobileNavStatus = useSelector(store => store.main.mobileNavStatus);

    const [isHover, setIsHover] = useState('');
    const [totalBalance, setTotalBalance] = useState(0);

    const pageHandler = (page) =>{
        return window.location.pathname.indexOf(page) === 1
    }

    useEffect(()=>{
        dispatch(getTotalBalance());
    },[dispatch])

    useEffect(()=>{
        if (totalBalanceStore){
            let curSum = 0;
            totalBalanceStore.forEach((item)=>{
                curSum = curSum + Number(item.amount_usdt);
            })
            setTotalBalance(curSum.toFixed(2));
        }
    },[totalBalanceStore])


    return (
        <section className={`${Styles.container} ${mobileNavStatus ? Styles.containerActive : null}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.logoWrap}`} onClick={()=>{navigate('/')}}>
                    <img src={logo} alt="omt logotype"/>
                </div>
                <div className={`${Styles.balanceWrap}`}>
                    {totalBalance} USDT
                </div>
                {userData?.honeechize_permission || userData?.honeechize_structure ?
                    <div className={`${Styles.honeechizeWrap}`}>
                        <div className={`${Styles.honeechizeImgWrap}`}>
                            <img src={honeechizeIcon} alt="honeechize"/>
                        </div>
                        <div className={`${Styles.honeechizeText}`}>{userData?.honeechize_label ? userData?.honeechize_label : ''}</div>
                    </div> : null
                }
                <div className={`${Styles.mainNavWrap}`}>
                    {/*<div*/}
                    {/*    className={`${Styles.navItem} ${pageHandler('tickets') ? Styles.navItemActive : null}`}*/}
                    {/*    onClick={()=>{*/}
                    {/*        navigate('/tickets');*/}
                    {/*        dispatch(setMobileNavStatus(false));*/}
                    {/*    }}*/}
                    {/*    onMouseEnter={()=>{setIsHover('11')}}*/}
                    {/*    onMouseLeave={()=>{setIsHover('')}}*/}
                    {/*>*/}
                    {/*    <NavIcon type={'11'} isHover={pageHandler('tickets') ? true : isHover === '11'}/>*/}
                    {/*    <span>Pre-sale</span>*/}
                    {/*</div>*/}
                    <div
                        className={`${Styles.navItem} ${pageHandler('home') ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/home');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={()=>{setIsHover('1')}}
                        onMouseLeave={()=>{setIsHover('')}}
                    >
                        <NavIcon type={'1'} isHover={pageHandler('home') ? true : isHover === '1'}/>
                        <span>{t('Главная')}</span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${pageHandler('wallet') ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/wallet');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={()=>{setIsHover('12')}}
                        onMouseLeave={()=>{setIsHover('')}}
                    >
                        <NavIcon type={'12'} isHover={pageHandler('wallet') ? true : isHover === '12'}/>
                        <span>{t('Кошелек')}</span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('staking') === 1 ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/staking');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={()=>{setIsHover('2')}}
                        onMouseLeave={()=>{setIsHover('')}}
                    >
                        <NavIcon type={'2'} isHover={pageHandler('staking') ? true : isHover === '2'}/>
                        <span>{t('Стейкинг')}</span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('honeeAi') === 1 ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/honeeAi');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={()=>{setIsHover('3')}}
                        onMouseLeave={()=>{setIsHover('')}}
                    >
                        <NavIcon type={'3'} isHover={pageHandler('honeeAi') ? true : isHover === '3'}/>
                        <span>{t('Бот')}</span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${Styles.navItemBoost} ${window.location.pathname.indexOf('community-boost') === 1 ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/community-boost');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={()=>{setIsHover('15')}}
                        onMouseLeave={()=>{setIsHover('')}}
                    >
                        <NavIcon type={'15'} isHover={pageHandler('community-boost') ? true : isHover === '15'}/>
                        <span>{t('Поддержка сообщества')}</span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${Styles.navItemIndex} ${window.location.pathname.indexOf('community-boost') === 1 ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/index');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={()=>{setIsHover('16')}}
                        onMouseLeave={()=>{setIsHover('')}}
                    >
                        <NavIcon type={'16'} isHover={pageHandler('community-boost') ? true : isHover === '16'}/>
                        <span>Index <span>OMT20</span></span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('bonuses') === 1 ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/bonuses');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={()=>{setIsHover('4')}}
                        onMouseLeave={()=>{setIsHover('')}}
                    >
                        <NavIcon type={'4'} isHover={pageHandler('bonuses') ? true : isHover === '4'}/>
                        <span>{t('Программа Лояльности')}</span>
                    </div>
                    {userData?.honeechize_permission || userData?.honeechize_partner ?
                        <div
                            className={`${Styles.navItem} ${Styles.navItemOrange} ${window.location.pathname.indexOf('honeechize') === 1 ? Styles.navItemActive : null}`}
                            onClick={()=>{
                                navigate('/honeechize/metrics');
                                dispatch(setMobileNavStatus(false));
                            }}
                            onMouseEnter={()=>{setIsHover('14')}}
                            onMouseLeave={()=>{setIsHover('')}}
                        >
                            <NavIcon type={'14'} isHover={pageHandler('honeechize') ? true : isHover === '14'}/>
                            <span>Honeechize</span>
                        </div>
                        : null}
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('ambassadors') === 1 ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/ambassadors');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={()=>{setIsHover('10')}}
                        onMouseLeave={()=>{setIsHover('')}}
                    >
                        <NavIcon type={'10'} isHover={pageHandler('ambassadors') ? true : isHover === '10'}/>
                        <span>{t('Амбассадорка')}</span>
                    </div>
                    {/*{userData?.bounty_visible ?*/}
                    {/*    <div*/}
                    {/*        className={`${Styles.navItem} ${window.location.pathname.indexOf('events') === 1 ? Styles.navItemActive : null}`}*/}
                    {/*        onClick={()=>{*/}
                    {/*            navigate('/events/1');*/}
                    {/*            dispatch(setMobileNavStatus(false));*/}
                    {/*        }}*/}
                    {/*        onMouseEnter={()=>{setIsHover('13')}}*/}
                    {/*        onMouseLeave={()=>{setIsHover('')}}*/}
                    {/*    >*/}
                    {/*        <NavIcon type={'13'} isHover={pageHandler('events') ? true : isHover === '13'}/>*/}
                    {/*        <span>Events</span>*/}
                    {/*    </div>*/}
                    {/*    : null}*/}
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('exchange') === 1 ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/exchange');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={()=>{setIsHover('8')}}
                        onMouseLeave={()=>{setIsHover('')}}
                    >
                        <NavIcon type={'8'} isHover={pageHandler('exchange') ? true : isHover === '8'}/>
                        <span>{t('Обмен')}</span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('transactions') === 1 ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/transactions');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={()=>{setIsHover('9')}}
                        onMouseLeave={()=>{setIsHover('')}}
                    >
                        <NavIcon type={'9'} isHover={pageHandler('transactions') ? true : isHover === '9'}/>
                        <span>{t('Транзакции')}</span>
                    </div>
                    {/*<div*/}
                    {/*    className={`${Styles.navItem}`}*/}
                    {/*    onMouseEnter={()=>{setIsHover('5')}}*/}
                    {/*    onMouseLeave={()=>{setIsHover('')}}*/}
                    {/*>*/}
                    {/*    <NavIcon type={'5'} isHover={isHover === '5'}/>*/}
                    {/*    <span>Мои рефералы</span>*/}
                    {/*</div>*/}
                </div>
                <div className={`${Styles.otherNavWrap}`}>
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('settings') === 1 ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/settings');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={()=>{setIsHover('6')}}
                        onMouseLeave={()=>{setIsHover('')}}
                    >
                        <NavIcon type={'6'} isHover={pageHandler('settings') ? true : isHover === '6'}/>
                        <span>{t('Настройки')}</span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('support') === 1 ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/support');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={()=>{setIsHover('7')}}
                        onMouseLeave={()=>{setIsHover('')}}
                    >
                        <NavIcon type={'7'} isHover={pageHandler('support') ? true : isHover === '7'}/>
                        <span>{t('Поддержка')}</span>
                    </div>
                </div>
                <div className={`${Styles.socialsWrap}`}>
                    <div className={`${Styles.socialsTitle}`}>{t('Социальные сети')}</div>
                    <div className={`${Styles.socialsBox}`}>
                        <div
                            className={`${Styles.socialsBoxItem}`}
                            onClick={() => {
                                window.open("https://www.instagram.com/omtwallet/", "_blank");
                            }}
                        >
                            <img src={soc_icon5} alt="instagram"/>
                        </div>
                        <div
                            className={`${Styles.socialsBoxItem}`}
                            onClick={() => {
                                window.open("https://twitter.com/official_omtech", "_blank");
                            }}
                        >
                            <img src={soc_icon2} alt="twitter"/>
                        </div>
                        <div
                            className={`${Styles.socialsBoxItem}`}
                            onClick={() => {
                                window.open("https://github.com/OracleMetaTechnologies", "_blank");
                            }}
                        >
                            <img src={soc_icon7} alt="github"/>
                        </div>
                        <div
                            className={`${Styles.socialsBoxItem}`}
                            onClick={() => {
                                window.open("https://t.me/officialOMT", "_blank");
                            }}
                        >
                            <img src={soc_icon6} alt="telegram"/>
                        </div>
                        <div
                            className={`${Styles.socialsBoxItem}`}
                            onClick={() => {
                                window.open("https://www.youtube.com/channel/UCDx29CIHFou4lrdIDHBRmiw", "_blank");
                            }}
                        >
                            <img src={soc_icon1} alt="youtube"/>
                        </div>
                        <div
                            className={`${Styles.socialsBoxItem}`}
                            onClick={() => {
                                window.open("https://discord.gg/YWMJkwkg74", "_blank");
                            }}
                        >
                            <img src={soc_icon3} alt="discord"/>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.logOutBtn}`} onClick={()=>{dispatch(logout())}}>
                    <img src={log_out_icon} alt="logout"/>
                    <span>{t('Выход')}</span>
                </div>
                <LangSwitcher/>
            </div>
        </section>
    );
};

export default WalletNavigation;
