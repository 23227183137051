import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import SettingsMain from "../../components/settingsMain";
import SettingsSupport from "../../components/settingsSupport";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";


const SettingsPage = () => {
    const navigate = useNavigate();
    const authStatus = useSelector(store => store.main.authorizationStatus);
    const { t } = useTranslation();

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Настройки')}/>
            <div className={`${Styles.contentWrap}`}>
                <SettingsMain/>
                <SettingsSupport/>
            </div>
        </section>
    );
};

export default SettingsPage;