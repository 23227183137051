import React, {useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {balanceTransactions, setBotBalanceStatus} from "../../store/wallet/action";

import exchangeIcon from '../../theme/assets/svg/omt_exchange_icon.svg';
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import CryptoIcon from "../_UI/cryptoIcon";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";


const BotBalance = () => {
    const dispatch = useDispatch();
    const botBalanceStatus = useSelector(store => store.wallet.botBalanceStatus);
    const takeWalletNetwork = useSelector(store => store.wallet.walletAssets);
    const { t } = useTranslation();

    const [sum, setSum] = useState('');
    const [isDep, setIsDep] = useState(botBalanceStatus.type);
    const [listStatus, setListStatus] = useState(false);
    const [currentToken, setCurrentToken] = useState(takeWalletNetwork?.[0] ? takeWalletNetwork[0] : null);

    const closeModal = () =>{
        dispatch(setBotBalanceStatus({status: false, type: false, balance: false, withdrawBalance: false}));
        setSum('');
    }

    const mainBtnHandler = () =>{
        if (sum >= 0.1){
            dispatch(balanceTransactions(!isDep, 'bot', currentToken.currency.id, sum));
            closeModal();
            toast(t('Ваш перевод на баланс бота успешно отправлен, ожидайте завершения транзакции!'));
        }
    }

    useEffect(()=>{
        setIsDep(botBalanceStatus.type)
    },[botBalanceStatus])

    useEffect(()=>{
        setCurrentToken(takeWalletNetwork?.[0] ? takeWalletNetwork[0] : null)
    },[takeWalletNetwork])

    const contentHandler = () =>{
        return (
            <div className={`${Styles.listPosWrap}`}>
                <div className={`${Styles.listBox}`}>
                    <div
                        className={`${Styles.listBtn} ${listStatus ? Styles.listBtnActive : null}`}
                        onClick={()=>{setListStatus(!listStatus)}}
                    >
                        <img src={listArrowIcon} alt="list arrow"/>
                    </div>
                    <div className={`${Styles.listResult}`}>
                        <div className={`${Styles.listResultImgWrap}`}>
                            <CryptoIcon short_name={currentToken?.currency?.short_name}/>
                        </div>
                        <div className={`${Styles.listResultText}`}>{currentToken?.currency?.name}</div>
                        <div className={`${Styles.listResultText} ${Styles.listResultTextBalance}`}>{currentToken?.amount * 1} {currentToken?.currency?.short_name}</div>
                    </div>
                </div>
                <div className={`${Styles.abslistWrap} ${listStatus ? Styles.abslistWrapActive : null}`}>
                    <div className={`${Styles.abslistBorderWrap}`}>
                        <div className={`${Styles.abslistBox}`}>
                            {takeWalletNetwork ? takeWalletNetwork.map((item, index)=>{
                                return (
                                    <div
                                        className={`${Styles.listBoxItem}`}
                                        key={'walletNetwork' + index}
                                        onClick={()=>{
                                            setCurrentToken(item);
                                            setListStatus(false);
                                        }}
                                    >
                                        <div className={`${Styles.listBoxItemImgWrap}`}>
                                            <CryptoIcon short_name={item?.currency?.short_name}/>
                                        </div>
                                        <div className={`${Styles.listBoxItemText}`}>{item?.currency?.name}</div>
                                        <div className={`${Styles.listBoxItemText} ${Styles.listBoxItemTextBalance}`}>{item.amount * 1} {item?.currency?.short_name}</div>
                                    </div>
                                )
                            }) : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Popup
            open={botBalanceStatus.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.formWrap}`}>
                        <div className={`${Styles.formTitle}`}>{t('Внутренний перевод')}</div>
                        <div className={`${Styles.exchangeWrap}`}>
                            <div className={`${Styles.infoWrap}`}>
                                <div className={`${Styles.infoWrapText}`}>{t('От куда')}</div>
                                <div className={`${Styles.infoWrapLineBox}`}>
                                    <div className={`${Styles.infoWrapLineBoxItem}`}/>
                                    <div className={`${Styles.infoWrapLineBoxItem}`}/>
                                    <div className={`${Styles.infoWrapLineBoxItem}`}/>
                                    <div className={`${Styles.infoWrapLineBoxItem}`}/>
                                    <div className={`${Styles.infoWrapLineBoxItem}`}/>
                                    <div className={`${Styles.infoWrapLineBoxItem}`}/>
                                </div>
                                <div className={`${Styles.infoWrapText}`}>{t('Куда')}</div>
                            </div>
                            <div className={`${Styles.valuesWrap}`}>
                                <div className={`${Styles.valuesItem}`}>
                                    {isDep ? contentHandler() : t('Бот')}
                                </div>
                                <div className={`${Styles.valuesItem}`}>
                                    {isDep ? t('Бот') : contentHandler()}
                                </div>
                            </div>
                            <div className={`${Styles.btnWrap}`}>
                                <div className={`${Styles.btn}`} onClick={()=>{setIsDep(!isDep)}}>
                                    <img src={exchangeIcon} alt="exchange"/>
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.inputWrap}`}>
                            <div className={`${Styles.inputBox}`}>
                                <input
                                    type="number"
                                    placeholder={`${t('Количество от')} 0.1 ${t('до')} ${isDep ? currentToken?.amount * 1 : botBalanceStatus.withdrawBalance}`}
                                    onChange={(e)=>{
                                        if (e.target.value < 0){
                                            setSum(0)
                                        } else if (e.target.value > Number(isDep ? currentToken?.amount * 1 : botBalanceStatus.withdrawBalance)){
                                            setSum(isDep ? currentToken?.amount : botBalanceStatus.withdrawBalance)
                                        } else {
                                            setSum(e.target.value)
                                        }
                                    }}
                                    className={`${Styles.inputSum}`}
                                    value={sum}
                                />
                                <div className={`${Styles.inputAbs}`} onClick={()=>{setSum(isDep ? currentToken?.amount * 1 : botBalanceStatus.withdrawBalance)}}>
                                    <span className={`${Styles.inputAbsCur}`}>{currentToken?.currency?.short_name}</span>
                                    <span className={`${Styles.inputAbsBtn}`}>{t('Все')}</span>
                                </div>
                                <div className={`${Styles.inputNote}`}>{t('Доступный баланс')}: <span>{isDep ? currentToken?.amount * 1 : botBalanceStatus.withdrawBalance} {currentToken?.currency?.short_name}</span></div>
                            </div>
                        </div>
                        <div className={`${Styles.mainBtn} ${sum >= 0.1 ? Styles.mainBtnMActive : null}`} onClick={()=>{mainBtnHandler()}}>{t('Перевод')}</div>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default BotBalance;
