import React, {useCallback, useEffect, useState} from "react";
import Styles from './styles.module.scss'
import ReactECharts from 'echarts-for-react';

import * as echarts from 'echarts/core';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getHoneechizeEarningsGraphData} from "../../store/wallet/action";



const HoneechizeMetricsEarningsGraph = (props) => {
    const {isToken} = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const graphData = useSelector(store => store.wallet.honeechizeEarningsGraphData);
    
    const [currentPeriod, setCurrentPeriod] = useState('day');
    const [correctData, setCorrectData] = useState(false);
    


    const option = {
        color: [`${isToken ? '#F7931A' : '#03b759'}`],
        // responsive: true,
        // maintainAspectRatio: false,
        tooltip: {
            formatter: function(params) {
                const date = correctData.names[params[0].dataIndex]; // Получаем дату из массива дат
                const value = (params[0].value - correctData?.maxValue * 0.1).toFixed(6) * 1; // Получаем значение точки на графике

                // Возвращаем пользовательскую строку для подсказки, включающую дату и значение
                return `${date}<br />Value (USD): ${value}`;
            },
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            },
        },
        legend: false,
        grid: {
            left: '0',
            right: '0',
            bottom: '0',
            height: '190px',
            containLabel: false
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: correctData ? correctData?.names : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                show: false,
                splitLine: {
                    show: false
                }
            },
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: false
                },
            }
        ],
        series: [
            {
                name: 'Value (USD)',
                type: 'line',
                stack: 'Total',
                smooth: false,
                lineStyle: {
                    color: `${isToken ? '#F7931A' : '#03b759'}`,
                    width: 2
                },
                showSymbol: false,
                areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: `${isToken ? 'rgba(101,78,50,255)' : 'rgba(46,93,68,255)'}`
                        },
                        {
                            offset: 1,
                            color: `${isToken ? 'rgba(36,24,9,255)' : 'rgba(8,35,21,255)'}`
                        }
                    ])
                },
                emphasis: {
                    focus: 'series'
                },
                connectNulls: true,
                data: correctData ? correctData?.values : [0, 0, 0, 0, 0, 0, 0]
            }
        ]
    };

    const graphDataHandler = useCallback((data)  =>{
        let names = [];
        let values = [];
        let maxValue = 0;

        data.forEach((item)=>{
            if (item.value > maxValue){
                maxValue = item.value
            }
        })

        data.forEach((item)=>{
            names.push(String(currentPeriod === 'day' ? item.date + ':00' : item.date))
            values.push(item.value + maxValue * 0.1);
        })

        if (names.length > 0 && values.length > 0){
            setCorrectData({names, values, maxValue})
        }
    }, [currentPeriod])
    
    useEffect(()=>{
        if (graphData){
            graphDataHandler(graphData)
        }
    },[graphData, graphDataHandler])

    useEffect(()=>{
        dispatch(getHoneechizeEarningsGraphData(currentPeriod))
    },[currentPeriod, dispatch])
    
    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`} style={{borderColor: `#8C9497`}}>
                <div className={`${Styles.cardHeader}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title3}`}>{t('Ваш заработок за')}:</div>
                        <div className={`${Styles.filterWrap}`}>
                            <div
                                className={`${Styles.filterItem} ${currentPeriod === 'day' ? Styles.filterItemActive : null}`}
                                onClick={()=>{setCurrentPeriod('day')}}
                            >
                                {t('День')}
                            </div>
                            <div
                                className={`${Styles.filterItem} ${currentPeriod === 'week' ? Styles.filterItemActive : null}`}
                                onClick={()=>{setCurrentPeriod('week')}}
                            >
                                {t('Неделя')}
                            </div>
                            <div
                                className={`${Styles.filterItem} ${currentPeriod === 'month' ? Styles.filterItemActive : null}`}
                                onClick={()=>{setCurrentPeriod('month')}}
                            >
                                {t('Месяц')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.chartWrap}`}>
                        <ReactECharts option={option} style={{ height: '210px', width: '100%'}}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HoneechizeMetricsEarningsGraph;
