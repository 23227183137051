import React from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";

import chainIcon from '../../theme/assets/svg/omt_ambassadors_chain.svg';
import questionIcon from '../../theme/assets/svg/omt_ambassadors_question.svg';
import {useTranslation} from "react-i18next";

import i18n from "i18next";



const AmbassadorsProgress = () => {
    const { t } = useTranslation();
    console.log(i18n.language)

    const ambassadorMainData = useSelector(store => store.wallet.ambassadorMainData);

    const progressTypeHandler = () =>{
         return Number(ambassadorMainData?.referral_turnover) >= 100000
    }

    const percentBarHandler = () =>{
        let maxSum = progressTypeHandler() ? 2300000 : 100000;
        let currentPercent;
        currentPercent = ((Number(ambassadorMainData?.referral_turnover).toFixed(2) * 1) / maxSum) * 100;

        if (currentPercent > 100){
            return 101
        } else if (currentPercent > 5){
            return currentPercent
        } else if (currentPercent < 5 && currentPercent > 0){
            return 5
        } else {
            return 0
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card} ${progressTypeHandler() ? Styles.cardOrange : null}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.textWrap}`}>
                        <div className={`${Styles.titleBox}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={chainIcon} alt={t('Honeechize прогресс')}/>
                            </div>
                            <div className={`${Styles.text}`}>{t('Honeechize прогресс')}</div>
                        </div>
                        <div
                            className={`${Styles.linkBox}`}
                            onClick={()=>{
                                if (i18n.language === 'ru'){
                                    window.open(`https://api.omt.technology/honeechize/ru.pdf`, "_blank")
                                } else {
                                    window.open(`https://api.omt.technology/honeechize/en.pdf`, "_blank")
                                }
                            }}
                        >
                            {t('Подробнее о Honeechize')}
                            <div className={`${Styles.imgWrap}`}>
                                <img src={questionIcon} alt={t('Подробнее о Honeechize')}/>
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.barWrap}`}>
                        <div className={`${Styles.botLevel} ${progressTypeHandler() ? Styles.botLevelOrange : null}`}>
                            <div className={`${Styles.botLevelBar}`} style={{width: `${percentBarHandler()}%`}}/>
                            <div className={`${Styles.botLevelText}`}>
                                ${ambassadorMainData?.referral_turnover ? Number(ambassadorMainData?.referral_turnover).toFixed(2) * 1 : '0'} / ${progressTypeHandler() ? '2300000' : '100000'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AmbassadorsProgress;
