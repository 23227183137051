import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import Seo from "../../components/_UI/seo";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {loginWithHash, loginWithHashFailure} from "../../store/main/action";
import checkIcon from "../../theme/assets/svg/omt_check_icon.svg";
import {useTranslation} from "react-i18next";


const NewDevicePage = () => {
    let {hash} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);
    const hashIsNotAlive = useSelector(store => store.main.hashIsNotAlive);
    const formErr = useSelector(store => store.main.loginWithHashErr);

    const [checkStatus, setCheckStatus] = useState(false);
    const [phrase, setPhrase] = useState('');

    const sendPhraseHandler = () =>{
        if (phrase.length > 5){
            dispatch(loginWithHash({code: hash, phrase: phrase, dont_remember: checkStatus}))
        }
    }

    useEffect(()=>{
        if (authStatus.authorization){
            if (authStatus.phrase){
                navigate('/home');
            } else {
                navigate('/confirmation');
            }
        }
    },[authStatus, navigate])

    useEffect(()=>{
        if (hashIsNotAlive){
            navigate('/sign-in')
        }
    },[hashIsNotAlive, navigate])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'Security service'}/>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title}`}>{t('Служба безопасности')}</div>
                <div className={`${Styles.noteWrap}`}>
                    <div className={`${Styles.noteItem}`}>
                        {t('Мы заметили, что вы входите в систему с нового устройства или IP-адреса. Для обеспечения безопасности вашей учетной записи просим вас ввести свою фразу восстановления для дополнительной проверки.')}
                    </div>
                    <div className={`${Styles.noteItem}`}>
                        {t('Обратите внимание: этот шаг помогает нам гарантировать, что доступ к вашей учетной записи предоставлен именно вам. Мы ценим вашу конфиденциальность и заботимся о безопасности ваших данных.')}
                    </div>
                </div>
                <div className={`${Styles.checkRow}`}>
                    <div className={`${Styles.checkBox}`} onClick={()=>{setCheckStatus(!checkStatus)}}>
                        {checkStatus ? <img src={checkIcon} alt="check accept"/> : null}
                    </div>
                    <div className={`${Styles.checkRowContent}`}>{t('Не запоминать устройство')}</div>
                </div>
                <div className={`${Styles.inputWrap} ${formErr?.phrase ? Styles.inputWrapWithErr : null}`}>
                    <div className={`${Styles.inputTitle}`}>{t('Для подтверждения введите фразу восстановления:')}</div>
                    <input
                        type="text"
                        value={phrase}
                        onChange={(e)=>{setPhrase(e.target.value)}}
                        onKeyDown={(event)=>{
                            if (formErr?.phrase){
                                delete formErr.phrase;
                                dispatch(loginWithHashFailure(formErr));
                            }
                        }}
                    />
                    <div className={`${Styles.inputErr}`}>{formErr?.phrase ? formErr.phrase : null}</div>
                </div>
                <div className={`${Styles.btn} ${phrase.length > 5 ? Styles.btnActive : null}`} onClick={()=>{sendPhraseHandler()}}>{t('Отправить')}</div>
            </div>
        </section>
    );
};

export default NewDevicePage;
