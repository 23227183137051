import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import honeeIcon from "../../theme/assets/crypto/omt_crypto_honee_icon.svg";
import botIcon from '../../theme/assets/svg/omt_bot_icon.svg';
import {useNavigate} from "react-router-dom";
import Moment from "react-moment";
import {getBotSubscribeInfo, getCustomSettings, setBotSubscribeModalStatus} from "../../store/wallet/action";



const BotMain = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const botMainData = useSelector(store => store.wallet.botMainData);
    const { t } = useTranslation();
    
    // useEffect(()=>{
    //     if (botMainData){
    //         if (botMainData?.subscription?.subscription?.name === 'Pro'){
    //             dispatch(getCustomSettings())
    //         } else {
    //             dispatch(getBotSubscribeInfo());
    //         }
    //     }
    // },[botMainData, dispatch])

    useEffect(()=>{
        if (botMainData){
            dispatch(getBotSubscribeInfo());
            if (botMainData?.subscription?.subscription?.name === 'Pro'){
                dispatch(getCustomSettings())
            }
        }
    },[botMainData, dispatch])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.infoWrap}`}>
                        <div className={`${Styles.infoWrapTitleRow} ${botMainData?.subscription ? Styles.infoWrapTitleRowWB : null}`}>
                            <div className={`${Styles.infoWrapTitleRowIconWrap}`}>
                                <img src={botIcon} alt="Honee AI"/>
                            </div>
                            <div className={`${Styles.title2} ${Styles.colorOrange}`}>HoneeAI</div>
                            {botMainData?.subscription ? <div className={`${Styles.btn}`} onClick={()=>{dispatch(setBotSubscribeModalStatus(true))}}>{t('Продлить подписку')}</div> : null}
                        </div>

                        <div className={`${Styles.infoWrapNote}`}>
                            {t('Наш исĸусственный интеллеĸт, обеспечивающий торговлю на биржах вместо Вас! Подробную информацию Вы можете найти')} <span className={`${Styles.colorOrange}`} onClick={()=>{window.open("https://omt.technology/white-paper/honee-ai", "_blank")}}>{t('здесь')}</span>
                        </div>
                        <div className={`${Styles.infoWrapSubscribe}`}>
                            <div className={`${Styles.subscribe} ${botMainData?.subscription ? Styles.subscribeActive : null}`}>
                                {t('Тип подписĸи')}: {botMainData?.subscription ? <span className={botMainData?.subscription?.subscription?.name === 'Pro' ? Styles.colorOrange : null}>{botMainData?.subscription?.subscription?.name}{botMainData?.subscription?.status !== 'active' ? ` (${botMainData?.subscription?.status})` : ''}</span> : '-'}
                            </div>
                            <div className={`${Styles.subscribe} ${botMainData?.subscription ? Styles.subscribeActive : null}`}>
                                {t('Подписĸа истеĸает')}: {botMainData?.subscription?.date_to ? <span className={`${Styles.colorGreen}`}><Moment format="DD/MM/YYYY">{botMainData?.subscription?.date_to}</Moment></span> : '-'}
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentWrap}`}>
                        <div className={`${Styles.contentWrapItem}`}>
                            <div className={`${Styles.title2}`}>{t('Баланс')}</div>
                            <div className={`${Styles.contentWrapItemValue}`}>
                                <div className={`${Styles.contentWrapItemValueIconWrap}`}>
                                    <img src={honeeIcon} alt="Honee"/>
                                </div>
                                {botMainData?.wallet?.amount ? Number(botMainData?.wallet?.amount).toFixed(2) * 1 : '0'}
                            </div>
                            <div className={`${Styles.contentWrapItemTextWrap}`}>
                                {/*<div className={`${Styles.contentWrapItemTextWrapRow}`}>{t('Статус')}: <span>{botMainData?.bot?.is_active ? t('Активен') : t('Не активен')}</span></div>*/}
                                <div className={`${Styles.contentWrapItemTextWrapRow}`}>{t('Статус')}: <span>{botMainData?.bot?.is_active && botMainData?.subscription ? t('Активен') : t('Не активен')}</span></div>
                                <div className={`${Styles.contentWrapItemTextWrapRow}`}>{t('Текущий уровень бота')}: <span>{botMainData?.bot?.level?.level ? botMainData?.bot?.level?.level : '0'}</span></div>
                            </div>
                        </div>
                        <div className={`${Styles.contentWrapItem}`}>
                            <div className={`${Styles.title2}`}>{t('В работе')}</div>
                            <div className={`${Styles.contentWrapItemValue}`}>
                                <div className={`${Styles.contentWrapItemValueIconWrap}`}>
                                    <img src={honeeIcon} alt="Honee"/>
                                </div>
                                {botMainData?.bot?.amount ? Number(botMainData?.bot?.amount).toFixed(2) * 1 : '0'}
                            </div>
                        </div>
                        <div className={`${Styles.contentWrapItem}`}>
                            <div className={`${Styles.title2}`}>{t('Заработано')}</div>
                            <div className={`${Styles.contentWrapItemValue}`}>
                                <div className={`${Styles.contentWrapItemValueIconWrap}`}>
                                    <img src={honeeIcon} alt="Honee"/>
                                </div>
                                {botMainData?.bot?.profit ? Number(botMainData?.bot?.profit).toFixed(2) * 1 : '0'}
                            </div>
                            <div className={`${Styles.contentWrapItemBtnWrap}`}>
                                <div className={`${Styles.contentWrapItemBtn}`} onClick={()=>{navigate('/exchange')}}>
                                    {t('Обмен')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BotMain;
