import React from "react";
import Styles from './styles.module.scss';
import Slider from "react-slick";

// import mediaIcon1 from '../../theme/assets/svg/omt_main_media_icon1.svg';
// import mediaIcon2 from '../../theme/assets/svg/omt_main_media_icon2.svg';
import mediaIcon3 from '../../theme/assets/svg/omt_main_media_icon3.svg';
import mediaIcon4 from '../../theme/assets/svg/omt_main_media_icon4.svg';
import mediaIcon5 from '../../theme/assets/svg/omt_main_media_icon5.svg';
import mediaIcon6 from '../../theme/assets/svg/omt_main_media_icon6.svg';
import mediaIcon7 from '../../theme/assets/svg/omt_main_media_icon7.svg';
import mediaIcon8 from '../../theme/assets/svg/omt_main_media_icon8.svg';
import mediaIcon9 from '../../theme/assets/svg/omt_main_media_icon9.svg';
import mediaIcon10 from '../../theme/assets/svg/omt_main_media_icon10.svg';
import mediaIcon11 from '../../theme/assets/svg/omt_main_media_icon11.svg';
import mediaIcon12 from '../../theme/assets/svg/omt_main_media_icon12.svg';
import mediaIcon13 from '../../theme/assets/svg/omt_main_media_icon13.svg';
import mediaIcon14 from '../../theme/assets/svg/omt_main_media_icon14.svg';
import {useTranslation} from "react-i18next";


const MainMedia = () => {
    const { t } = useTranslation();

    const showSliderHandler = () =>{
        if (window.screen.width < 768 ){
            return 1
        } else if(window.screen.width >= 768 && window.screen.width < 1920){
            return 1
        } else {
            return 1
        }
    }

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: showSliderHandler(),
        slidesToScroll: showSliderHandler(),
        autoplay: false,
        arrows: false,
        // beforeChange: (current, next) => dispatch(setCurrentWallet(next)),
        // afterChange: current => console.log({ activeSlide2: current })
    };

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title1}`}>{t('Медиа')} <span className={`${Styles.colorBlue}`}>{t('о нас')}</span></div>
                <div className={`${Styles.contentBox} ${Styles.sliderWrap}`}>
                    <Slider {...settings}>
                        <div className={`${Styles.sliderItem}`}>
                            <div className={`${Styles.contentBoxRow}`}>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://coinmarketcap.com/currencies/oracle-meta-technologies/", "_blank");
                                        }}>
                                            <img src={mediaIcon9} alt="coinmarketcap"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://www.coingecko.com/en/coins/oracle-meta-technologies", "_blank");
                                        }}>
                                            <img src={mediaIcon10} alt="coingecko"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${Styles.contentBoxRow}`}>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://markets.businessinsider.com/news/stocks/oracle-meta-technologies-launches-innovative-crypto-index-with-digital-asset-management-1033535692", "_blank");
                                        }}>
                                            <img src={mediaIcon11} alt="business insider"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://apnews.com/press-release/marketersmedia/meta-platforms-inc-cryptocurrency-casper-6a59113014a5e50d61311cb81c2baf23", "_blank");
                                        }}>
                                            <img src={mediaIcon12} alt="apnews"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://www.morningstar.com/news/accesswire/886103msn/oracle-meta-technologies-launches-innovative-crypto-index-with-digital-asset-management", "_blank");
                                        }}>
                                            <img src={mediaIcon13} alt="morningstar"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://finance.yahoo.com/news/oracle-meta-technologies-launches-innovative-123000112.html", "_blank");
                                        }}>
                                            <img src={mediaIcon4} alt="finance yahoo"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://www.marketwatch.com/press-release/oracle-meta-technologies-launches-innovative-crypto-index-with-digital-asset-management-ed025109?mod=search_headline", "_blank");
                                        }}>
                                            <img src={mediaIcon14} alt="marketwatch"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://www.digitaljournal.com/pr/news/accesswire/oracle-meta-technologies-launches-innovative-128695230.html", "_blank");
                                        }}>
                                            <img src={mediaIcon5} alt="digital journal"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.sliderItem}`}>
                            <div className={`${Styles.contentBoxRow}`}>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://coinmarketcap.com/currencies/oracle-meta-technologies/", "_blank");
                                        }}>
                                            <img src={mediaIcon9} alt="coinmarketcap"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://www.coingecko.com/en/coins/oracle-meta-technologies", "_blank");
                                        }}>
                                            <img src={mediaIcon10} alt="coingecko"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${Styles.contentBoxRow}`}>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://www.streetinsider.com/Accesswire/Oracle+Meta+Technologies+Redefine+the+Future+of+Digital+Assets+with+Innovation+in+Cryptocurrency/22013193.html", "_blank");
                                        }}>
                                            <img src={mediaIcon3} alt="streetinsider"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://finance.yahoo.com/cryptocurrencies/oracle-meta-technologies-redefine-future-193000179.html", "_blank");
                                        }}>
                                            <img src={mediaIcon4} alt="finance yahoo"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://www.digitaljournal.com/pr/news/accesswire/oracle-meta-technologies-redefine-the-future-of-digital-assets-with-innovation-in-cryptocurrency", "_blank");
                                        }}>
                                            <img src={mediaIcon5} alt="digital journal"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://markets.businessinsider.com/news/stocks/oracle-meta-technologies-redefine-the-future-of-digital-assets-with-innovation-in-cryptocurrency-1032531385", "_blank");
                                        }}>
                                            <img src={mediaIcon6} alt="markets business insider"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://www.benzinga.com/pressreleases/23/08/33642073/oracle-meta-technologies-redefine-the-future-of-digital-assets-with-innovation-in-cryptocurrency", "_blank");
                                        }}>
                                            <img src={mediaIcon7} alt="benzinga"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${Styles.contentBoxRowItem}`}>
                                    <div className={`${Styles.borderWrap}`}>
                                        <div className={`${Styles.imgWrap}`} onClick={()=>{
                                            window.open("https://happycoin.club/omt-pervyj-kriptokoshelyok-s-integrirovannym-ii-s-akczentom-na-bezopasnost/", "_blank");
                                        }}>
                                            <img src={mediaIcon8} alt="happycoin"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default MainMedia;
