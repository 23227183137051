import React from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";

import omtIcon from '../../theme/assets/crypto/omt_crypto_usdt_icon.svg';
import honeeIcon from '../../theme/assets/crypto/omt_crypto_honee_icon.svg';
import questionIcon from '../../theme/assets/svg/omt_link_question.svg';

import {setAmbassadorsModalSalaryStatus, setAmbassadorsModalStatus} from "../../store/wallet/action";
import {useTranslation} from "react-i18next";



const AmbassadorsMain = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const ambassadorMainData = useSelector(store => store.wallet.ambassadorMainData);

    const percentBarHandler = (isSalary) =>{
        let currentPercent;
        if (isSalary){
            currentPercent = ((Number(ambassadorMainData?.salary_turnover).toFixed(2) * 1) / (Number(ambassadorMainData?.nextSalary?.monthly_referral_turnover).toFixed(2) * 1)) * 100;
        } else {
            currentPercent = ((Number(ambassadorMainData?.referral_turnover).toFixed(2) * 1) / (Number(ambassadorMainData?.next_status?.trading_volume_structure).toFixed(2) * 1)) * 100;
        }

        if (currentPercent > 100){
            return 101
        } else if (currentPercent > 5){
            return currentPercent
        } else if (currentPercent < 5 && currentPercent > 0){
            return 5
        } else {
            return 0
        }
    }

    const statusColor = () => {
        if (ambassadorMainData?.influencer) {
            return '#FD00D5';
        }
        return ambassadorMainData?.current_status?.color ? ambassadorMainData?.current_status?.color : '#8C9497';
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`} style={{border: `1px solid ${statusColor()}`}}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.contentWrapItem}`}>
                        <div className={`${Styles.title2}`}>
                            {`${t('Ваш статус')}: `}
                            <span className={`${Styles.contentWrapItemColor}`} style={{color: `${statusColor()}`}}
                            >
                            {ambassadorMainData?.influencer ? 'Influencer' : ambassadorMainData?.current_status?.title ? ambassadorMainData?.current_status?.title : ' '}
                        </span>
                        </div>
                        <div className={`${Styles.contentWrapItemDetailsBtn}`} onClick={()=>{dispatch(setAmbassadorsModalStatus(true))}}>
                            <div className={`${Styles.text}`}>
                                {t('Подробнее про статусы')}
                            </div>
                            <img src={questionIcon} alt="question icon"/>
                        </div>
                        {/*<div className={`${Styles.contentWrapItemDetailsBtn}`} onClick={()=>{ window.location.href='https://api.omt.technology/presentation_eng.pdf'}}>About</div>*/}
                        <div className={`${Styles.contentWrapItemDetailsWrap}`}>
                            <div className={`${Styles.contentWrapItemDetailsItem}`}>
                                <div className={`${Styles.title3}`} style={{color: `${statusColor()}`}}>{t('Бонус')}:</div>
                                <div className={`${Styles.contentWrapItemDetailsItemValue}`}>
                                    {ambassadorMainData?.current_status?.bonus_amount ? Number(ambassadorMainData?.current_status?.bonus_amount) : '0'} Honee
                                </div>
                            </div>
                            <div className={`${Styles.contentWrapItemDetailsItem}`}>
                                <div className={`${Styles.title3}`} style={{color: `${statusColor()}`}}>{t('Партнёрсĸий депозитный бонус')}:</div>
                                <div className={`${Styles.contentWrapItemDetailsItemValue}`}>
                                    {ambassadorMainData?.current_status?.bot_deposit_bonuses?.join('%-')}%
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.title3}`}>{t('Структура объема торгов')}:</div>
                        <div className={`${Styles.botLevel}`} style={{border: `1px solid ${statusColor()}`}}>
                            <div className={`${Styles.botLevelBar}`} style={{width: `${percentBarHandler()}%`, background: `${statusColor()}`}}/>
                            <div className={`${Styles.botLevelText}`}>
                                ${ambassadorMainData?.referral_turnover ? Number(ambassadorMainData?.referral_turnover).toFixed(2) * 1 : '0'} / ${ambassadorMainData?.next_status?.trading_volume_structure ? Number(ambassadorMainData?.next_status?.trading_volume_structure).toFixed(2) * 1 : '0'}
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <div className={`${Styles.contentWrapItemDetailsWrap} ${Styles.contentWrapItemDetailsWrapRePos}`}>
                            <div className={`${Styles.contentWrapItemDetailsItem}`}>
                                <div className={`${Styles.title3}`} style={{color: `${statusColor()}`}}>{t('Ваши привилегии')}:</div>
                                <div className={`${Styles.contentWrapItemDetailsItemValue}`}>{ambassadorMainData?.current_status?.promo_codes?.[0]?.count} {t('промокод на')} 3%</div>
                                <div className={`${Styles.contentWrapItemDetailsItemValue}`}>{ambassadorMainData?.current_status?.promo_codes?.[1]?.count} {t('промокод на')} 5%</div>
                            </div>
                            <div className={`${Styles.contentWrapItemDetailsItem}`}>
                                <div className={`${Styles.title3}`} style={{color: `${statusColor()}`}}>{t('Партнёрсĸий бонус от прибыли')}:</div>
                                <div className={`${Styles.contentWrapItemDetailsItemValue}`}>{ambassadorMainData?.referral_levels?.join('%-')}%</div>
                            </div>
                        </div>
                        <div className={`${Styles.title3} ${Styles.customMargin}`}>{t('Результат')}: <span onClick={()=>{dispatch(setAmbassadorsModalSalaryStatus(true))}}>{t('Подробнее об оплатах')}<img src={questionIcon} alt="question icon"/></span></div>
                        <div className={`${Styles.botLevel}`} style={{border: `1px solid #03B759`}}>
                            <div className={`${Styles.botLevelBar}`} style={{width: `${percentBarHandler(true)}%`, background: `#03B759`}}/>
                            <div className={`${Styles.botLevelText}`}>
                                ${ambassadorMainData?.salary_turnover ? Number(ambassadorMainData?.salary_turnover).toFixed(2) * 1 : '0'} / ${ambassadorMainData?.nextSalary?.monthly_referral_turnover ? Number(ambassadorMainData?.nextSalary?.monthly_referral_turnover).toFixed(2) * 1 : '0'}
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <div className={`${Styles.contentWrapItemRewardsWrap}`}>
                            <div className={`${Styles.contentWrapItemRewardsItem}`}>
                                <div className={`${Styles.title2} ${Styles.colorOrange}`}>{t('Награды')}:</div>
                                <div className={`${Styles.title3}`}>Honee</div>
                                <div className={`${Styles.contentWrapItemRewardsItemBox}`}>
                                    <div className={`${Styles.contentWrapItemRewardsItemIconWrap}`}>
                                        <img src={honeeIcon} alt="Honee"/>
                                    </div>
                                    {ambassadorMainData?.rewards?.HONEE ? Number(ambassadorMainData?.rewards?.HONEE).toFixed(2) * 1 : '0'}
                                </div>
                            </div>
                            <div className={`${Styles.contentWrapItemRewardsItem}`}>
                                <div className={`${Styles.title2} ${Styles.colorGreen}`}>{t('Зарплата')}:</div>
                                <div className={`${Styles.title3}`}>USDT</div>
                                <div className={`${Styles.contentWrapItemRewardsItemBox}`}>
                                    <div className={`${Styles.contentWrapItemRewardsItemIconWrap}`}>
                                        <img src={omtIcon} alt="OMT tokens"/>
                                    </div>
                                    {ambassadorMainData?.salary?.reward ? Number(ambassadorMainData?.salary?.reward).toFixed(2) * 1 : '0'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AmbassadorsMain;
