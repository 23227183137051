import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import HoneechizeNavigation from "../../components/honeechizeNavigation";
import HoneechizeMain from "../../components/honeechizeMain";
import {
    getBotMainData,
    getCustomSettings,
    getHoneechizeBotEarnings, getHoneechizeBotSettings,
    getHoneechizeMainData
} from "../../store/wallet/action";
import HoneechizeBotEarnings from "../../components/honeechizeBotEarnings";
import HoneechizeBotSettings from "../../components/honeechizeBotSettings";



const HoneechizeBotPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authStatus = useSelector(store => store.main.authorizationStatus);
    const botMainData = useSelector(store => store.wallet.botMainData);


    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getBotMainData());
        dispatch(getHoneechizeMainData());
        dispatch(getHoneechizeBotEarnings());
        dispatch(getHoneechizeBotSettings());
    },[dispatch])

    useEffect(()=>{
        if (botMainData?.subscription?.subscription?.name === 'Pro'){
            dispatch(getCustomSettings())
        }
    },[botMainData, dispatch])



    return (
        <section className={`${Styles.container}`}>
            <Seo title={'Bot settings'}/>
            <div className={`${Styles.contentWrap}`}>
                <HoneechizeNavigation/>
                <HoneechizeMain/>
                <HoneechizeBotEarnings/>
                <HoneechizeBotSettings/>
            </div>
        </section>
    );
};

export default HoneechizeBotPage;