import React from "react";
import Styles from './styles.module.scss';

import started_img from '../../theme/assets/svg/omt_main_started.svg';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


const MainStart = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title1}`}>{t('Лёгĸий старт с')} <span className={`${Styles.colorBlue}`}>OMT</span></div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.contentBoxItemImgWrap}`}>
                            <img src={started_img} alt="Регистрация"/>
                            <div className={`${Styles.contentBoxItemAbsNum}`}>1</div>
                        </div>
                        <div className={`${Styles.contentBoxItemText}`}>{t('Регистрация')}</div>
                    </div>
                    <div className={`${Styles.contentBoxSep}`}/>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.contentBoxItemImgWrap}`}>
                            <img src={started_img} alt="Создание ĸошельĸа"/>
                            <div className={`${Styles.contentBoxItemAbsNum}`}>2</div>
                        </div>
                        <div className={`${Styles.contentBoxItemText}`}>{t('Создание ĸошельĸа')}</div>
                    </div>
                    <div className={`${Styles.contentBoxSep}`}/>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.contentBoxItemImgWrap}`}>
                            <img src={started_img} alt="Заработоĸ"/>
                            <div className={`${Styles.contentBoxItemAbsNum}`}>3</div>
                        </div>
                        <div className={`${Styles.contentBoxItemText}`}>{t('Генерация прибыли')}</div>
                    </div>
                </div>
                <div className={`${Styles.btn}`} onClick={()=>{navigate('/sign-up')}}>{t('Регистрация')}</div>
            </div>
        </section>
    );
};

export default MainStart;
