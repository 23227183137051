import React, {useCallback, useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {sendCommunityBoostPost, setCbTaskModalStatus} from "../../store/wallet/action";
import {useTranslation} from "react-i18next";


const CommunityBoostTaskModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const cbModalStatus = useSelector(store => store.wallet.cbModalStatus);
    const communityBoostMainData = useSelector(store => store.wallet.communityBoostMainData);
    const sendCbPostStarted = useSelector(store => store.wallet.sendCbPostStarted);


    const [post, setPost] = useState('');


    const sendPostHandler = () =>{
        if (post.length > 5 && !sendCbPostStarted){
            dispatch(sendCommunityBoostPost({url: post}))
        }
    }

    const fakeInputContent = (type, count) =>{
        switch (type){
            case 'user_registration':
                return <div className={`${Styles.fakeInput}`}><span className={`${Styles.fakeInputContent}`}>{t('Зарегистрировать')} <span>{count}</span> {t('новых пользователей')}</span></div>
            case 'bot_invest':
                return <div className={`${Styles.fakeInput}`}><span className={`${Styles.fakeInputContent}`}>{t('Запустить2')} <span>HoneeAI {count}</span> {t('партнерам')}</span></div>
            case 'exchange_omt':
                return <div className={`${Styles.fakeInput}`}><span className={`${Styles.fakeInputContent}`}>{t('Совершить')} <span>{t('Обмен')}</span> {t('на сумму минимум')} <span>${count}</span></span></div>
            case 'omt_staking':
                return <div className={`${Styles.fakeInput}`}><span className={`${Styles.fakeInputContent}`}>{t('Открыть')} <span>{t('Стейкинг')}</span> {t('на сумму не менее')} <span>{count} OMT</span></span></div>
            case 'post':
                return <input type="text" placeholder={`${t('Ссылка на пост')}: https//...`} readOnly={communityBoostMainData?.current_task?.order !== 5 || communityBoostMainData?.task_status === 'pending' || communityBoostMainData?.task_status === 'approved'} value={post} onChange={(e)=>{setPost(e.target.value)}}/>
            default:
                return null
        }
    }

    const btnContent = (order) =>{
        let type = false;
        if (order === communityBoostMainData?.current_task.order){
            type = 'do'
        } else if (order > communityBoostMainData?.current_task.order){
            type = 'unavailable'
        } else if (order < communityBoostMainData?.current_task.order){
            type = 'accept'
        }

        const styleHandler = (typeX) =>{
            switch (typeX){
                case 'do':
                    return <div className={`${Styles.taskBtn} ${Styles.taskBtnDo}`}>{t('Выполняется')}</div>
                case 'unavailable':
                    return <div className={`${Styles.taskBtn} ${Styles.taskBtnUnavailable}`}>{t('Недоступно')}</div>
                case 'accept':
                    return <div className={`${Styles.taskBtn} ${Styles.taskBtnAccept}`}>{t('Выполнено')}</div>
                default:
                    return null
            }
        }

        const styleInputHandler = (typeY) =>{
            switch (typeY){
                case 'empty':
                    return <div className={`${Styles.taskBtn} ${Styles.taskBtnDo} ${Styles.taskBtnP}`} onClick={()=>{sendPostHandler()}}>{t('Отправить')}</div>
                case 'pending':
                    return <div className={`${Styles.taskBtn} ${Styles.taskBtnDo}`}>{t('На проверке')}</div>
                case 'approved':
                    return <div className={`${Styles.taskBtn} ${Styles.taskBtnAccept}`}>{t('Выполнено')}</div>
                case 'canceled':
                    return <div className={`${Styles.taskBtn} ${Styles.taskBtnDo} ${Styles.taskBtnP}`} onClick={()=>{sendPostHandler()}}>{t('Отправить')}</div>
                default:
                    return null
            }
        }

        if (order === 5){
            return styleInputHandler(communityBoostMainData?.task_status)
        } else {
            return styleHandler(type)
        }
    }


    const closeModal = useCallback(() =>{
        dispatch(setCbTaskModalStatus(false));
    }, [dispatch])

    return (
        <Popup
            open={cbModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.title}`}>{t('Задания для раунда')} №{communityBoostMainData?.round?.number}</div>
                    <div className={`${Styles.taskWrap}`}>
                        {communityBoostMainData?.tasks ? communityBoostMainData?.tasks.map((item, index)=>{
                            return (
                                <div className={`${Styles.taskWrapItem}`} key={'taskN' + index}>
                                    <div className={`${Styles.taskWrapItemOrder}`}>{item.order === 5 ? `${item.order}. ${t('Разместить пост в соц. сетях')} ${t('об ОМТ с упоминанием программы')} ${t('Поддержка сообщества')}` : item.order}.</div>
                                    <div className={`${Styles.taskWrapItemRow}`}>
                                        <div className={`${Styles.inputBox}`}>
                                            {fakeInputContent(item.type, item.amount * 1)}
                                        </div>
                                        <div className={`${Styles.btnBox}`}>
                                            {btnContent(item.order)}
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : null}
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default CommunityBoostTaskModal;
