import axios from "axios";
import { store } from '../store';
import {confirmationClear, setAuthorizationStatus} from "../store/main/action";


axios.interceptors.request.use(function (config) {
    const lng = localStorage.getItem('omtLng');
    if (lng && config.url.indexOf('lang') === -1){
        if (config.url.indexOf('?') === -1){
            config.url = config.url + `?lang=${lng}`
        } else {
            config.url = config.url + `&lang=${lng}`
        }
    }
    return config;
}, function (error) {

    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {

    return response;
}, function (error) {
    if (error.response.status === 401 || error.response.status === 429){
        store.dispatch(setAuthorizationStatus({authorization: false, phrase: false}));
        store.dispatch(confirmationClear())
        localStorage.removeItem('omt-token');
        localStorage.removeItem('omt-phrase-status');
    }
    return Promise.reject(error);
});


export default axios;