import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getCommunityBoostLeaderboardData, getCommunityBoostMainData} from "../../store/wallet/action";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";
import CommunityBoostMain from "../../components/communityBoostMain";
import CommunityBoostRound from "../../components/communityBoostRound";
import CommunityBoostTaskModal from "../../components/communityBoostTaskModal";
import CommunityBoostLeaderBoard from "../../components/communityBoostLeaderBoard";
import CommunityBoostRewardModal from "../../components/communityBoostRewardModal";


const CommunityBoostPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])
    
    useEffect(()=>{
        dispatch(getCommunityBoostMainData());
        dispatch(getCommunityBoostLeaderboardData());
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Поддержка сообщества')}/>
            <div className={`${Styles.contentWrap}`}>
                <CommunityBoostMain/>
                <CommunityBoostRound round={1}/>
                <CommunityBoostRound round={2}/>
                <CommunityBoostRound round={3}/>
                <CommunityBoostLeaderBoard/>
                <CommunityBoostTaskModal/>
                <CommunityBoostRewardModal/>
            </div>
        </section>
    );
};

export default CommunityBoostPage;