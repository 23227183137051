import React from "react";
import Styles from './styles.module.scss'

import btn_arrow from '../../theme/assets/svg/omt_btn_arrow.svg';
import {setConfirmationStep} from "../../store/main/action";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";


const ConfirmationSecurity = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const btnHandler = () =>{
        dispatch(setConfirmationStep('2'));
        localStorage.setItem('omt-step', '2');
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title}`}>{t('Обеспечьте безопасность своего кошелька')}</div>
                <div className={`${Styles.subTitle}`}>{t('Для безопасности ваших средств и данных вам необходима секретная фраза')}:</div>
                <div className={`${Styles.note}`}>
                    {t('Ваша секретная фраза восстановления - это фраза из 12 слов, которая является "главным ключом" к вашему кошельку и вашим средствам')}.
                </div>
                <div className={`${Styles.noteTitle}`}>{t('Никогда, никогда не делитесь своей секретной фразой восстановления')}!</div>
                <div className={`${Styles.note}`}>
                    {t('Если кто-то попросит у вас фразу восстановления, он, скорее всего, пытается обмануть вас и украсть средства из вашего кошелька')}.
                </div>
                <div className={`${Styles.mainBtn}`} onClick={()=>{btnHandler()}}>
                    <span className={`${Styles.mainBtnContent}`}>{t('Далее')}</span>
                    <img src={btn_arrow} alt="arrow"/>
                </div>
            </div>
        </section>
    );
};

export default ConfirmationSecurity;
