import React from "react";
import Styles from './styles.module.scss'



const BotMassage = (props) => {
    const {text, styleStatus} = props;

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${styleStatus ? Styles.contentWrapOrange : null}`}>
                {text}
            </div>
        </section>
    );
};

export default BotMassage;
