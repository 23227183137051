import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {setBonusesModalStatus} from "../../store/wallet/action";
import {useTranslation} from "react-i18next";


const BonusesModal = () => {
    const dispatch = useDispatch();
    const bonusesModalStatus = useSelector(store => store.wallet.bonusesModalStatus);
    const refInfo = useSelector(store => store.wallet.refInfo);
    const refMainData = useSelector(store => store.wallet.refMainData);
    const { t } = useTranslation();

    const closeModal = () =>{
        dispatch(setBonusesModalStatus(false))
    }


    return (
        <Popup
            open={bonusesModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
            className='fixH'
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.title}`}>{t('Приглашайте друзей, чтобы зарабатывать вместе!')}</div>
                    <div className={`${Styles.subTitle}`}>{t('Реферальная программа OMT предлагает множество способов заработка для Вас и Вашей команды:')}</div>
                    <div className={`${Styles.tableWrap}`}>
                        <div className={`${Styles.titleRow}`}>
                            <div className={`${Styles.titleRowItem}`}>{t('Ранг')}</div>
                            <div className={`${Styles.titleRowItem}`}>{t('Объем торгов структуры')}</div>
                            <div className={`${Styles.titleRowItem}`}>{t('Личный объем торгов')}</div>
                            <div className={`${Styles.titleRowItem}`}>% {t('вознаграждения')}</div>
                        </div>
                        <div className={`${Styles.tableBox}`}>
                            {refInfo?.levels?.map((item, index)=>{
                                return (
                                    <div className={`${Styles.tableBoxRow}`} key={'refInfo' + index}>
                                        {/*<div className={`${Styles.tableBoxRowItem} ${Styles.tableBoxRowItemColored}`}><span className={`${Styles.desk}`}>Ранг</span> <span className={`${Styles.pos}`}> 0</span></div>*/}
                                        <div className={`${Styles.tableBoxRowItem} ${Styles.tableBoxRowItemColored}`}>{item?.name?.[item?.name?.length - 1]}</div>
                                        <div className={`${Styles.tableBoxRowItem}`}>{item.referral_turnover}$</div>
                                        <div className={`${Styles.tableBoxRowItem}`}>{item.personal_turnover}$</div>
                                        <div className={`${Styles.tableBoxRowItem}`}>
                                            {item.reward_percentage?.map((itemX, indexX)=>{
                                                if (indexX === item.reward_percentage.length - 1){
                                                    return (`${((itemX * 10) + (refInfo?.percent * 10)) / 10}%`)
                                                } else {
                                                    return (`${((itemX * 10) + (refInfo?.percent * 10)) / 10} - `)
                                                }
                                            })}
                                        </div>
                                    </div>
                                    )
                            })}
                        </div>
                    </div>
                    <div className={`${Styles.textWrap}`}>
                        <div className={`${Styles.textBox}`}>
                            <div className={`${Styles.textBoxTitle}`}>{t('1. За приглашение пользователя получайте наши токены*, для того что бы получить бонус ваш реферал должен:')}</div>
                            <div className={`${Styles.textBoxReqWrap}`}>
                                <div className={`${Styles.textBoxReqWrapItem}`}>{t('Зарегистрироваться;')}</div>
                                <div className={`${Styles.textBoxReqWrapItem}`}>{t('Пополнить счет.')}</div>
                            </div>
                            {/*<div className={`${Styles.textBoxSubTitle}`}>{t('Структура получения бонусов:')}</div>*/}
                            {/*<div className={`${Styles.textBoxList}`}>*/}
                            {/*    {refInfo?.settings?.register_bonuses ?*/}
                            {/*        Object.keys(refInfo?.settings?.register_bonuses).map((item, index)=>{*/}
                            {/*            return (*/}
                            {/*                <div className={`${Styles.textBoxListItem}`} key={'register_bonuses' + index}>{index + 1} {t('уровень')} - {refInfo?.settings?.register_bonuses[item]} {t('токенов')};</div>*/}
                            {/*            )*/}
                            {/*        }) : null*/}
                            {/*    }*/}
                            {/*</div>*/}
                            {/*<div className={`${Styles.textBoxNote}`}>*{t('токены можно отправить на стейкинг')}</div>*/}
                        </div>
                        <div className={`${Styles.textBox}`}>
                            <div className={`${Styles.textBoxTitle}`}>{t('2. За стейкинг - получайте % с прибыли вашего реферала.')}</div>
                            <div className={`${Styles.textBoxSubTitle}`}>{t('Структура получения бонусов:')}</div>
                            <div className={`${Styles.textBoxList}`}>
                                {refMainData?.referral_level?.reward_percentage?.map((item, index)=>{
                                    return (
                                        <div className={`${Styles.textBoxListItem}`} key={'staking_bonuses' + index}>{index + 1} {t('уровень')} - {item}%{refMainData?.referral_level?.reward_percentage.length - 1 === index ? '.' : ';'}</div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className={`${Styles.textBox}`}>
                            <div className={`${Styles.textBoxTitle}`}>{t('3. За бота - получайте % с объема прибыли торгов.')}</div>
                            <div className={`${Styles.textBoxSubTitle}`}>{t('Структура получения бонусов:')}</div>
                            <div className={`${Styles.textBoxList}`}>
                                {refMainData?.referral_level?.reward_percentage?.map((item, index)=>{
                                    return (
                                        <div className={`${Styles.textBoxListItem}`} key={'bot_bonuses' + index}>{index + 1} {t('уровень')} - {item}%{refMainData?.referral_level?.reward_percentage.length - 1 === index ? '.' : ';'}</div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default BonusesModal;
