import React, {useState} from "react";
import Styles from './styles.module.scss';

import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";


const PostSubscribe = () => {
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [emailErrStatus, setEmailErrStatus] = useState(false);

    const sendFormHandler = () =>{
        if (email.length > 5){
            toast(t('Спасибо за подписку!'));
            setEmail('');
        } else {
            setEmailErrStatus(true);
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title4} ${Styles.colorOrange}`}>{t('Наш Блог')}</div>
                <div className={`${Styles.title1}`}>{t('Подпишитесь на нашу рассылку')}</div>
                <div className={`${Styles.note}`}>{t('Подпишитесь, чтобы узнавать о новых функциях продуктов, новейших технологиях, решениях и обновлениях.')}</div>
                <div className={`${Styles.inputWrap}`}>
                    <div className={`${Styles.inputBox}`}>
                        <div className={`${Styles.positionWrap} ${emailErrStatus ? Styles.positionWrapErr : null}`}>
                            <input
                                type="text"
                                placeholder={`${t('Укажите ваш email')}`}
                                onChange={(e)=>{setEmail(e.target.value)}}
                                onKeyDown={()=>{setEmailErrStatus(false)}}
                                value={email}
                            />
                            <div className={`${Styles.btn}`} onClick={()=>{sendFormHandler()}}>{t('Подписаться')}</div>
                        </div>
                        <div className={`${Styles.inputNote}`}>{t('Мы заботимся о ваших данных в нашей политике конфиденциальности')}</div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default PostSubscribe;
