import axios from '../../helpers/interceptors';


import {
    TEST,
    SET_AUTHORIZATION_STATUS,
    SET_CONFIRMATION_STEP,
    REGISTRATION_STARTED,
    REGISTRATION_SUCCESS,
    REGISTRATION_FAILURE,
    LOGIN_STARTED,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_STARTED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    GET_PHRASE_STARTED,
    GET_PHRASE_SUCCESS,
    GET_PHRASE_FAILURE,
    SET_PHRASE_STARTED,
    SET_PHRASE_SUCCESS,
    SET_PHRASE_FAILURE,
    GET_USER_DATA_STARTED,
    GET_USER_DATA_SUCCESS,
    GET_USER_DATA_FAILURE,
    SET_MOBILE_NAV_STATUS,
    SET_MOBILE_WP_NAV_STATUS,
    CONFIRMATION_CLEAR,
    GET_LATEST_NEWS_STARTED,
    GET_LATEST_NEWS_SUCCESS,
    GET_LATEST_NEWS_FAILURE,
    GET_MAIN_NEWS_STARTED,
    GET_MAIN_NEWS_SUCCESS,
    GET_MAIN_NEWS_FAILURE,
    GET_POST_STARTED,
    GET_POST_SUCCESS,
    GET_POST_FAILURE,
    GET_TOP_NEWS_STARTED,
    GET_TOP_NEWS_SUCCESS,
    GET_TOP_NEWS_FAILURE,
    SET_LINK_SCROLL_STATUS,
    GET_PRESALE_DATA_STARTED,
    GET_PRESALE_DATA_SUCCESS,
    GET_PRESALE_DATA_FAILURE,
    RESET_PASSWORD_STARTED,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    SET_NEW_DEVICE_STATUS,
    LOGIN_WITH_HASH_STARTED,
    LOGIN_WITH_HASH_SUCCESS,
    LOGIN_WITH_HASH_FAILURE,
    SET_HASH_STATUS
} from "./types";

import {api} from "../../api";
// axios.defaults.withCredentials = true;
const host = api.host;


export const test = data => ({
    type: TEST,
    payload: data
});

export const setLinkScrollStatus = status => ({
    type: SET_LINK_SCROLL_STATUS,
    payload: status
});

export const setHashStatus = status => ({
    type: SET_HASH_STATUS,
    payload: status
});

export const setMobileNavStatus = status => ({
    type: SET_MOBILE_NAV_STATUS,
    payload: status
});

export const setMobileWpNavStatus = status => ({
    type: SET_MOBILE_WP_NAV_STATUS,
    payload: status
});

export const setAuthorizationStatus = status => ({
    type: SET_AUTHORIZATION_STATUS,
    payload: status
});

export const setConfirmationStep = step => ({
    type: SET_CONFIRMATION_STEP,
    payload: step
});

export const registration = (formData) => {
    let url = host + api.registration;


    return dispatch => {
        dispatch(registrationStarted());
        axios.post(url, formData)
            .then(res => {
                localStorage.setItem('omt-token', res.data.response.token);
                dispatch(registrationSuccess(res.data.response));
                dispatch(setAuthorizationStatus({authorization: true, phrase: false}));
            })
            .catch(err => {
                dispatch(registrationFailure(err.response.data.errors));
            });
    };
};

const registrationStarted = () => ({
    type: REGISTRATION_STARTED
});

export const registrationSuccess = serverReport => ({
    type: REGISTRATION_SUCCESS,
    payload: serverReport
});

export const registrationFailure = error => ({
    type: REGISTRATION_FAILURE,
    payload: error
});


export const login = (formData) => {
    let url = host + api.login;


    return dispatch => {
        dispatch(loginStarted());
        axios.post(url, formData)
            .then(res => {
                if (res.data.response.token){
                    localStorage.setItem('omt-token', res.data.response.token);
                    if (res.data.response.backup_phrase_copied){
                        localStorage.setItem('omt-phrase-status', true);
                    }
                    dispatch(loginSuccess(res.data.response));
                    dispatch(setAuthorizationStatus({authorization: true, phrase: !!res.data.response.backup_phrase_copied}));
                } else {
                    dispatch(setHashStatus(false));
                    dispatch(setNewDeviceStatus(res.data.response.hash));
                }
            })
            .catch(err => {
                dispatch(loginFailure(err.response.data.errors));
            });
    };
};

const loginStarted = () => ({
    type: LOGIN_STARTED
});

export const loginSuccess = serverReport => ({
    type: LOGIN_SUCCESS,
    payload: serverReport
});

export const loginFailure = error => ({
    type: LOGIN_FAILURE,
    payload: error
});

export const setNewDeviceStatus = status => ({
    type: SET_NEW_DEVICE_STATUS,
    payload: status
});


export const loginWithHash = (formData) => {
    let url = host + api.loginWH;


    return dispatch => {
        dispatch(loginWithHashStarted());
        axios.post(url, formData)
            .then(res => {
                localStorage.setItem('omt-token', res.data.response.token);
                if (res.data.response.backup_phrase_copied){
                    localStorage.setItem('omt-phrase-status', true);
                }
                dispatch(loginWithHashSuccess(res.data.response));
                dispatch(setAuthorizationStatus({authorization: true, phrase: !!res.data.response.backup_phrase_copied}));
                dispatch(setNewDeviceStatus(false));
            })
            .catch(err => {
                if (err.response.status === 308){
                    dispatch(setNewDeviceStatus(false));
                    dispatch(setHashStatus(true));
                }
                dispatch(loginWithHashFailure(err.response.data.errors));
            });
    };
};

const loginWithHashStarted = () => ({
    type: LOGIN_WITH_HASH_STARTED
});

export const loginWithHashSuccess = serverReport => ({
    type: LOGIN_WITH_HASH_SUCCESS,
    payload: serverReport
});

export const loginWithHashFailure = error => ({
    type: LOGIN_WITH_HASH_FAILURE,
    payload: error
});


export const logout = () => {
    let url = host + api.logout;
    const token = localStorage.getItem('omt-token');

    return dispatch => {
        dispatch(logoutStarted());
        axios.post(url, null, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                localStorage.removeItem('omt-token');
                localStorage.removeItem('omt-phrase-status');
                dispatch(setAuthorizationStatus({authorization: false, phrase: false}));
                dispatch(confirmationClear());
                localStorage.removeItem('omt-step');
            })
            .catch(err => {
                localStorage.removeItem('omt-token');
                localStorage.removeItem('omt-phrase-status');
                dispatch(setAuthorizationStatus({authorization: false, phrase: false}));
                dispatch(logoutFailure(err));
                dispatch(confirmationClear());
                localStorage.removeItem('omt-step');
            });
    };
};

const logoutStarted = () => ({
    type: LOGOUT_STARTED
});

export const logoutSuccess = serverReport => ({
    type: LOGOUT_SUCCESS,
    payload: serverReport
});

export const logoutFailure = error => ({
    type: LOGOUT_FAILURE,
    payload: error
});

export const confirmationClear = () => ({
    type: CONFIRMATION_CLEAR
});

export const getPhrase = () => {
    let url = host + api.phrase;
    const token = localStorage.getItem('omt-token');

    return dispatch => {
        dispatch(getPhraseStarted());
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                dispatch(getPhraseSuccess(res.data.response.phrase));
            })
            .catch(err => {
                dispatch(getPhraseFailure(err));
            });
    };
};

const getPhraseStarted = () => ({
    type: GET_PHRASE_STARTED
});

export const getPhraseSuccess = serverReport => ({
    type: GET_PHRASE_SUCCESS,
    payload: serverReport
});

export const getPhraseFailure = error => ({
    type: GET_PHRASE_FAILURE,
    payload: error
});


export const setPhraseToConfirmation = (data) => {
    let url = host + api.phrase;
    const token = localStorage.getItem('omt-token');

    return dispatch => {
        dispatch(setPhraseStarted());
        axios.post(url, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                localStorage.setItem('omt-phrase-status', true);
                dispatch(setAuthorizationStatus({authorization: true, phrase: true}));
                dispatch(setPhraseSuccess(res.data.success));
            })
            .catch(err => {
                dispatch(setPhraseFailure(err.response.data.errors));
            });
    };
};

const setPhraseStarted = () => ({
    type: SET_PHRASE_STARTED
});

export const setPhraseSuccess = serverReport => ({
    type: SET_PHRASE_SUCCESS,
    payload: serverReport
});

export const setPhraseFailure = error => ({
    type: SET_PHRASE_FAILURE,
    payload: error
});


export const getUserData = () => {
    let url = host + api.user;
    const token = localStorage.getItem('omt-token');

    return dispatch => {
        dispatch(getUserDataStarted());
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                localStorage.setItem('omt-phrase-status', true);
                dispatch(getUserDataSuccess(res.data.response));
            })
            .catch(err => {
                localStorage.removeItem('omt-phrase-status');
                dispatch(getUserDataFailure(err));
                if (err?.response?.status === 401){
                    localStorage.removeItem('omt-token');
                    localStorage.removeItem('omt-phrase-status');
                    dispatch(setAuthorizationStatus({authorization: false, phrase: false}));
                    dispatch(confirmationClear());
                }
            });
    };
};

const getUserDataStarted = () => ({
    type: GET_USER_DATA_STARTED
});

export const getUserDataSuccess = serverReport => ({
    type: GET_USER_DATA_SUCCESS,
    payload: serverReport
});

export const getUserDataFailure = error => ({
    type: GET_USER_DATA_FAILURE,
    payload: error
});


export const getLatestNews = () => {
    let url = host + api.news.latest;

    return dispatch => {
        dispatch(getLatestNewsStarted());
        axios.get(url)
            .then(res => {
                dispatch(getLatestNewsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getLatestNewsFailure(err));
            });
    };
};

const getLatestNewsStarted = () => ({
    type: GET_LATEST_NEWS_STARTED
});

export const getLatestNewsSuccess = serverReport => ({
    type: GET_LATEST_NEWS_SUCCESS,
    payload: serverReport
});

export const getLatestNewsFailure = error => ({
    type: GET_LATEST_NEWS_FAILURE,
    payload: error
});


export const getMainNews = (page) => {
    let url;
    if (page){
        url = host + api.news.paginate + `?page=${page}`;
    } else {
        url = host + api.news.paginate;
    }


    return dispatch => {
        dispatch(getMainNewsStarted());
        axios.get(url)
            .then(res => {
                dispatch(getMainNewsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getMainNewsFailure(err));
            });
    };
};

const getMainNewsStarted = () => ({
    type: GET_MAIN_NEWS_STARTED
});

export const getMainNewsSuccess = serverReport => ({
    type: GET_MAIN_NEWS_SUCCESS,
    payload: serverReport
});

export const getMainNewsFailure = error => ({
    type: GET_MAIN_NEWS_FAILURE,
    payload: error
});

export const getPost = (id) => {
    let url = host + api.news.post + `/${id}`;

    return dispatch => {
        dispatch(getPostStarted());
        axios.get(url)
            .then(res => {
                dispatch(getPostSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getPostFailure(err));
            });
    };
};

const getPostStarted = () => ({
    type: GET_POST_STARTED
});

export const getPostSuccess = serverReport => ({
    type: GET_POST_SUCCESS,
    payload: serverReport
});

export const getPostFailure = error => ({
    type: GET_POST_FAILURE,
    payload: error
});


export const getTopNews = () => {
    let url = host + api.news.top;

    return dispatch => {
        dispatch(getTopNewsStarted());
        axios.get(url)
            .then(res => {
                dispatch(getTopNewsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getTopNewsFailure(err));
            });
    };
};

const getTopNewsStarted = () => ({
    type: GET_TOP_NEWS_STARTED
});

export const getTopNewsSuccess = serverReport => ({
    type: GET_TOP_NEWS_SUCCESS,
    payload: serverReport
});

export const getTopNewsFailure = error => ({
    type: GET_TOP_NEWS_FAILURE,
    payload: error
});

export const getPreSaleData = () => {
    let url = host + api.tickets.total;

    return dispatch => {
        dispatch(getPreSaleDataStarted());
        axios.get(url)
            .then(res => {
                dispatch(getPreSaleDataSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getPreSaleDataFailure(err));
            });
    };
};

const getPreSaleDataStarted = () => ({
    type: GET_PRESALE_DATA_STARTED
});

export const getPreSaleDataSuccess = serverReport => ({
    type: GET_PRESALE_DATA_SUCCESS,
    payload: serverReport
});

export const getPreSaleDataFailure = error => ({
    type: GET_PRESALE_DATA_FAILURE,
    payload: error
});


export const resetPassword = (formData) => {
    let url = host + api.reset;

    return dispatch => {
        dispatch(resetPasswordStarted());
        axios.post(url, formData)
            .then(res => {
                dispatch(resetPasswordSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(resetPasswordFailure(err.response.data.errors));
            });
    };
};

const resetPasswordStarted = () => ({
    type: RESET_PASSWORD_STARTED
});

export const resetPasswordSuccess = serverReport => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: serverReport
});

export const resetPasswordFailure = error => ({
    type: RESET_PASSWORD_FAILURE,
    payload: error
});






