import React from "react";
import Styles from './styles.module.scss';

import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import newsReadIcon from '../../theme/assets/svg/omt_news_read_icon.svg';
import {linkTranslate} from "../../helpers/linkTranslite";
import Moment from "react-moment";
import DOMPurify from "dompurify";
// import newsLinkIcon from '../../theme/assets/svg/omt_news_link_icon.svg';


const NewsLatest = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const latestNews = useSelector(store => store.main.latestNews);

    const postLinkHandler = (title, id) => {
        const correctUrl = linkTranslate(title, id);
        navigate(`/news/${correctUrl}`);
    };

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title1}`}>{t('Новости & События')}</div>
                <div className={`${Styles.title2} ${Styles.colorBlue}`}>{t('Последние новости')}</div>
                <div className={`${Styles.contentBox}`}>
                    {latestNews?.length > 0 ? latestNews.map((item, index)=>{
                        return (
                            <div className={`${Styles.contentBoxItem}`} key={'latestNews' + index} onClick={()=>{postLinkHandler(item.title, item.id)}}>
                                <div className={`${Styles.contentBoxItemImgWrap}`} style={{background: `url("${item.picture}")`}}>
                                    <img src={item.picture} alt={item.title}/>
                                </div>
                                <div className={`${Styles.contentBoxItemData}`}>
                                    <div className={`${Styles.dateWrap}`}>
                                        <div className={`${Styles.date}`}><Moment format="DD/MM/YYYY">{item.date}</Moment></div>
                                        <div className={`${Styles.read}`}>
                                            <div className={`${Styles.readImgWrap}`}>
                                                <img src={newsReadIcon} alt={item.title}/>
                                            </div>
                                            {item.read_time ? item.read_time : '2'} minutes
                                        </div>
                                    </div>
                                    <div className={`${Styles.title3}`}>{item.title}</div>
                                    <div
                                        className={`${Styles.contentBoxItemDescription}`}
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(item.content),
                                        }}
                                    />
                                    <div className={`${Styles.contentBoxItemCategory}`}>{item.category?.name}</div>
                                </div>
                            </div>
                        )
                    }) : null}
                </div>
            </div>
        </section>
    );
};

export default NewsLatest;
