import React from "react";
import Styles from './styles.module.scss'
import Slider from "react-slick";
import {useDispatch, useSelector} from "react-redux";
import {
    setCurrentWallet,
} from "../../store/wallet/action";
import wallet_bg_pic1_type1 from "../../theme/assets/other/omt_wallet_bg_pic1_type1.png";
import wallet_bg_pic1_type2 from "../../theme/assets/other/omt_wallet_bg_pic1_type2.png";
import wallet_bg_pic1_type3 from "../../theme/assets/other/omt_wallet_bg_pic1_type3.png";
import wallet_bg_pic2_type1 from "../../theme/assets/other/omt_wallet_bg_pic2_type1.png";
import wallet_bg_pic2_type2 from "../../theme/assets/other/omt_wallet_bg_pic2_type2.png";
import wallet_bg_pic2_type3 from "../../theme/assets/other/omt_wallet_bg_pic2_type3.png";
import wallet_bg_pic3_type1 from "../../theme/assets/other/omt_wallet_bg_pic3_type1.png";
import wallet_bg_pic3_type2 from "../../theme/assets/other/omt_wallet_bg_pic3_type2.png";
import wallet_bg_pic3_type3 from "../../theme/assets/other/omt_wallet_bg_pic3_type3.png";
import importIcon from '../../theme/assets/svg/omt_import_icon.svg';
import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import WalletBtns from "../_UI/walletBtns";


const WalletMain = (props) => {
    const dispatch = useDispatch();
    const {posStatus} = props
    const availableWallets = useSelector(store => store.wallet.availableWallets);

    const { t } = useTranslation();

    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        beforeChange: (current, next) => dispatch(setCurrentWallet(next)),
        // afterChange: current => console.log({ activeSlide2: current })
    };

    const pictures = [
        [wallet_bg_pic1_type1, wallet_bg_pic1_type2, wallet_bg_pic1_type3],
        [wallet_bg_pic2_type1, wallet_bg_pic2_type2, wallet_bg_pic2_type3],
        [wallet_bg_pic3_type1, wallet_bg_pic3_type2, wallet_bg_pic3_type3]
    ]



    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBody} ${posStatus ? Styles.contentWrapP : null}`}>
                    <div className={`${Styles.cardWrap}`}>
                        {availableWallets?.length > 0 ?
                            <Slider {...settings}>
                                {availableWallets?.map((item, index)=>{
                                    return (
                                        <div
                                            className={`${Styles.cardWrapItem}`}
                                            key={'activeWallets' + index}
                                        >
                                            <div
                                                className={`${Styles.cardWrapBgBox}`}
                                                style={{backgroundImage: `url(${pictures[item.background - 1][item.color - 1]})`}}
                                            >
                                                <div className={`${Styles.titleRow}`}>
                                                    <div className={`${Styles.title}`}>{item.name}</div>
                                                    <div className={`${Styles.settingsBtn}`}>
                                                        <span className={`${Styles.settingsBtnItem}`}/>
                                                        <span className={`${Styles.settingsBtnItem}`}/>
                                                        <span className={`${Styles.settingsBtnItem}`}/>
                                                    </div>
                                                </div>
                                                <div className={`${Styles.balanceRow}`}>
                                                    <div className={`${Styles.balance}`}>{item.amount ? item.amount * 1 : '0'} {item.currency?.short_name}</div>
                                                </div>
                                                <WalletBtns currentWallet={item}/>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider> :
                            <div className={`${Styles.cardWrapItem}`}>
                                <div  className={`${Styles.cardWrapBgBoxFE}`}>
                                    <EmptyValues text={t('У вас пока что нет добавленных кошельков')} withIcon={true} withBtn={true} type='walletAssets'/>
                                </div>
                            </div>}

                    </div>
                    {/*onClick={()=>{dispatch(setWalletCreateFormStatus(true))}}*/}
                    <div className={`${Styles.addBtn}`}>
                        <div className={`${Styles.absSoon}`}>{t('Скоро')}</div>
                        <div className={`${Styles.addBtnPlus}`}>
                            <img src={importIcon} alt="Import Wallet"/>
                        </div>
                        <div className={`${Styles.addBtnContent}`}>{t('Импортировать кошелек')}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WalletMain;
