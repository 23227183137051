import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import NewsLatest from "../../components/newsLatest";
import NewsMain from "../../components/newsMain";
import {getLatestNews, getMainNews} from "../../store/main/action";


const NewsPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let {page} = useParams();
    const authStatus = useSelector(store => store.main.authorizationStatus);

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                // navigate('/confirmation');
            } else {
                // navigate('/wallet');
            }
        }

    },[authStatus, navigate])
    
    useEffect(()=>{
        dispatch(getLatestNews());
        dispatch(getMainNews(page));
    },[dispatch, page])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'News'}/>
            <NewsLatest/>
            <NewsMain/>
        </section>
    );
};

export default NewsPage;