import React from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";

import CryptoIcon from "../_UI/cryptoIcon";
import {colorHandler} from "../../helpers/colorHandler";
import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";


const WalletStaking = () => {
    const userStaking = useSelector(store => store.wallet.userStaking);
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.card}`}>
                <div className={`${Styles.cardBodyWithScroll}`}>
                    <div className={`${Styles.absBtn}`} onClick={()=>{navigate('/staking')}}>{t('Перейти к стейкингу')}</div>
                    <div className={`${Styles.title3}`}>{t('Стейкинг')}</div>
                    <div className={`${Styles.balanceRow}`}>
                        <div className={`${Styles.balanceRowTitle}`}>{t('Баланс')}</div>
                        <div className={`${Styles.balance}`}>{userStaking?.balance ? Number(userStaking?.balance).toFixed(2) * 1 : '0'} {userStaking?.currency}</div>
                    </div>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Монета')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Кол-во')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>$ / {t('Кол-во')}<br/>≈{t('прибыль')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        {userStaking?.stakings?.length > 0 ? userStaking?.stakings.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem}`} key={'walletStaking' + index}>
                                    <div className={`${Styles.contentBoxItemNameWrap}`}>
                                        <div className={`${Styles.contentBoxItemNameImgWrap}`}>
                                            <CryptoIcon short_name={item.currency.short_name}/>
                                        </div>
                                        <div className={`${Styles.contentBoxItemNameBox}`}>
                                            {item.currency.short_name}
                                        </div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemValues}`}>
                                        <div className={`${Styles.contentBoxItemValuesItem}`}>{item.total * 1}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemValues}`}>
                                        <div className={`${Styles.contentBoxItemValuesItem}`} style={{color: colorHandler(item.profit)}}>{item.profit * 1}</div>
                                    </div>
                                </div>
                            )
                        }) : <EmptyValues text={t('У вас пока что нет активов в стейкинге')} withIcon={false} withBtn={false}/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WalletStaking;
