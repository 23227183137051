import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getIndexMainData, getIndexSwapHistory, getIndexSwapPrediction} from "../../store/wallet/action";
import Seo from "../../components/_UI/seo";
import IndexMain from "../../components/indexMain";
import IndexTotal from "../../components/indexTotal";
import IndexSwap from "../../components/indexSwap";
import IndexHistory from "../../components/indexHistory";
import IndexPrediction from "../../components/indexPrediction";


const IndexPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authStatus = useSelector(store => store.main.authorizationStatus);
    const postLink = '/white-paper/omt-ecosystem/index-omt20';

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])
    
    useEffect(()=>{
        dispatch(getIndexMainData());
        dispatch(getIndexSwapHistory('week'));
        dispatch(getIndexSwapPrediction(360));
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'Index'}/>
            <div className={`${Styles.contentWrap}`}>
                <IndexMain link={postLink}/>
                <IndexTotal/>
                <IndexSwap/>
                <IndexHistory link={postLink}/>
                <IndexPrediction/>
            </div>
        </section>
    );
};

export default IndexPage;