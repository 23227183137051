import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {setBotSubscribeAlertModalStatus} from "../../store/wallet/action";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";



const BotSubscribeAlertModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const alertModalStatus = useSelector(store => store.wallet.botSubscribeAlertModalStatus);


    const closeModal = () =>{
        dispatch(setBotSubscribeAlertModalStatus(false));
        localStorage.setItem('bot-subscribe-alert-modal-status', 'true');
    }


    return (
        <Popup
            open={alertModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.title2}`}>{t('Срок подписки истек')}</div>
                    <div className={`${Styles.modalContentBox}`}>
                        <div className={`${Styles.modalContentBoxItem}`}>
                            {t('Мы хотели бы сообщить вам, что ваша подписка на HoneeAI истекла. Чтобы продолжить использование HoneeAI, пожалуйста, произведите оплату подписки.')}
                        </div>
                    </div>
                    <div
                        className={`${Styles.btn}`}
                        onClick={()=>{
                            closeModal();
                            navigate('/honeeAi')
                        }}
                    >
                        {t('Подпишитесь снова')}
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default BotSubscribeAlertModal;
